import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { DocumentStatuses } from '@/dal'
import { NewButton, Switch, TextL } from '@/ui'
import { $documentStatus } from '@/features/estimate-correction/shared-model'

import { $totalWorkCount, openExitSpecialModeModal } from '../../../model/private'
import {
  $isResettingAll,
  $isResetModeEnabled,
  $resettingRowsCount,
  changeIsResettingAll,
  resetKorDocRows,
  resetKorDocRowsFx,
  enableResetMode,
} from '../../../model/reset-multiple-items.private'
import { $isMultipleEditModeEnabled } from '../../../model/edit-multiple-items.private'

export const ResetRowsControls = React.memo(() => {
  const [
    status,
    isResetMode,
    selectedCount,
    areAllSelected,
    totalCount,
    isMultipleEditMode,
    isPending,
  ] = useUnit([
    $documentStatus,
    $isResetModeEnabled,
    $resettingRowsCount,
    $isResettingAll,
    $totalWorkCount,
    $isMultipleEditModeEnabled,
    resetKorDocRowsFx.pending,
  ])

  if (status !== DocumentStatuses.Draft || isMultipleEditMode) return null

  const count = areAllSelected ? totalCount ?? 0 : selectedCount

  return (
    <Wrapper>
      {!isResetMode ? (
        <NewButton
          label="Обнулить строки"
          prefixIcon="eraser"
          size="S"
          buttonType="grey"
          dataTestId="open-reset-mode-btn"
          onClick={enableResetMode}
        />
      ) : (
        <>
          <ButtonsWrapper>
            <NewButton
              label="Отменить"
              buttonType="grey"
              size="S"
              isDisabled={isPending}
              dataTestId="close-reset-mode-btn"
              onClick={openExitSpecialModeModal}
            />
            <NewButton
              label="Обнулить выбранные строки"
              size="S"
              isDisabled={!count}
              isPending={isPending}
              dataTestId="reset-selected-rows-btn"
              onClick={resetKorDocRows}
            />
          </ButtonsWrapper>

          <SelectedCountWrapper>
            <span>Выбрано:</span>
            {count}
          </SelectedCountWrapper>

          <Switch
            label="Выбрать все строки"
            isChecked={areAllSelected}
            disabled={isPending}
            dataTestId="reset-all-rows-checkbox"
            onChange={() => changeIsResettingAll(!areAllSelected)}
          />
        </>
      )}
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 8px;
`

const SelectedCountWrapper = styled.div`
  ${TextL}
  display: flex;
  gap: 8px;
`
