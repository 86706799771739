import React from 'react'

import { EditableValue } from '@/ui'
import { numberWithSpaces } from '@/lib/number-represent'

import { Col, Value } from './sharedCSS'

type Props = {
  value: string
  currencyValue?: string | null
  isChangeable?: boolean
  isApproved?: boolean
  isGrey?: boolean
  onSubmit?: (val: string) => void
}

export const AvansCol = ({
  currencyValue,
  value,
  isApproved,
  isChangeable,
  isGrey,
  onSubmit,
}: Props) => {
  const formatString = (string: string) => (
    numberWithSpaces(string.toString())
  )

  return (
    <Col>
      <Value $isGrey={isGrey}>
        {formatString(value)}
        {' %'}
      </Value>

      {currencyValue &&
        (isChangeable && onSubmit ? (
          <EditableValue
            onSubmit={onSubmit}
            value={currencyValue}
            cellType="currency"
            placeholder="Введите цену"
          />
        ) : (
          <Value $isApproved={isApproved} $isGrey={isGrey}>
            {formatString(currencyValue)}
          </Value>
        )
      )}
    </Col>
  )
}
