import React from 'react'
import styled from 'styled-components'

import { Icon, IconName } from './Icon'
import { ResetStyleButton } from './ResetStyleButton'
import { themeVar } from './theming'
import { MediumLightTextCSS } from './Typography'

type Props = {
  fileName: string
  file: string
  isHiddeBorder?: boolean
  icon?: IconName
}

/** @deprecated */
export const PDFFileItem = ({
  fileName,
  file,
  isHiddeBorder,
  icon = 'fixed-download',
}: Props) => {
  const url = file.split('/media')[1]
  const ref = React.createRef<HTMLAnchorElement>()
  const onClick = () => {
    ref.current?.click()
  }
  return (
    <ItemWrapper>
      <IconWrapper>
        <Icon icon="pdf-file-icon" />
      </IconWrapper>
      <FileName>{fileName}</FileName>
      <FileLink ref={ref} href={`/media${url}`} download={fileName} />
      <DownloadIconWrapper onClick={onClick} isHiddeBorder={Boolean(isHiddeBorder)}>
        <Icon icon={icon} />
      </DownloadIconWrapper>
    </ItemWrapper>
  )
}

const ItemWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
`

const FileName = styled.div`
  ${MediumLightTextCSS}
  flex: 1;
`

const DownloadIconWrapper = styled(ResetStyleButton)<{isHiddeBorder?: boolean}>`
  border: 1px solid ${(
    { isHiddeBorder },
  ) => (isHiddeBorder ? 'transparent' : themeVar('borderGray'))};
  padding: ${({ isHiddeBorder }) => (isHiddeBorder ? '0px' : '4px')};
  width: 24px;
  height: 24px;
  color: ${themeVar('gray8')};
  transition: color 0.15s linear;

  &:hover {
    color: ${themeVar('gray1')};
  }
`

const FileLink = styled.a``
