import React from 'react'
import { useForm } from 'effector-forms'
import styled from 'styled-components'

import {
  DropAreaWithList,
  NumberInputField,
  TextAreaField,
} from '@/ui'

import { splitVolumeForm } from '../../../model/split-volume.private'

export const NewFields = React.memo(() => {
  const { fields } = useForm(splitVolumeForm)

  const isNewWorkFieldRequired = (
    !fields.new_material.value || parseFloat(fields.new_material.value) <= 0
  )

  const isNewMaterialFieldRequired = (
    !fields.new_work.value || parseFloat(fields.new_work.value) <= 0
  )

  return (
    <>
      <NumberInputField
        dataTestId="estimate-count"
        label="Кол-во по смете"
        isRequired
        onChange={fields.new_count_in_estimate.onChange}
        value={fields.new_count_in_estimate.value}
        decimalScale={4}
        size="L"
        fixedDecimalScale
        error={fields.new_count_in_estimate.firstError?.errorText}
        onClear={fields.new_count_in_estimate.reset}
        thousandSeparator=" "
      />

      <FieldRow>
        <NumberInputField
          dataTestId="material-input"
          label="Цена материалов, руб"
          isRequired={isNewMaterialFieldRequired}
          onChange={fields.new_material.onChange}
          value={fields.new_material.value}
          decimalScale={2}
          fixedDecimalScale
          error={fields.new_material.firstError?.errorText}
          onClear={fields.new_material.reset}
          size="L"
          thousandSeparator=" "
        />

        <NumberInputField
          dataTestId="work-input"
          label="Цена работ, руб"
          isRequired={isNewWorkFieldRequired}
          onChange={fields.new_work.onChange}
          value={fields.new_work.value}
          decimalScale={2}
          fixedDecimalScale
          error={fields.new_work.firstError?.errorText}
          onClear={fields.new_work.reset}
          size="L"
          thousandSeparator=" "
        />
      </FieldRow>

      <TextAreaField
        dataTestId="text-area"
        label="Обоснование"
        onChange={fields.new_justification_of_changes_current.onChange}
        placeholder="Укажите причину изменений и ссылку на РД"
        value={fields.new_justification_of_changes_current.value}
        autoGrow
        currentSymbolCount={fields.new_justification_of_changes_current.value.length}
        maxSymbolCount={800}
        height={100}
      />
      <DropAreaWithList
        value={fields.new_files_to_save.value}
        onChange={fields.new_files_to_save.onChange}
        dataTestId="file-input"
      />
    </>
  )
})

const FieldRow = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
`
