import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { GaActTable, GaTableFilters } from '@/features/act/acting-group'

import { Table, TableFilters } from '../containers'
import { AutoCompleteAlert } from '../parts'
import { $documentFeatures, $isGaAct } from '../../../model'

export const Works = React.memo(() => {
  const [features, isGa] = useUnit([$documentFeatures, $isGaAct])

  return (
    <>
      {features?.has_negative_completions_counts && <AutoCompleteAlert />}

      <TableWrapper>
        {isGa ? (
          <>
            <GaTableFilters />
            <GaActTable />
          </>
        ) : (
          <>
            <TableFilters />
            <Table />
          </>
        )}
      </TableWrapper>
    </>
  )
})

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  // TODO в идеале — переработать контейнеры
  min-height: 75dvh;
`
