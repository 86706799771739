import React from 'react'
import styled from 'styled-components'

import { NotificationItem, NotificationType, PaletteColor, TextMLight, palette } from '@/ui'

type Props = {
  hasNotification: boolean
  hasRejectionLegend: boolean
}

export const ColorsLegend = ({ hasNotification, hasRejectionLegend }: Props) => {
  return (
    <Wrapper>
      {hasNotification && (
        <NotificationItem
          type={NotificationType.Info}
          body="Вам необходимо выбрать места выполнения работ"
        />
      )}

      <Legend>
        <Item>
          <ColorSquare $color="lightGreen100" />
          <Text>— Закрывается в текущем выполнении</Text>
        </Item>
        {hasRejectionLegend && (
          <Item>
            <ColorSquare $color="red100" />
            <Text>— Не принято</Text>
          </Item>
        )}
        <Item>
          <ColorSquare $color="grey40" />
          <Text>— Закрыто в прошлом выполнении</Text>
        </Item>
      </Legend>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  min-height: 58px;
  padding: 12px 20px;
`

const Legend = styled.div`
  display: flex;
  justify-content: end;
  gap: 16px;
  flex-grow: 1;
`

const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const ColorSquare = styled.div<{ $color: PaletteColor }>`
  width: 12px;
  height: 12px;
  flex-shrink: 0;
  border-radius: 2px;
  background-color: ${({ $color }) => palette[$color]};
`

const Text = styled.p`
  ${TextMLight}
`
