import React from 'react'
import styled, { css } from 'styled-components'
import { usePopperTooltip } from 'react-popper-tooltip'

import { Icon, IconName } from '@/ui/Icon'
import { themeVar } from '@/ui/theming'
import { ResetStyleButton } from '@/ui/ResetStyleButton'
import { SimpleLoader } from '@/ui/Loader'

import { noop } from '@/lib/noop'
import { PopperToolTip } from '@/ui/PopperToolTip'
import { ButtonsType } from '../types'

type Props = {
  onClick?: () => void
  icon?: IconName
  styleButton: ButtonsType
  dataTestId: string
  iconColor?: string
  iconHoverColor?: string
  isLoading?: boolean
  children?: React.ReactElement | string
  width?: string;
  type?: 'submit' | 'reset' | 'button'
  disabled?: boolean
  tooltip?: string
  size?: 'M' | 'L'
}

/** @deprecated */
export const Button = ({
  onClick,
  icon,
  type,
  styleButton,
  dataTestId,
  iconColor,
  isLoading,
  children,
  iconHoverColor,
  width,
  disabled,
  tooltip,
  size = 'M',
}: Props) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    placement: 'top',
  })

  return (
    <>
      <Wrapper
        ref={setTriggerRef}
        data-testid={dataTestId}
        onClick={isLoading ? noop : onClick}
        styleButton={styleButton}
        iconHoverColor={iconHoverColor}
        iconColor={iconColor}
        width={width}
        disabled={disabled}
        type={type}
        size={size}
        isLoading={Boolean(isLoading)}
      >
        {!isLoading && icon && (
          <IconWrapper iconColor={iconColor}>
            <Icon icon={icon} />
          </IconWrapper>
        )}
        {isLoading ? <SimpleLoader sizePx={24} /> : children}
      </Wrapper>
      {tooltip && (
        <PopperToolTip
          getArrowProps={getArrowProps}
          getTooltipProps={getTooltipProps}
          setTooltipRef={setTooltipRef}
          tooltip={tooltip}
          visible={visible}
        />
      )}
    </>
  )
}

type ButtonProps = Pick<Props, 'size' | 'width' | 'iconHoverColor' | 'iconColor' | 'styleButton'> & {
  isLoading: boolean
}

const LargeButtonStyle = css`
  height: 60px;
  padding: 18px 24px;
  font-size: 16px;
  line-height: 1.25;
`

const MediumButtonStyle = css`
  padding: 10px 16px;
  font-size: 14px;
  line-height: 1.29;
  height: 45px;
`

const Wrapper = styled(ResetStyleButton)<ButtonProps>`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.15s linear;

  width: ${({ width }) => width || '100%'};
  min-width: ${({ width }) => width || 'unset'};
  ${({ size }) => (size === 'M' ? MediumButtonStyle : LargeButtonStyle)}

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  ${({ isLoading }) => isLoading && css`
    cursor: progress;
  `}

  ${({ iconHoverColor, iconColor }) => (
    css`
      &:hover:not(:disabled) ${IconWrapper} {
        color: ${iconHoverColor || iconColor || 'inherit'};
      }`
  )}


  ${({ styleButton }) => {
    if (styleButton === 'primary') {
      return css`
        background-color: ${themeVar('main')};
        color: ${themeVar('whiteText')};

        &:hover:not(:disabled) {
          opacity: 0.7;
        }
      `
    }
  }}

  ${({ styleButton }) => {
    if (styleButton === 'secondary') {
      return css`
          border: 1px solid ${themeVar('main')};
          color: ${themeVar('main')};

          &:hover:not(:disabled) {
            background-color: ${themeVar('main')};
            color: ${themeVar('whiteText')};
          }
        `
    }
  }}

  ${({ styleButton }) => {
    if (styleButton === 'third') {
      return css`
        border: 1px solid ${themeVar('grey')};
        background-color: ${themeVar('gray13')};
        color: ${themeVar('textGray')};

        &:hover:not(:disabled) {
          border: 1px solid ${themeVar('grey')};
          background-color: ${themeVar('grey')};
          color: ${themeVar('text')};
        }
      `
    }
  }}

  ${({ styleButton }) => {
    if (styleButton === 'fourth') {
      return css`
        border: 1px solid ${themeVar('grey')};
        background-color: ${themeVar('fourthButtonBgColor')};
        color: ${themeVar('textGray')};

        &:hover:not(:disabled) {
          border: 1px solid ${themeVar('grey')};
          background-color: ${themeVar('grey')};
          color: ${themeVar('text')};
        }
      `
    }
  }}
`

const IconWrapper = styled.div<{ iconColor?: string }>`
  width: 16px;
  height: 16px;
  color: inherit;
  transition: all 0.15s linear;

  ${({ iconColor }) => iconColor && css`
    color: ${iconColor};
  `}

`
