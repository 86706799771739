type Params = {
  e: MouseEvent
  element: HTMLElement | null
  screenshotResolution: {
    width: number
    height: number
  }
}

export const getEndCoords = ({ e, element, screenshotResolution }: Params) => {
  const rect = element?.getBoundingClientRect()
  const { pageX, pageY } = e
  const relativeX = pageX - (rect?.left ?? 0)
  const relativeY = pageY - (rect?.top ?? 0)

  const endX = relativeX >= 0
    ? Math.min(relativeX, screenshotResolution.width)
    : 0
  const endY = relativeY >= 0
    ? Math.min(relativeY, screenshotResolution.height)
    : 0

  return { endX, endY }
}
