import { sample } from 'effector'
import { debounce } from 'patronum'

import { CheckInnNotFoundError, PaymentInfo, isInnNotFoundError } from '@/dal'
import { ShowToast, createToast, showToast } from '@/features/toast-service/model'

import { NotificationType } from '@/ui'
import {
  $contragents,
  checkINNFx,
  editPaymentForm,
  onCheckINN,
  putPaymentInfoFx,
  $isInnNotFoundError,
  setInnNotFoundError,
  onCreateContragent,
  onShowContragentRequest,
  $openContragentInfo,
  setContragentInfo,
  showErrorInEditForm,
  changePaymentTypeAndInfoFx,
} from './edit-private'
import {
  $canEdit,
  $paymentDocumentId,
  updatePayment,
  $isThirdPerson,
  getPaymentItemFx,
  setIsThirdPerson,
  $contragentInfo,
} from './private'
import { $activeTabId, PaymentGate, setInvalidActiveTab } from './public'
import { ContragentInfo, PaymentTab } from './types'
import { openCreateContragent, openNewContragentPreview } from './create-contragent.private'

$contragents
  .on(checkINNFx.doneData, (_, contragents) => contragents)
  .reset(PaymentGate.close, editPaymentForm.fields.inn.changed)

$openContragentInfo
  .on(setContragentInfo, (_, info) => info)
  .reset(PaymentGate.close, editPaymentForm.fields.inn.changed)

$isInnNotFoundError
  .on(setInnNotFoundError, () => true)
  .reset(PaymentGate.close, editPaymentForm.fields.inn.changed)

sample({
  clock: PaymentGate.close,
  target: editPaymentForm.reset,
})

sample({
  clock: putPaymentInfoFx.doneData,
  target: updatePayment,
})

sample({
  clock: onCheckINN,
  filter: $isThirdPerson,
  target: editPaymentForm.fields.inn.validate,
})

sample({
  clock: editPaymentForm.fields.inn.changed,
  target: [
    editPaymentForm.fields.current_account.reset,
    editPaymentForm.fields.contragent.reset,
  ],
})

sample({
  clock: editPaymentForm.fields.contragent.changed,
  target: editPaymentForm.fields.current_account.reset,
})

sample({
  clock: editPaymentForm.fields.inn.validate,
  source: {
    inn: editPaymentForm.fields.inn.$value,
    isTouched: editPaymentForm.fields.inn.$isTouched,
    isValid: editPaymentForm.fields.inn.$isValid,
  },
  filter: ({ isValid, isTouched }) => isValid && isTouched,
  fn: ({ inn }) => ({ inn }),
  target: checkINNFx,
})

sample({
  clock: editPaymentForm.fields.inn.validate,
  source: {
    isValid: editPaymentForm.fields.inn.$isValid,
    isTouched: editPaymentForm.fields.inn.$isTouched,
  },
  filter: ({ isValid, isTouched }) => !isValid && isTouched,
  fn: (): ShowToast => ({
    content: 'Некорректный ИНН, попробуйте еще раз',
    icon: NotificationType.Alert,
  }),
  target: showToast,
})

sample({
  clock: editPaymentForm.validate,
  source: {
    tab: $activeTabId,
    isValid: editPaymentForm.$isValid,
  },
  filter: ({ tab, isValid }) => tab !== PaymentTab.Info && !isValid,
  target: showErrorInEditForm,
})

sample({
  clock: showErrorInEditForm,
  fn: () => PaymentTab.Info,
  target: setInvalidActiveTab,
})

sample({
  clock: showErrorInEditForm,
  fn: (): ShowToast => ({
    content: 'Заполните обязательные поля в блоке «Данные платежа»',
    icon: NotificationType.Alert,
  }),
  target: showToast,
})

sample({
  clock: checkINNFx.failData,
  filter: (error): error is CheckInnNotFoundError => isInnNotFoundError(error),
  fn: (error: CheckInnNotFoundError): ContragentInfo => ({
    ...error.data.contragent_info,
  }),
  target: [
    openCreateContragent,
    setInnNotFoundError,
    setContragentInfo,
  ],
})

sample({
  clock: onShowContragentRequest,
  source: $contragentInfo,
  filter: Boolean,
  target: openNewContragentPreview,
})

sample({
  clock: onCreateContragent,
  source: $openContragentInfo,
  filter: Boolean,
  target: openCreateContragent,
})

sample({
  clock: checkINNFx.doneData,
  source: {
    contragent: editPaymentForm.fields.contragent.$value,
  },
  filter: ({ contragent }, items) => Boolean(
    !contragent
    && items.length === 1,
  ),
  fn: (_, items) => {
    const item = items[0]
    return {
      contragent: item.uuid,
      current_account: item.bank_accounts.length === 1 ? item.bank_accounts[0].uuid : null,
    }
  },
  target: editPaymentForm.set,
})

createToast({
  effect: checkINNFx,
})

sample({
  clock: getPaymentItemFx.doneData,
  fn: (payment) => ({
    number: payment?.bill_details?.number || '',
    payment_date: payment?.bill_details?.payment_date || '',
    avans_date: payment?.bill_details?.avans_date || '',
    comment: payment?.comment || '',
    inn: payment?.inn || '',
    contragent: payment?.third_person_info?.contragent_id || null,
    current_account: payment?.third_person_info?.bank_account_id || null,
    outgoing_mail_number: payment?.third_person_info?.outgoing_mail_number || '',
    outgoing_mail_date: payment?.third_person_info?.outgoing_mail_date || '',
  }),
  target: [editPaymentForm.setForm, onCheckINN],
})

sample({
  clock: setIsThirdPerson,
  source: {
    id: $paymentDocumentId,
    fields: editPaymentForm.$values,
  },
  filter: ({ id }) => Boolean(id),
  fn: ({ id, fields }, isThird) => ({
    ...fields,
    isThirdPerson: isThird,
    id: id as PaymentInfo['id'],
  }),
  target: changePaymentTypeAndInfoFx,
})

sample({
  clock: debounce({
    source: editPaymentForm.$values,
    timeout: 300,
  }),
  source: {
    id: $paymentDocumentId,
    isThirdPerson: $isThirdPerson,
    isTouched: editPaymentForm.$touched,
    canEdit: $canEdit,
  },
  filter: ({ id, isTouched, canEdit }) => Boolean(id && isTouched && canEdit),
  fn: ({ id, isThirdPerson }, { ...fields }) => ({
    ...fields,
    isThirdPerson,
    id: id as PaymentInfo['id'],
  }),
  target: putPaymentInfoFx,
})

createToast({
  effect: putPaymentInfoFx,
  doneText: 'Данные сохранены',
})
