import React, { useMemo } from 'react'
import styled from 'styled-components'
import { ConnectedFields } from 'effector-forms'
import { v4 as uuidv4 } from 'uuid'

import {
  DropArea, InputField, NewFileItem, TextAreaField,
} from '@/ui'
import { FILE_MAX_COUNT } from '@/features/support/model'

type Props<T> = {
  children: React.ReactNode
  fields: T
  isDisabled?: boolean
}

type BaseFields = ConnectedFields<{
  subject: string
  description: string
  files: File[]
}>

export const BaseFormFieldsWithChildren = <T extends BaseFields>({
  children,
  fields,
  isDisabled,
}: Props<T>) => {
  const filesWithId = useMemo(() => (
    fields.files.value.map((file) => ({ file, id: uuidv4() }))
  ), [fields.files.value])

  const handleDeleteFile = (uuid: string) => {
    const files = filesWithId.filter(({ id }) => uuid !== id)
    fields.files.onChange(files.map(({ file }) => file))
  }

  return (
    <Wrapper>
      {children}

      <InputField
        value={fields.subject.value}
        error={fields.subject.firstError?.errorText}
        label="Тема"
        placeholder="Введите тему обращения"
        size="L"
        disabled={isDisabled}
        dataTestId="appeal-subject-input"
        isRequired
        onChange={fields.subject.onChange}
        onClear={() => fields.subject.set('')}
      />

      <TextAreaField
        value={fields.description.value}
        error={fields.description.firstError?.errorText}
        label="Текст обращения"
        placeholder="Введите текст..."
        disabled={isDisabled}
        height={100}
        dataTestId="appeal-description-textarea"
        required
        onChange={fields.description.onChange}
      />

      <FilesContainer>
        <DropArea
          value={fields.files.value}
          maxFiles={FILE_MAX_COUNT}
          isDisabled={isDisabled}
          isMultiple
          onChange={fields.files.onChange}
        />
        {filesWithId.length ? (
          <FileList>
            {filesWithId.map(({ file, id }) => (
              <NewFileItem
                key={id}
                size="M"
                name={file.name}
                isDense
                onDelete={() => handleDeleteFile(id)}
              />
            ))}
          </FileList>
        ) : null}
      </FilesContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const FilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 8px;
`

const FileList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`
