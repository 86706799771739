import { attach } from 'effector'

import {
  DownloadDocumentFilePayload,
  downloadPlanReportFileReqFx,
  getDocumentFileByUrlReqFx,
  getDocumentsFileReqFx,
  getDownloadPrinterFormReqFx,
  AdditionalRequestModalInfo,
} from '@/dal'
import { d } from './domain'

export const onAdditionalButtonClicked = d.event<AdditionalRequestModalInfo>()

export const downloadDocumentFlowFile = d.event<DownloadDocumentFilePayload>()
export const downloadActOrEstimate = d.event<DownloadDocumentFilePayload>()
export const downloadPrintableForm = d.event<DownloadDocumentFilePayload>()
export const downloadPlan = d.event<DownloadDocumentFilePayload>()
export const downloadFileByUrl = d.event<DownloadDocumentFilePayload & { url: string }>()

export const onClickCopyButton = d.event<number>()
export const onClickDeleteButton = d.event<number>()

export const downloadActOrEstimateFx = attach({
  effect: getDocumentsFileReqFx,
})

export const downloadPrintableFormFx = attach({
  effect: getDownloadPrinterFormReqFx,
})

export const downloadPlanFx = attach({
  effect: downloadPlanReportFileReqFx,
})

export const downloadFileByUrlFx = attach({
  effect: getDocumentFileByUrlReqFx,
})
