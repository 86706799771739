import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { useUnit } from 'effector-react'
import styled from 'styled-components'

import {
  HeadXL,
  ModalLayoutV2,
  palette,
} from '@/ui'

import {
  $articleTotal,
  $isArticlesModalOpen,
  $openedArticle,
  closeArticlesModal,
} from '../../model/private'
import { AllArticlesFilter, AllArticlesList } from '../containers'

export const AllArticlesModal = React.memo(() => {
  const articleTotal = useUnit($articleTotal)
  const isOpen = useUnit($isArticlesModalOpen)
  const openedArticle = useUnit($openedArticle)

  if (!isOpen) return null

  return (
    <ModalLayoutV2
      closeModal={closeArticlesModal}
      isHidden={Boolean(openedArticle)}
    >
      <Wrapper>
        <HeaderContainer>
          <Header>
            <Title>
              Новости
            </Title>
            <ArticleCount>
              {articleTotal}
            </ArticleCount>
          </Header>
          <AllArticlesFilter />
        </HeaderContainer>

        <Scrollbars autoHeight autoHeightMin={320} autoHeightMax="calc(70vh - 80px)">
          <Container>
            <AllArticlesList />
          </Container>
        </Scrollbars>
      </Wrapper>
    </ModalLayoutV2>
  )
})

const ArticleCount = styled.span`
  color: ${palette.grey60};
`

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: min-content 1fr;
  background-color: ${palette.white};
  position: relative;
  width: 100vw;
  max-width: 700px;
  border-radius: 20px;
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  background-color: ${palette.white};
  padding: 32px 64px 12px 32px;
  border-radius: 20px;
`

const Header = styled.div`
  ${HeadXL}
  display: flex;
  align-items: center;
  gap: 8px;
  width: max-content;
  margin-right: auto;
`

const Title = styled.h5`
  ${HeadXL}
`

const Container = styled.div`
  padding: 12px 32px 32px;
`
