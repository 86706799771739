import { sample } from 'effector'

import {
  $commentFormFiles,
  $hasSelectedAppeal,
  closeAppealChat,
  minimizeAppealChat,
  onAppealChatClosed,
  setCommentForm,
} from './public'
import { $selectedAppeal, appealChatModal } from './private'
import { commentForm } from './forms'

sample({
  clock: closeAppealChat,
  target: appealChatModal.close,
})

sample({
  clock: minimizeAppealChat,
  target: appealChatModal.minimize,
})

sample({
  clock: $selectedAppeal,
  fn: Boolean,
  target: $hasSelectedAppeal,
})

sample({
  clock: appealChatModal.close,
  target: onAppealChatClosed,
})

sample({
  clock: commentForm.fields.files.$value,
  target: $commentFormFiles,
})

sample({
  clock: setCommentForm,
  target: commentForm.set,
})
