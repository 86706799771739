import React from 'react'

import { GridWrapper, Label } from './sharedCSS'
import { CurrencyCol } from './CurrencyCol'

type Props = {
  current: string,
  adjusted: string
  approved: string | null
  label: string
  isTotal?: boolean
  is_changeable?: boolean
  isDraft?: boolean
  type?: 'currency' | 'percent'
  onSubmit?: (val: string) => void
}

export const CurrencyRow = ({
  adjusted,
  approved,
  current,
  is_changeable,
  label,
  isTotal,
  isDraft,
  type = 'currency',
  onSubmit,
}: Props) => (
  <GridWrapper $isTotal={isTotal}>
    <Label $isBold={isTotal}>{label}</Label>
    <CurrencyCol
      value={current}
      isBold={isTotal}
      type={type}
    />
    <CurrencyCol
      value={adjusted}
      isBold={isTotal}
      isChangeable={is_changeable}
      type={type}
      onSubmit={onSubmit}
    />
    {!isDraft && (
      <CurrencyCol
        value={approved ?? '0'}
        isBold={isTotal}
        isGrey={isDraft}
        type={type}
        isApproved
      />
    )}
  </GridWrapper>
)
