import React, { FormEvent, memo, useMemo } from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import {
  HeadXL, Icon2, ModalLayoutV2, NewButton, TextL, TextLLight,
} from '@/ui'
import { noop } from '@/lib/noop'
import { $isDeleteMorphologyModalOpen } from '@/features/estimate-lot/model/morphology.private'
import { updateEstimateLotFx } from '@/features/estimate-lot/model/private'

const mock = [
  {
    object: 'Объект 1',
    building: 'Корпус 1',
    sections: 'Секция 1, Секция 5, Секция 6, Секция 7, Секция 5',
  },
  {
    object: 'Объект 1',
    building: 'Корпус 1',
  },
]

// TODO пока модалка не вызывается
export const ConfirmDeleteModal = memo(() => {
  const [isOpen, isPending] = useUnit([
    $isDeleteMorphologyModalOpen,
    updateEstimateLotFx.pending,
  ])

  const arrowIcon = useMemo(() => (
    <IconWrapper>
      <Icon2 icon="arrowRightS" size={12} color="accent100" />
    </IconWrapper>
  ), [])

  if (!isOpen) return null

  const handleSubmit = (e: FormEvent) => {
  }

  return (
    <ModalLayoutV2 closeModal={isPending ? noop : noop}>
      <Wrapper>
        <Container onSubmit={handleSubmit}>
          <Title>Удаление элемента объекта строительства</Title>

          <Description>
            <Text>
              Обратите внимание, что данные по работам и материалам будут очищены после удаления
              элемента объекта строительства.
            </Text>
            <ConfirmText>
              Вы действительно хотите удалить элементы?
            </ConfirmText>
          </Description>

          <ObjectRows>
            {mock.map(({ object, building, sections }) => (
              // eslint-disable-next-line react/jsx-key
              <Row>
                <Text>{object}</Text>
                {building && (
                  <>
                    {arrowIcon}
                    <Text>{building}</Text>
                  </>
                )}
                {sections && (
                  <>
                    {arrowIcon}
                    <Text>{sections}</Text>
                  </>
                )}
              </Row>
            ))}
          </ObjectRows>
        </Container>

        <Buttons>
          <NewButton
            label="Удалить"
            type="submit"
            isPending={isPending}
            dataTestId="create-estimate-lot-btn"
            isFill
          />
          <NewButton
            label="Отмена"
            isDisabled={isPending}
            buttonType="grey"
            dataTestId="close-lot-creation-modal-btn"
            isFill
            onClick={noop}
          />
        </Buttons>
      </Wrapper>
    </ModalLayoutV2>
  )
})

const IconWrapper = styled.div`
  display: flex;
  padding-top: 4px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  width: 550px;
  padding: 32px;
`

const Title = styled.div`
  ${HeadXL}
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const Text = styled.p`
  ${TextLLight}
`

const ConfirmText = styled.p`
  ${TextL}
`

const ObjectRows = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const Row = styled.div`
  display: flex;
  gap: 4px;

  & > *:not(:last-child) {
    flex-shrink: 0;
  }
`

const Buttons = styled.div`
  display: flex;
  gap: 16px;
`
