import React from 'react'
import styled from 'styled-components'

import { noop } from '@/lib/noop'
import { useTooltip } from '@/lib/hooks'
import { mergeRefs } from '@/lib/mergeRefs'

import { TextL } from '../../typography'
import { Icon2, IconName2 } from '../../icons'
import { DefaultButton } from '../DefaultButton'
import { styles } from './styles'
import { AnimationWrapper } from './wrappers'
import { ButtonSize, ButtonType } from './types'
import { ToolTipV2 } from '../../components/tooltip'
import { ButtonLoader } from './ButtonLoader'

type HandleClick = ((e: React.MouseEvent) => void) | (() => void)

type Props = {
  label: string
  size?: ButtonSize
  isPending?: boolean
  buttonType?: ButtonType
  postfixIcon?: IconName2
  prefixIcon?: IconName2
  isDisabled?: boolean
  type?: 'button' | 'submit'
  tooltip?: string
  width?: number
  onClick?: HandleClick
  dataTestId: string
  isFill?: boolean
}

export const NewButton = React.forwardRef(({
  isPending,
  label,
  size = 'M',
  buttonType = 'primary',
  type = 'button',
  tooltip,
  postfixIcon,
  prefixIcon,
  isDisabled,
  width,
  onClick,
  dataTestId,
  isFill,
}: Props, ref: React.ForwardedRef<HTMLDivElement>) => {
  const {
    floatingStyles,
    getFloatingProps,
    getReferenceProps,
    isOpen,
    refs,
  } = useTooltip({ tooltipOffset: 4 })

  const handleClick = isPending ? noop : onClick

  return (
    <AnimationWrapper
      $isFill={isFill}
      $width={width}
      {...getReferenceProps()}
      ref={mergeRefs([ref ?? null, refs.setReference])}
    >
      <Wrapper
        disabled={Boolean(isDisabled || isPending)}
        onClick={(e) => handleClick?.(e)}
        $size={size}
        $buttonType={buttonType}
        type={type}
        data-testid={dataTestId}
        data-pending={isPending || undefined}
      >
        {prefixIcon ? <Icon2 size={16} icon={prefixIcon} /> : null}
        {label}
        {postfixIcon ? <Icon2 size={16} icon={postfixIcon} /> : null}
      </Wrapper>
      {isPending ? <ButtonLoader buttonType={buttonType} /> : null}

      {tooltip && (
        <ToolTipV2
          floatingStyles={floatingStyles}
          getFloatingProps={getFloatingProps}
          isOpen={isOpen}
          refs={refs}
          size="M"
        >
          {tooltip}
        </ToolTipV2>
      )}
    </AnimationWrapper>
  )
})

type WrapperProps = {
  $size: ButtonSize
  $buttonType: ButtonType
}

const Wrapper = styled(DefaultButton)<WrapperProps>`
  ${TextL}
  transition: color 0.15s linear, background-color 0.15s linear, border 0.15s linear;
  border-radius: 8px;
  gap: 8px;
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: max-content;
  justify-content: center;
  border: 1px solid transparent;
  padding: ${({ $size }) => ($size === 'M' ? '9px 23px' : '5px 19px')};

  ${({ $buttonType: type }) => styles[type]}
`
