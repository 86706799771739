import React from 'react'
import { useUnit } from 'effector-react'

import {
  ContractDirectionLabel,
  DocumentHeader,
} from '@/ui'
import { RouterPaths } from '@/features/app/model'
import { usePendingDocument } from '@/features/document-manager/hooks'
import { $contractDirections } from '@/features/administrative/contract-list/model'
import { ContractDirection, DocumentAlias } from '@/dal'

import {
  $headDocumentInfo,
  cancelActApproveFx,
  onCopy,
  onDeleteButton,
  onSendToApprove,
  openCancelApprovingModal,
  sendActToApproveFx,
} from '../../../model/header.private'
import { $documentId, $isEditBlocking } from '../../../model'
import { $activeTabId, changeActiveTab } from '../../../model/header.public'
import { ACT_PIR_TABS, ACT_TABS } from '../../../model/const'

export const ActHeader = React.memo(() => {
  const [info, id] = useUnit([$headDocumentInfo, $documentId])
  const isPendingApprove = useUnit(sendActToApproveFx.pending)
  const isPendingCancel = useUnit(cancelActApproveFx.pending)
  const isBlocked = useUnit($isEditBlocking)
  const activeTab = useUnit($activeTabId)
  const directions = useUnit($contractDirections)

  const {
    isDeleting,
  } = usePendingDocument({
    id,
    type: DocumentAlias.ACT,
  })

  const ActTypeLabel = React.useCallback(() => {
    if (!info?.actType || !directions) return null

    const subtype = info.isGa ? 'Группы актирования' : undefined

    return (
      <ContractDirectionLabel
        type={info.actType}
        subtype={subtype}
        directions={directions}
        size="M"
      />
    )
  }, [info, directions])

  if (!info) return null

  const canDelete = info.feature.can_delete
  const canCancel = info.feature.can_cancel_approving
  const showApproveButton = info.feature.can_approve || info.feature.can_send_to_approving
  const approveTitle = info.feature.can_send_to_approving ? 'Отправить на согласование' : 'Согласовать'
  const canCopy = info.feature.can_copy

  const isPIR = info.actType === ContractDirection.PDRD

  return (
    <DocumentHeader
      documentName={info.name}
      lastUpdate={info.lastUpdate}
      link={RouterPaths.Administrative}
      canCancelApproving={canCancel}
      canCopy={canCopy}
      canDelete={canDelete}
      approveTitle={approveTitle}
      showApproveButton={showApproveButton}
      isPendingApprove={isPendingApprove}
      isPendingCancel={isPendingCancel}
      isPendingDelete={isDeleting}
      isBlocked={isBlocked}
      unlockDate={info.rejectTime}
      onApproveButton={onSendToApprove}
      onCancelApprove={openCancelApprovingModal}
      onDeleteButton={onDeleteButton}
      docOnApproving={info.feature.on_approving}
      onCopy={onCopy}
      downloadInstructionText="Как подписать документы?"
      downloadInstructionURL={info.instructionLink}
      tabs={isPIR ? ACT_PIR_TABS : ACT_TABS}
      activeTabId={activeTab}
      docSubTypeSlot={<ActTypeLabel />}
      onChangeTab={changeActiveTab}
    />
  )
})
