import {
  CompanyItem, DocumentAlias, DocumentId, DocumentStatuses, FileDTO,
} from '../interfaces'

export type SupportAppealsFilters = {
  search_query?: string
  contragent_id: CompanyItem['id'][]
  issue_type: ResponsibleAppealAlias[]
  project_id: number[]
  date_from?: string
  date_to?: string
  status: AppealTabValue
}

export enum AdministrationAppealStatus {
  All = 'all',
  Unread = 'unread',
  WithoutResponse = 'without_response',
  Completed = 'completed',
}

type RequestStatuses = {
  name: string
  count: number
  value: AdministrationAppealStatus
}

type RequestAttachment = {
  name: string
  url: string
}

export type AppealItemMessage = {
  user_lkp: boolean
  author: string
  content: string
  files: FileDTO[]
  /** isoDate */
  created_at: string
}

export enum ResponsibleAppealAlias {
  /** Директор проекта */
  ProjectDir = 'project_dir',
  /** Директор стройки */
  ConstructionDir = 'construction_dir',
  /** Координатор проекта */
  ProjectCor = 'project_cor',
  /** Бухгалтер */
  ActReq = 'actreq',
  /** Финансист */
  Act = 'act',
  /** Ответственный по договору */
  Contract = 'contract',
  /** Сметчик */
  Estimate = 'estimate',
  /** Задача на подрядчика */
  Contragent = 'contragent',
}

export enum OtherAppealAlias {
  /** В техническую поддержку */
  TechSupport = 'to_tech_support',
  /** Отключить пользователя от ЛКП */
  BlockUser = 'block_user',
  /** Добавить пользователя в ЛКП */
  CreateUser = 'create_user',
}

export type AppealAlias = ResponsibleAppealAlias | OtherAppealAlias

export type AdministrationAppealFilterItem = {
  value: ResponsibleAppealAlias | 'all'
  label: string
}

export enum AdministrationAppealDocType {
  Contract = 'contract',
  Payment = 'znr',
  Act = 'act',
  Budget = 'plan',
  Estimate = 'estimate',
}

export enum LkpSection {
  /** Доп. запросы */
  ADDITIONAL_REQUESTS = 1,
  /** Работа с файлами */
  FILE_OPERATIONS = 2,
  /** Моя компания */
  COMPANY_INFO = 3,
  /** Профиль пользователя */
  USER_PROFILE = 4,
  /** Авторизация */
  AUTHORIZATION = 5,
  /** Помощь и поддержка */
  SUPPORT = 6,
  /** Главная */
  MAIN_PAGE = 7,
  /** Документооборот */
  DOCUMENT_FLOW = 8,
  /** Уведомления */
  NOTIFICATIONS = 9,
  /** Общие настройки */
  GENERAL_SETTINGS = 10,
}

export type AdministrativeRequestType = {
  alias: string
  name: ResponsibleAppealAlias
}

export type AdministrationAppealDocument = {
  name: string
  type: AdministrationAppealDocType
  id: number
  status: DocumentStatuses
}

export enum AppealStatus {
  /** Новый */
  New = 'new',
  /** Ожидает ответа */
  WaitingResponse = 'waiting_response',
  /** Отменен */
  Canceled = 'canceled',
  /** Закрыт */
  Closed = 'closed',
  /** В работе */
  InWork = 'in_work',
}

export type AppealItem = {
  // id может совпадать у элементов с разным issue_type
  id: number
  number: string
  issue_type: AppealAlias
  subject: string
  project_name: string | null
  /** YYYY-MM-DD */
  date: string
  author: string
  status: AppealStatus
  messages: AppealItemMessage[]

  related_document_id: DocumentId | null
  related_document_type: DocumentAlias | null
  related_document_name: string | null
  section_data: {
    type: LkpSection
    label: string
  } | null

  is_automatic: boolean
  is_my_issues: boolean
  can_resume: boolean
  can_copy: boolean
  can_cancelled: boolean
  can_send_message: boolean
  can_open_chat: boolean
}

export type GetAppealsParams = Partial<SupportAppealsFilters>

export type AppealFiltersResponse = {
  statuses: {
    name: string
    value: AppealTabValue
    count: number
  }[]
  issue_types: {
    alias: string
    name: string
  }[]
  projects: {
    id: number
    name: string
  }[]
}

export type AppealTabValue = AppealStatus | 'all' | 'my_issues'

export type AppealTypeTab = {
  value: AppealTabValue
  label: string
  count?: number
}

export type AdministrativeAppealItemType = {
  id: number
  request_number: string
  created_date: string // isoDate
  expiration_date: string // isoDate
  start_date: string // isoDate
  end_date: string // isoDate
  status: string
  task_type: ResponsibleAppealAlias
  attachments: RequestAttachment[]

  description: string
  executor: string
  completed: boolean

  is_read: boolean
  is_found_answer: boolean
  can_send_message: boolean
} & ({
  related_document_id: number
  related_document_name: string
  related_document_project_name: string
  related_document_sum: number
  related_document_type: DocumentAlias
} | {
  related_document_id: null
  related_document_name: null
  related_document_project_name: null
  related_document_sum: null
  related_document_type: null
})

export type GetAdministrativeAppealInfoParams = {
  request_type?: string[]
  from?: string
  to?: string
  status?: AdministrationAppealStatus[]
}

export type GetDocumentListParams = {
  task_type: string
  search_by_name?: string
}

export type GetDocumentListResponse = AdministrationAppealDocument[]

export type GetAdministrativeRequestTypesResponse = AdministrativeRequestType[]

export type GetAdministrativeAppealInfoResponse = {
  items: AdministrativeAppealItemType[]
  request_status: RequestStatuses[]
}

export type ContactsFields = {
  contact_person_name: string
  contact_person_phone: string
}

export type PostAppealFormParams = {
  target_document_id: number
  task_type: ResponsibleAppealAlias
  subject: string
  description: string
  files?: File[]
  doc_type: AdministrationAppealDocType
} & ContactsFields

export type SendLkpSectionProblemPayload = {
  section: LkpSection
  title: string
  description: string
  files?: File[]
} & ContactsFields

export type SendMessagePayload = {
  id: AppealItem['id']
  content: string
  files?: File[]
}

export type CompleteTaskPayload = {
  id: number
  result: string
  files?: File[]
}

export type CancelAppealPayload = {
  id: number
  appealType: AppealAlias
  reason?: string
  number: string
}

export type ResumeAppealPayload = {
  id: number
  appealType: AppealAlias
}

export type PostAppealFormResponse = AdministrativeAppealItemType

export type GetJiraDocumentsByType = {
  type: DocumentAlias
}

export type JiraDocument = {
  status: DocumentStatuses,
  name: string,
  id: number
}

export type AddUserParams = {
  email: string
  phone: string
  user_info: string
  first_name: string
  patronymic: string
  last_name: string
}

export type MyUsersInfo = {
  id: number
  email: string
  fio: string
}

export type SendDocumentProblemPayload = {
  related_document_id: number
  related_document_type: DocumentAlias
} & Omit<SendLkpSectionProblemPayload, 'section'>

export type AppealComment = {
  id: number
  author: string
  /** ISO date */
  date_time: string
  body: string
  user_lkp: boolean
  files: FileDTO[]
}

// TODO новая DTO для редизайна, актуализировать потом
export type AppealCommentV2 = {
  author: string
  /** ISO date */
  created_at: string
  content: string
  user_lkp: boolean
  files: FileDTO[]
}

export type PostAppealCommentResponse = {
  item: AppealItem
  comments: AppealComment[]
}

export type PutAppealMessageReqParams = {
  comment?: string
  files?: File[]
  id: AppealItem['id']
}

export type PutAppealMessageReqResponse = {
  item: AppealItem
  comments: AppealComment[]
}
