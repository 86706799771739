import React from 'react'
import { useGate, useUnit } from 'effector-react'

import { DetailsInfo, EditDetailsForm } from '../containers'
import { $isEditing, DetailsGate } from '../../model/private'

export const Details = React.memo(() => {
  const isEditing = useUnit($isEditing)

  useGate(DetailsGate)

  return isEditing ? <EditDetailsForm /> : <DetailsInfo />
})
