import React from 'react'
import styled from 'styled-components'

import { DocumentProgress, DocumentStatuses, RejectionReason } from '@/dal'

import { PaletteColor, palette } from '../../palette'
import { DocumentStatusLabel, Label } from '../labels'
import { ProgressBar } from './ProgressBar'
import { TextMLight } from '../../typography'
import { ConnectedPayment } from './ConnectedPayment'
import { RejectionInfo } from './RejectionInfo'
import { ConnectedPaymentType } from '../../types'

type Props = {
  progress: DocumentProgress
  status: DocumentStatuses
  connectedPayment?: ConnectedPaymentType | null
  rejectionReason?: RejectionReason
  cancelComment?: {
    description: string
    help_description: string
  } | null
}

const progressBarAllowedStatuses = [
  DocumentStatuses.AgreedTechSupervisor,
  DocumentStatuses.AgreedContractor,
  DocumentStatuses.DocumentsWaiting,
  DocumentStatuses.ToBeAgreed,
  DocumentStatuses.Accepted,
  DocumentStatuses.FinalApproved,
  DocumentStatuses.OnCancellation,
]

export const DocumentInfoTop = ({
  progress,
  status,
  connectedPayment,
  rejectionReason,
  cancelComment,
}: Props) => {
  const isProgressBarShown = progressBarAllowedStatuses.includes(status)

  // Если вдруг это будет мешать, или потребуется кастомить, будем выносить это выше.
  const progressBarData = React.useMemo(() => {
    if (!progress || !isProgressBarShown) return null
    const approvePercent = (progress.agreed_by_default / (progress.total_visas_default || 1)) * 100
    const visasPercent =
      (progress.agreed_additional_visas / (progress.total_additional_visas || 1)) * 100
    const items = [{ color: 'accent90' as PaletteColor, percent: approvePercent }]
    if (visasPercent) items.push({ color: 'accent40' as PaletteColor, percent: visasPercent })
    return items
  }, [progress, isProgressBarShown])

  const rejectionReasonAuthor =
    typeof rejectionReason === 'object' ? rejectionReason?.message : null

  return (
    <Top>
      <Row>
        <LabelItem>
          <Square color="accent90" />
          <DocumentStatusLabel status={status} progress={progress} />
        </LabelItem>

        <LabelItem>
          <Square color="accent40" />
          <Label
            bgColor="grey10"
            colorText="grey90"
            borderColor="grey30"
            text={`${progress.agreed_additional_visas}/${progress.total_additional_visas} Доп. запросов`}
          />
        </LabelItem>
      </Row>
      {isProgressBarShown && <ProgressBar items={progressBarData} />}

      {(rejectionReasonAuthor || progress.responsible) && (
        <ResponsibleName>
          Ответственный – {rejectionReasonAuthor || progress.responsible}
        </ResponsibleName>
      )}

      {connectedPayment && <ConnectedPayment {...connectedPayment} />}

      <RejectionInfo
        status={status}
        rejectionReason={rejectionReason}
        cancelComment={cancelComment}
      />
    </Top>
  )
}

const Top = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const Row = styled.div`
  display: flex;
  gap: 16px;
`

const LabelItem = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`

const Square = styled.div<{ color: PaletteColor }>`
  background-color: ${({ color }) => palette[color]};
  width: 12px;
  height: 12px;
  border-radius: 2px;
`

const ResponsibleName = styled.div`
  ${TextMLight}
  color: ${palette.grey70};
`
