import React from 'react'
import { createPortal } from 'react-dom'

import { useTooltip } from '@/lib/hooks'
import { Icon2, LightTooltip } from '@/ui'

type Props = {
  comment: string
}

export const RejectionComment = ({ comment }: Props) => {
  const {
    floatingStyles,
    getFloatingProps,
    getReferenceProps,
    isOpen,
    refs,
    arrowProps,
  } = useTooltip({ tooltipOffset: 10, tooltipCrossOffset: 12, placement: 'top-end' })

  return (
    <>
      <div ref={refs.setReference} {...getReferenceProps()}>
        <Icon2 icon="message" color="accent100" />
      </div>
      {createPortal(
        <LightTooltip
          floatingStyles={floatingStyles}
          getFloatingProps={getFloatingProps}
          isOpen={isOpen}
          refs={refs}
          arrowProps={arrowProps}
        >
          {comment}
        </LightTooltip>,
        document.body,
      )}
    </>
  )
}
