import { useUnit } from 'effector-react'
import React from 'react'
import styled from 'styled-components'

import { NewInput } from '@/ui'

import { FilterDropdown } from '../container'
import { $isResetRowsPending } from '../../../model'
import { $searchInputValue, changeSearch, acceptSearch } from '../../../model/filters.private'

export const TableFilter = React.memo(() => {
  const value = useUnit($searchInputValue)
  const isBlocked = useUnit($isResetRowsPending)

  return (
    <>
      <InputWrapper>
        <NewInput
          dataTestId="search-work-in-table-input"
          onChange={changeSearch}
          value={value}
          placeholder="Поиск по наименованию услуг и ID"
          postfixIcon="search"
          disabled={isBlocked}
          onEnterPress={acceptSearch}
          onPostfixIconClick={acceptSearch}
        />
      </InputWrapper>
      <FilterDropdown isDisabled={isBlocked} />
    </>
  )
})

const InputWrapper = styled.div`
  width: 320px;
`
