import { attach } from 'effector'
import { attachWrapper } from '@42px/effector-extra'
import { AxiosError, AxiosResponse } from 'axios'

import { prepareFormData } from '@/lib/form-data'

import { Method, authRequestFx, downloadFilesFx } from '../request'
import {
  AvansTableItem,
  CancelPaymentApproveParams,
  ContragentDTO,
  CreateContragentOrBankAccountParams,
  CreateContragentResponse,
  GetAvansTableResponse,
  GetPaymentAvansTableParams,
  PaymentDocItem,
  PaymentErrorResponse,
  PaymentInfo,
  PostPaymentFilesParams,
  PutAvansItemParams,
  PutPaymentInfoParams,
  Summ,
} from './types'
import { defaultErrorHandler } from '../error-handler'
import {
  AbortableRequest, DefaultResponseFail, DocumentAlias, FileDTO, PaymentId, Response,
} from '../interfaces'
import { paymentErrorHandler } from './error-handler'
import { DocumentFileItem, getDocumentFilesReqFx } from '../document-files'

export const createAvansDocumentReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (documentId: number|string) => ({
    method: Method.post,
    url: `/payment/pay_doc/${documentId}`,
  }),
  mapResult: ({ result }: {result: AxiosResponse<PaymentInfo>}) => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const getPaymentAvansTableReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({
    id,
    showOnlyFilled,
    availableAvans,
    signal,
  }: AbortableRequest<GetPaymentAvansTableParams>) => ({
    method: Method.get,
    url: `/payment/item/${id}`,
    query: {
      show_only_filled: showOnlyFilled,
      available_avans: availableAvans,
    },
    signal,
  }),
  mapResult: ({ result }: {result: AxiosResponse<GetAvansTableResponse>}) => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const getPaymentFeaturesReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (id: PaymentId) => ({
    method: Method.get,
    url: `/payment/pay_doc/${id}/features/`,
  }),
  mapResult: ({ result }): PaymentInfo['features'] => result.data.features,
  mapError: ({ error }: { error: AxiosError<PaymentErrorResponse> }) => (
    paymentErrorHandler(error.response?.data)
  ),
})

export const getPaymentTemplateLinkReqFx = attachWrapper({
  effect: getDocumentFilesReqFx,
  mapParams: (id: PaymentId) => ({
    id,
    docType: DocumentAlias.PAYMENT,
  }),
  mapResult: ({ result }): DocumentFileItem | null => (
    result.document_files.filter(({ is_template }) => is_template)[0] ?? null
  ),
})

export const downloadTemplateLinkReqFx = attach({
  effect: downloadFilesFx,
  mapParams: ({ url }: DocumentFileItem) => ({
    url: url.split('/api')[1],
  }),
})

export const putAvansItemReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, value }: PutAvansItemParams) => ({
    method: Method.put,
    url: `/payment/item/${id}`,
    body: {
      sum_avans_request: value,
    },
  }),
  mapResult: (
    { result }: { result: AxiosResponse<AvansTableItem> },
  ) => result.data,
  mapError: ({ error }: { error: AxiosError<PaymentErrorResponse> }) => (
    paymentErrorHandler(error.response?.data)
  ),
})

export const getAvansSumsReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ signal, id }: AbortableRequest<{ id: PaymentId }>) => ({
    method: Method.get,
    url: `/payment/pay_doc/${id}/sums/`,
    signal,
  }),
  mapResult: ({ result }: { result: AxiosResponse<Summ> }) => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const getPaymentItemReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (id: PaymentId) => ({
    method: Method.get,
    url: `/payment/pay_doc/${id}`,
  }),
  // Payment doc
  mapResult: ({ result }: {result: AxiosResponse<PaymentInfo>}) => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const putPaymentInfoReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({
    id,
    isThirdPerson,
    signal,
    ...params
  }: AbortableRequest<PutPaymentInfoParams>) => ({
    method: Method.put,
    url: `/payment/pay_doc/${id}`,
    signal,
    body: prepareFormData({
      ...params,
      is_third_person: isThirdPerson,
      files: undefined,
    }),
  }),
  mapResult: ({ result }: {result: AxiosResponse<PaymentInfo>}) => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const cancelPaymentApproveReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, message }: CancelPaymentApproveParams) => ({
    method: Method.post,
    url: `/payment/reject_pay_doc/${id}`,
    body: {
      message,
    },
  }),
  mapResult: ({ result }: {result: AxiosResponse<PaymentInfo>}) => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const sendPaymentToApproveReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (id: PaymentId) => ({
    method: Method.get,
    url: `/payment/approve_payment/${id}`,
  }),
  mapResult: ({ result }: {result: AxiosResponse<PaymentInfo>}) => result.data,
  mapError: ({ error }: { error: AxiosError<PaymentErrorResponse> }) => (
    paymentErrorHandler(error.response?.data)
  ),
})

export const deletePaymentReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (paymentId: PaymentId) => ({
    method: Method.delete,
    url: `/payment/pay_doc/${paymentId}`,
  }),
  mapResult: ({ result }: {result: AxiosResponse }) => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const copyPaymentReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (paymentId: PaymentId) => ({
    method: Method.post,
    url: `/payment/${paymentId}`,
  }),
  mapResult: ({ result }: {result: AxiosResponse<PaymentDocItem> }) => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const checkINNReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ inn }: AbortableRequest<{ inn: string }>) => ({
    method: Method.get,
    url: `/payment/thrid_face/${inn}/`,
  }),
  mapResult: ({ result }: { result: AxiosResponse<ContragentDTO[]> }) => result.data,
  mapError: ({ error }: { error: AxiosError<PaymentErrorResponse> }) => (
    paymentErrorHandler(error.response?.data)
  ),
})

export const fillTableReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, isFill }: { id: number, isFill: boolean }) => ({
    method: Method.post,
    url: `/payment/pay_doc/${id}/fill_by_remaining/`,
    query: {
      is_fill: isFill,
    },
  }),
  mapResult: ({ result }: { result: AxiosResponse<GetAvansTableResponse> }) => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const postPaymentFilesReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, ...body }: PostPaymentFilesParams) => ({
    method: Method.post,
    url: `/files/by_document/payment/${id}/`,
    body: prepareFormData({ ...body, files: undefined }),
  }),
  mapResult: ({ result }: { result: AxiosResponse<FileDTO[]> }) => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const createContragentReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (body: CreateContragentOrBankAccountParams) => ({
    method: Method.post,
    url: `/payment/pay_doc/${body.payment}/create_contragent/`,
    body,
  }),
  mapResult: ({ result }: { result: AxiosResponse<CreateContragentResponse> }) => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const closeBankWarrantySplashReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (id: number) => ({
    method: Method.post,
    url: `/payment/pay_doc/${id}/close_bank_warranty_splash/`,
  }),
  mapResult: ({ result }: Response<PaymentInfo['features']>) => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})
