import {
  ActGaGroupRow,
  ActGaRow,
  ActRowType,
  ActTableFolder,
  ActTableFolderPIR,
  ActTableRow,
  ActWorkPIR,
  GaMorphologyItem,
} from './types'
import {
  ActCompensationsError,
  ApproveActError,
  CreateActConflictError,
  CreateActReqiredFieldError,
} from './errors'

export const isApproveError = (error: Error): error is ApproveActError =>
  error instanceof ApproveActError

export const isCreateActConflictError = (error: Error): error is CreateActConflictError =>
  error instanceof CreateActConflictError

export const isCreateActReqieredFieldError = (error: Error): error is CreateActReqiredFieldError =>
  error instanceof CreateActReqiredFieldError

export const isActCompensationsError = (error: Error): error is ActCompensationsError =>
  error instanceof ActCompensationsError

export const isPIRActWork = (row: ActTableRow): row is ActWorkPIR => 'status' in row

export const isGaGroupRow = (item: ActGaRow | ActGaGroupRow): item is ActGaGroupRow =>
  'type' in item

export const isGaMorphologyElement = (item: ActTableRow): item is GaMorphologyItem =>
  'row_type' in item &&
  Boolean(item.row_type) &&
  [
    ActRowType.Building,
    ActRowType.ConstructionObject,
    ActRowType.Section,
    ActRowType.Floor,
  ].includes(item.row_type!)

export const isPIRTableFolder = (folder: ActTableFolder): folder is ActTableFolderPIR =>
  typeof folder.period_cost === 'string'
