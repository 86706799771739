import { sample } from 'effector'

import { createToast } from '@/features/toast-service/model'
import { writeQueryFx } from '@/features/shared/query-string/model'
import { onScreenCaptureRequestCanceled, onScreenshotModeExited } from '@/features/screen-capture/model'

import {
  $appeals,
  appealChatModal,
  getSupportAppealsFx,
  getSupportAppealFiltersFx,
  markAppealAsRead,
  putMarkAppealAsReadFx,
  $appealAvailableFilters,
  $appealMessages,
  $selectedAppealTab,
  selectAppealTab,
  sendMessageFx,
  openAppealChat,
  $canSendMessage,
  setCanSendMessage,
  getAppealMessagesFx,
  insertNewAppeal,
  $selectedAppeal,
  readSupportQueryFx,
  $isAppealChatHidden,
} from './private'
import { SupportAppealsGate } from '../../model'
import {
  $filters, acceptFilters, clearFilter, removeFilter, setFilter, setHasUpdatedFilters,
} from '../../filters/model'
import { commentForm } from './forms'
import { onAppealResumed, onNewAppealCreated } from '../../support-appeals/model'
import { onAppealCanceled } from '../../cancel-appeal/model'
import { setIsAppealChatHidden } from './public'

import './public.init'

$appeals
  .on(getSupportAppealsFx.doneData, (_, data) => data)
  .on(insertNewAppeal, (items, item) => [item, ...items])
  .on([onAppealCanceled, onAppealResumed], (items, updatedItem) => (
    items.map((item) => (item.id === updatedItem.id ? updatedItem : item))
  ))
  .reset(SupportAppealsGate.close)

$canSendMessage
  .on(setCanSendMessage, (_, val) => val)
  .reset(appealChatModal.close)

$appealAvailableFilters
  .on(getSupportAppealFiltersFx.doneData, (_, data) => data)
  .reset(SupportAppealsGate.close)

$appealMessages
  .on(getAppealMessagesFx.doneData, (_, data) => data)
  .on(sendMessageFx.doneData, (items, item) => [...items, item])
  .reset(getAppealMessagesFx)

$selectedAppealTab
  .on(selectAppealTab, (_, val) => val)
  .reset(SupportAppealsGate.close)

$isAppealChatHidden
  .on(setIsAppealChatHidden, (_, val) => val)
  .reset(onScreenCaptureRequestCanceled)

sample({
  clock: [SupportAppealsGate.open, acceptFilters, clearFilter, $selectedAppealTab.updates],
  source: {
    filters: $filters,
    status: $selectedAppealTab,
  },
  fn: ({ filters, status }) => ({ status, ...filters }),
  target: getSupportAppealsFx,
})

sample({
  clock: [SupportAppealsGate.open, onNewAppealCreated, onAppealCanceled, onAppealResumed],
  fn: () => ({}),
  target: getSupportAppealFiltersFx,
})

sample({
  clock: [getSupportAppealsFx, clearFilter],
  fn: () => false,
  target: setHasUpdatedFilters,
})

sample({
  clock: [setFilter, removeFilter],
  fn: () => true,
  target: setHasUpdatedFilters,
})

sample({
  clock: onNewAppealCreated,
  source: $selectedAppealTab,
  filter: (tab, { status, is_my_issues }) => (
    ['all', status].includes(tab) || (is_my_issues && tab === 'my_issues')
  ),
  fn: (_, item) => item,
  target: insertNewAppeal,
})

sample({
  clock: markAppealAsRead,
  fn: (id) => ({ id }),
  target: putMarkAppealAsReadFx,
})

// TODO SUPPORT непонятно, нужно ли сейчас отмечать как прочитанное
/* sample({
  clock: putMarkAppealAsReadFx.doneData,
  target: listModel.onMarkAsRead,
}) */

sample({
  clock: $selectedAppeal,
  filter: Boolean,
  fn: (appeal) => ({
    id: appeal.id,
    alias: appeal.issue_type,
  }),
  target: getAppealMessagesFx,
})

sample({
  clock: openAppealChat,
  filter: sendMessageFx.pending.map((val) => !val),
  target: appealChatModal.open,
})

sample({
  clock: openAppealChat,
  filter: sendMessageFx.pending.map((val) => !val),
  fn: ({ can_send_message }) => can_send_message,
  target: setCanSendMessage,
})

sample({
  clock: appealChatModal.close,
  target: commentForm.reset,
})

sample({
  clock: [onAppealResumed, onNewAppealCreated],
  target: appealChatModal.close,
})

sample({
  clock: commentForm.formValidated,
  source: $selectedAppeal,
  filter: Boolean,
  fn: ({ id }, { comment, files }) => ({ id, files, content: comment }),
  target: sendMessageFx,
})

sample({
  clock: [sendMessageFx.done, $selectedAppeal.updates],
  target: commentForm.reset,
})

sample({
  clock: getSupportAppealsFx.doneData,
  target: readSupportQueryFx,
})

sample({
  clock: readSupportQueryFx.doneData,
  source: $appeals,
  filter: (appeals, { id, type }) => (
    appeals.some((item) => item.id === Number(id) && item.issue_type === type)
  ),
  fn: (appeals, { id, type }) => (
    appeals.find((item) => item.id === Number(id) && item.issue_type === type)!
  ),
  target: openAppealChat,
})

sample({
  clock: onScreenshotModeExited,
  filter: appealChatModal.$isMinimized,
  target: appealChatModal.unfold,
})

sample({
  clock: readSupportQueryFx.done,
  target: writeQueryFx.prepend(() => ({})),
})

createToast({
  effect: sendMessageFx,
})
