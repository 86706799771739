import React, { memo } from 'react'
import styled from 'styled-components'

import { FlexScrollbar, NewIconButton, palette } from '@/ui'

import { GA_MODAL_PADDING } from './const'

const SCROLLBAR_PROPS = {
  thumbColor: 'accent90',
  trackZIndex: 10,
  style: { borderTopLeftRadius: 20, borderTopRightRadius: 20 },
} as const

type Props = {
  children: React.ReactNode
  buttonsSlot: React.ReactNode
  canClose?: boolean
  onClose: () => void
}

export const GaModalWrapper = memo(
  ({ children, buttonsSlot, canClose = true, onClose }: Props) => {
    return (
      <ModalOverlay>
        <ModalWrapper>
          <FlexScrollbar {...SCROLLBAR_PROPS}>{children}</FlexScrollbar>

          <CloseButtonWrapper>
            <NewIconButton
              borderRadius="50%"
              backgroundColor="white"
              icon="cross"
              color="grey60"
              hoverColor="grey80"
              hoverBackgroundColor="grey20"
              size={20}
              dataTestId="ga-act-modal-close-btn"
              padding={6}
              disabled={!canClose}
              onClick={() => onClose()}
            />
          </CloseButtonWrapper>

          <Footer>{buttonsSlot}</Footer>
        </ModalWrapper>
      </ModalOverlay>
    )
  },
)

const BASE_PADDING = `${GA_MODAL_PADDING}px`

const ModalOverlay = styled.div`
  position: fixed;
  inset: 0;
  z-index: 10;

  display: flex;
  justify-content: center;
  align-items: end;

  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(4px);
`

const ModalWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  width: 100%;
  height: calc(100% - 56px);
  padding: ${BASE_PADDING};
  padding-bottom: 0;

  background-color: ${palette.white};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`

const CloseButtonWrapper = styled.div`
  position: absolute;
  bottom: calc(100% + 12px);
  right: 12px;
`

const Footer = styled.footer`
  z-index: 1;

  display: flex;
  justify-content: end;
  align-items: center;
  gap: 12px;

  min-height: 72px;
  margin-inline: -${BASE_PADDING};
  padding: 12px ${BASE_PADDING};
  box-shadow: 0px 0px 20px 0px #27272b14;
`
