import React, { memo } from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { GaEndNode } from '@/dal'
import { Cell } from '@/features/act/view'
import { $isDraft } from '@/features/act/model'

import { FirstCellWrapper, MorphologyEndNode } from '../parts'
import { clearMorphologyWorks, fillMorphologyWorks } from '../../model/private'

type Props<T> = {
  items: T[]
  typeName: string
}

export const EndNodeRows = memo(<T extends GaEndNode>({ items, typeName }: Props<T>) => {
  const isDraft = useUnit($isDraft)

  const handleToggle = (works: string[], isClosedCurrentPeriod: boolean | null) => {
    if (isClosedCurrentPeriod) {
      clearMorphologyWorks(works)
    } else {
      fillMorphologyWorks(works)
    }
  }

  return (
    <Wrapper>
      <FirstCellWrapper>
        <Cell bgColor="grey20" borderPosition="bottom" isLeftAlign isBold>
          {typeName}
        </Cell>
        <Cell bgColor="grey20" isLeftAlign isBold>
          Цена
        </Cell>
      </FirstCellWrapper>

      {items.map((item) => (
        <MorphologyEndNode
          key={item.id}
          name={item.name}
          price={item.total_price}
          comment={item.comment}
          isClosedInPrevPeriod={Boolean(item.is_closed_last_period)}
          isClosingInCurrPeriod={Boolean(item.is_closed_current_period)}
          isRejected={item.is_rejected}
          isDisabled={!isDraft}
          onToggle={() => handleToggle(item.works_uuids, item.is_closed_current_period)}
        />
      ))}
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
`
