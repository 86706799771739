import {
  sample,
} from 'effector'
import { condition, debounce } from 'patronum'

import {
  ActDTO,
  ActRowType,
  ActTableRow,
  ContractDirection,
  DocumentAlias,
  ResetActTableParams,
  SendWorkVolumeFxPayload,
} from '@/dal'
import { createToast, showToast } from '@/features/toast-service/model'
import { NotificationType } from '@/ui'
import {
  $selectedGroupId,
  loadGroupTree,
  onSelectedGroupIdChanged,
  resetTree,
} from '@/features/group-tree/model'

import {
  sendActWorkVolumeFx,
  onWorkInputError,
  onFillByRemaining,
  $isCellGroupHidden,
  toggleHiddenGroup,
  fillByRemainingFx,
  $tableRoot,
  initFlatTableFx,
  paginateFlatTableFx,
  setRootUpdate,
  $tableTree,
  getActWorkFolderContentFx,
  setMapUpdate,
  closeFolders,
  $pendingFolderIds,
  getActTreeTableFx,
  $tableInfo,
  getTableData,
  $isTree,
  toggleIsTree,
  uploadTemplateFx,
  templateSelected,
  updateItemsFx,
  openTableItem,
  onLoadMore,
  closeTableItem,
  setItemToFocus,
  $itemToFocus,
  resetItemToFocus,
  onEnterSubmitItem,
  $flatItems,
  $actTotalWorks,
  onChangeSMRActWork,
  onChangePIRActWork,
  getGaActTableItemContentFx,
  setActTotalWorks,
  ActTableGate,
  resetActTable,
  resetActTableFx,
  getGaActTable,
  getActTable,
  getGaActTableFx,
  getGaActTableItemContent,
  getActWorkFolderContent,
  $hasMorphology,
} from './private'

import './filters.init'
import { $worksTableSearch, filtersForm, resetFilters } from './filters.private'
import { TreeType } from './types'
import { mapFilters } from './helper'
import {
  $actType,
  $documentId,
  $isGaAct,
  ActGate,
  updateFeatures,
  updateKSData,
} from '../../model'
import { cancelActApproveFx, sendActToApproveFx } from '../../model/header.private'
import { markTablesAsChanged } from '../../model/private'

$isTree
  .on(toggleIsTree, (val) => !val)
  .reset(ActTableGate.close)

$actTotalWorks
  .on(setActTotalWorks, (_, val) => val)
  .reset(ActGate.close)

$isCellGroupHidden
  .on(toggleHiddenGroup, (isHidden) => !isHidden)
  .reset(ActTableGate.close)

$tableRoot
  .on(getActTreeTableFx.doneData, (_, { root }) => root)
  .on(getGaActTableFx.doneData, (_, { root }) => root)
  .on(setRootUpdate, (_, root) => root)
  .on(initFlatTableFx.doneData, (_, { data }) => data.items)
  .on(paginateFlatTableFx.doneData,
    (prevData, { data }) => (prevData ? [...prevData, ...data.items] : data.items))
  .reset(ActTableGate.close)

$tableTree
  .on([
    getActWorkFolderContentFx.done,
    getGaActTableItemContentFx.done,
  ], (map, { params, result }) => ({
    ...map,
    [params.groupId]: result,
  }))
  .on(setMapUpdate, (map, update) => ({
    ...map,
    ...update,
  }))
  .on(closeFolders, (_, map) => map)
  .reset(ActTableGate.close, getActTreeTableFx, initFlatTableFx, $isTree.updates)

$pendingFolderIds
  .on(
    [getActWorkFolderContentFx, getGaActTableItemContentFx],
    (arr, params) => [...arr, params.groupId],
  )
  .on(
    [getActWorkFolderContentFx.finally, getGaActTableItemContentFx.finally],
    (arr, { params }) => arr.filter((id) => id !== params.groupId),
  )
  .reset(ActTableGate.close, getActTreeTableFx, initFlatTableFx)

$tableInfo
  .on(getActTreeTableFx.doneData, (_, { root, ...info }) => info)
  .on(getGaActTableFx.doneData, (_, { root, ...info }) => info)
  .on(initFlatTableFx.doneData, (_, { data: { items, ...info } }) => info)
  .on(sendActWorkVolumeFx.doneData, (_, {
    root,
    target,
    tree,
    ks2,
    ks3,
    ...info
  }) => info)
  .reset(ActTableGate.close)

$itemToFocus
  .on(setItemToFocus, (_, id) => id)
  .reset(resetItemToFocus, ActGate.close)

sample({
  clock: onEnterSubmitItem,
  source: $flatItems,
  filter: Boolean,
  fn: (items, id) => {
    const index = items.findIndex((item) => item.id === id)
    const hasNextItem = index !== -1 && index < items.length - 1
    return hasNextItem ? items[index + 1]?.id : null
  },
  target: setItemToFocus,
})

sample({
  clock: ActTableGate.close,
  target: resetTree,
})

sample({
  clock: ActTableGate.open,
  target: getTableData,
})

sample({
  clock: ActTableGate.open,
  source: {
    id: $documentId,
    isGa: $isGaAct,
  },
  filter: ({ id, isGa }) => Boolean(id && !isGa),
  fn: ({ id }) => ({
    documentType: DocumentAlias.ACT,
    documentId: id!,
  }),
  target: loadGroupTree,
})

sample({
  clock: [initFlatTableFx.doneData, getActTreeTableFx.doneData],
  fn: ({ total }) => total ?? 0,
  target: setActTotalWorks,
})

sample({
  clock: sendActWorkVolumeFx.doneData,
  source: {
    currentRoot: $tableRoot,
    currentTree: $tableTree,
  },
  fn: (tableData, { target, root, tree }) => ({
    target, root, tree, ...tableData,
  }),
  target: updateItemsFx,
})

sample({
  clock: sendActWorkVolumeFx.doneData,
  fn: ({ ks2, ks3, fill_by_remaining }) => ({
    ks2,
    ks3,
    is_fill_by_remaining: fill_by_remaining,
  }),
  target: updateKSData,
})

sample({
  clock: sendActWorkVolumeFx.done,
  fn: ({ params }) => params.actId,
  target: updateFeatures,
})

sample({
  clock: sendActWorkVolumeFx.done,
  target: markTablesAsChanged,
})

sample({
  clock: onWorkInputError,
  fn: () => ({
    content: 'Нельзя указывать объём работы больший, чем остаток к выполнению',
    icon: NotificationType.Alert,
  }),
  target: showToast,
})

sample({
  clock: [onChangeSMRActWork, onChangePIRActWork],
  source: {
    form: filtersForm.$values,
    name: $worksTableSearch,
    actId: $documentId,
    groupId: $selectedGroupId,
    isTree: $isTree,
    actType: $actType,
  },
  filter: ({ actId, actType }, { workId }) => Boolean(actId && actType && workId),
  fn: (params, work): SendWorkVolumeFxPayload => {
    return {
      ...params,
      actId: params.actId as ActDTO['id'],
      actType: params.actType as ContractDirection,
      workId: work.workId,
      value: work.value,
    }
  },
  target: sendActWorkVolumeFx,
})

sample({
  clock: updateItemsFx.doneData,
  fn: ({ root }) => root,
  target: setRootUpdate,
})

sample({
  clock: updateItemsFx.doneData,
  filter: ({ tree }) => Boolean(tree),
  fn: ({ tree }) => tree as TreeType,
  target: setMapUpdate,
})

createToast({
  effect: sendActWorkVolumeFx,
  doneText: 'Изменения сохранены',
})

sample({
  clock: onFillByRemaining,
  source: $documentId,
  filter: Boolean,
  fn: (actId, isFill) => ({
    actId,
    isFill,
  }),
  target: fillByRemainingFx,
})

sample({
  clock: uploadTemplateFx.done,
  target: [resetFilters, getTableData],
})

sample({
  clock: uploadTemplateFx.doneData,
  fn: ({ ks }) => ks,
  target: updateKSData,
})

sample({
  clock: fillByRemainingFx.doneData,
  target: updateKSData,
})

const $hasDocumentId = $documentId.map(Boolean)

const getActTableSource = {
  form: filtersForm.$values,
  name: $worksTableSearch,
  actId: $documentId,
  groupId: $selectedGroupId,
  actType: $actType,
}

sample({
  clock: onLoadMore,
  source: getActTableSource,
  filter: $hasDocumentId,
  fn: mapFilters,
  target: paginateFlatTableFx,
})

condition({
  source: sample({
    clock: [
      getTableData,
      filtersForm.$values,
      debounce({ source: $worksTableSearch, timeout: 700 }),
      onSelectedGroupIdChanged,
      $isTree.updates,
      fillByRemainingFx.done,
      sendActToApproveFx.done,
      cancelActApproveFx.done,
      resetActTableFx.done,
    ],
    source: $documentId,
    filter: Boolean,
  }),
  if: $isGaAct,
  then: getGaActTable,
  else: getActTable,
})

sample({
  clock: getGaActTable,
  source: {
    form: filtersForm.$values,
    name: $worksTableSearch,
    groupId: $selectedGroupId,
    actType: $actType,
    hasMorphology: $hasMorphology,
    isFolderView: $isTree,
  },
  fn: (source, actId) => ({ actId, ...source }),
  target: getGaActTableFx,
})

condition({
  source: sample({
    clock: getActTable,
    source: getActTableSource,
    fn: mapFilters,
  }),
  if: $isTree,
  then: getActTreeTableFx,
  else: initFlatTableFx,
})

sample({
  clock: templateSelected,
  source: $documentId,
  filter: Boolean,
  fn: (actId, file) => ({
    actId,
    file,
  }),
  target: uploadTemplateFx,
})

sample({
  clock: closeTableItem,
  source: $tableTree,
  fn: (map, parentId) => {
    const copyMap = { ...map }
    const closeChildren = (children: ActTableRow[] | null) => {
      children?.forEach((node) => {
        closeChildren(copyMap[node.id])
        delete copyMap[node.id]
      })
    }

    closeChildren(copyMap[parentId])
    delete copyMap[parentId]

    return copyMap
  },
  target: closeFolders,
})

const getTableItemContentSource = {
  form: filtersForm.$values,
  name: $worksTableSearch,
  actId: $documentId,
  actType: $actType,
  isFolderView: $isTree,
  hasMorphology: $hasMorphology,
}

condition({
  source: openTableItem,
  if: $isGaAct,
  then: getGaActTableItemContent,
  else: getActWorkFolderContent,
})

sample({
  clock: getActWorkFolderContent,
  source: getTableItemContentSource,
  filter: ({ actId, actType }, { id }) => Boolean(id && actId && actType),
  fn: ({
    form,
    name,
    actId,
    actType,
  }, { id }) => {
    return {
      ...form,
      name,
      groupId: id!,
      actId: actId!,
      actType: actType!,
    }
  },
  target: getActWorkFolderContentFx,
})

sample({
  clock: getGaActTableItemContent,
  source: getTableItemContentSource,
  filter: ({ actId }, { type, id }) => Boolean(actId && type && id),
  fn: ({
    form,
    name,
    actId,
    isFolderView,
    hasMorphology,
  }, { type, id, morphologyId, morphologyType }) => {
    const isFolder = type === ActRowType.Folder
    return {
      ...form,
      name,
      isFolderView,
      hasMorphology,
      actId: actId!,
      groupId: id,
      groupType: type,
      morphologyId: isFolder ? morphologyId : id,
      morphologyType: isFolder ? morphologyType : type,
    }
  },
  target: getGaActTableItemContentFx,
})

sample({
  clock: resetActTable,
  source: {
    id: $documentId,
    hasMorphology: $hasMorphology,
  },
  filter: ({ id }) => Boolean(id),
  fn: (payload) => payload as ResetActTableParams,
  target: resetActTableFx,
})

sample({
  clock: resetActTableFx.done,
  target: markTablesAsChanged,
})

sample({
  clock: resetActTableFx.doneData,
  target: updateKSData,
})

createToast({
  effect: uploadTemplateFx,
  doneText: 'Данные из Excel-шаблона успешно загружены',
  errorText: 'Произошла ошибка при загрузке Excel-шаблона',
})

createToast({
  effect: cancelActApproveFx,
})
