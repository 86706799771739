import React from 'react'

import { TableCell } from '@/ui'

import { CellRow, SplitCellWrapper } from './cellWrappers'

type Props = {
  isHiddenGroup: boolean
  periodCost: {
    total: string
    material: string
    work: string
  }
}

export const GaCommonCells = ({ isHiddenGroup, periodCost }: Props) => {
  return (
    <>
      {!isHiddenGroup && (
        <>
          <TableCell isEmpty />
          <TableCell isEmpty />
        </>
      )}

      <TableCell isEmpty />
      <TableCell isEmpty />
      <TableCell isEmpty />
      <TableCell isEmpty />

      <SplitCellWrapper>
        <TableCell isEmpty />
        <CellRow>
          <TableCell isEmpty />
          <TableCell isEmpty />
        </CellRow>
      </SplitCellWrapper>

      <SplitCellWrapper>
        <TableCell value={periodCost.total} align="center" />
        <CellRow>
          <TableCell value={periodCost.material} align="center" />
          <TableCell value={periodCost.work} align="center" />
        </CellRow>
      </SplitCellWrapper>
    </>
  )
}
