import React, {
  useCallback, useEffect, useRef, useState,
} from 'react'
import styled, { css } from 'styled-components'
import { useUnit } from 'effector-react'

import { palette } from '@/ui'

import {
  $isScreenshotModeEnabled,
  enableScreenshotMode,
  exitScreenshotMode,
  resetIsAskingForScreenCapture,
} from '../../model/private'
import {
  $isAskingForScreenCapture,
  onScreenCaptureRequestCanceled,
  onScreenshotSaved,
} from '../../model'
import { Actions, BottomHint } from '../parts'
import { getEndCoords } from '../../model/helpers'

const DEFAULT_COORDS = { x: 0, y: 0 }
const MIN_SIZE = 20

type SelectedArea = {
  top: number
  left: number
  width: number
  height: number
} | null

export const ScreenCapture = () => {
  const screenshotRef = useRef<HTMLCanvasElement>(null)
  const areaRef = useRef<HTMLCanvasElement>(null)

  const [isMoving, setIsMoving] = useState(false)
  const [startCoords, setStartCoords] = useState(DEFAULT_COORDS)
  const [selectedArea, setSelectedArea] = useState<SelectedArea>(null)
  const [screenshotResolution, setScreenshotResolution] = useState({ width: 0, height: 0 })

  const [isEditing, isAsking] = useUnit([
    $isScreenshotModeEnabled,
    $isAskingForScreenCapture,
  ])

  const refillAreaCanvas = useCallback(() => {
    const areaCanvas = areaRef.current
    const areaCtx = areaCanvas?.getContext('2d')
    if (!areaCanvas || !areaCtx) return

    areaCtx.clearRect(0, 0, areaCanvas.width, areaCanvas.height)
    areaCtx.fillStyle = 'rgba(0, 0, 0, 0.4)'
    areaCtx.fillRect(0, 0, areaCanvas.width, areaCanvas.height)
  }, [areaRef])

  const onReset = useCallback(() => {
    setSelectedArea(null)
    setStartCoords(DEFAULT_COORDS)
    refillAreaCanvas()
  }, [refillAreaCanvas])

  const captureScreenshot = useCallback(async () => {
    if (!('ImageCapture' in window)) return

    const areaCanvas = areaRef.current
    const screenshotCanvas = screenshotRef.current
    const screenshotCtx = screenshotCanvas?.getContext('2d')

    if (!screenshotCtx || !screenshotCanvas || !areaCanvas) return

    const { innerWidth, innerHeight } = window
    let stream: MediaStream | undefined

    try {
      stream = await navigator.mediaDevices.getDisplayMedia({
        audio: false,
        video: {
          displaySurface: 'window',
          width: { ideal: innerWidth, max: innerWidth },
          height: { ideal: innerHeight, max: innerHeight },
        },
        preferCurrentTab: true,
      })
    } catch {
      onScreenCaptureRequestCanceled()
    } finally {
      resetIsAskingForScreenCapture()
    }

    if (!stream) return

    const track = stream.getVideoTracks()[0]
    const imageCapture = new (window.ImageCapture as any)(track)
    const bitmap = await imageCapture.grabFrame()
    track.stop()

    setScreenshotResolution({ width: bitmap.width, height: bitmap.height })

    screenshotCanvas.width = bitmap.width
    screenshotCanvas.height = bitmap.height
    areaCanvas.width = bitmap.width
    areaCanvas.height = bitmap.height
    screenshotCtx.clearRect(0, 0, areaCanvas.height, areaCanvas.height)
    refillAreaCanvas()
    screenshotCtx.drawImage(bitmap, 0, 0, bitmap.width, bitmap.height)

    enableScreenshotMode()
  }, [areaRef, screenshotRef, refillAreaCanvas])

  const onMouseDown = useCallback((e: React.MouseEvent) => {
    e.preventDefault()
    refillAreaCanvas()
    setIsMoving(true)
    setStartCoords({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY })
  }, [refillAreaCanvas])

  const onMouseMove = useCallback((e: MouseEvent) => {
    if (!isMoving) return

    const { endX, endY } = getEndCoords({
      e,
      screenshotResolution,
      element: screenshotRef.current,
    })

    refillAreaCanvas()

    const ctx = areaRef.current?.getContext('2d')
    if (!ctx) return

    ctx.globalCompositeOperation = 'destination-out'
    ctx.fillStyle = 'rgba(0, 0, 0, 1)'
    ctx.fillRect(startCoords.x, startCoords.y, endX - startCoords.x, endY - startCoords.y)
    ctx.globalCompositeOperation = 'source-over'
    ctx.setLineDash([8, 8])
    ctx.strokeStyle = palette.grey80
    ctx.lineWidth = 1
    ctx.strokeRect(startCoords.x, startCoords.y, endX - startCoords.x, endY - startCoords.y)
  }, [startCoords, isMoving, areaRef, screenshotRef, screenshotResolution, refillAreaCanvas])

  const onMouseUp = useCallback((e: MouseEvent) => {
    if (!isMoving) return
    setIsMoving(false)

    const { endX, endY } = getEndCoords({
      e,
      screenshotResolution,
      element: screenshotRef.current,
    })

    const width = Math.abs(endX - startCoords.x)
    const height = Math.abs(endY - startCoords.y)

    if (width < MIN_SIZE || height < MIN_SIZE) {
      onReset()
      return
    }
    setSelectedArea({
      width,
      height,
      top: endY > startCoords.y ? startCoords.y : endY,
      left: endX > startCoords.x ? startCoords.x : endX,
    })
  }, [isMoving, startCoords, screenshotResolution, screenshotRef, onReset])

  useEffect(() => {
    if (isAsking) {
      captureScreenshot()
    }
  }, [isAsking, captureScreenshot])

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onReset()
        exitScreenshotMode()
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [onReset])

  useEffect(() => {
    if (isEditing) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.removeProperty('overflow')
    }
  }, [isEditing])

  useEffect(() => {
    window.addEventListener('mouseup', onMouseUp)
    window.addEventListener('mousemove', onMouseMove)
    return () => {
      window.removeEventListener('mouseup', onMouseUp)
      window.removeEventListener('mousemove', onMouseMove)
    }
  }, [onMouseUp, onMouseMove])

  const handleCaptureAndSave = async () => {
    if (!selectedArea) return

    const canvas = document.createElement('canvas')
    canvas.width = selectedArea.width
    canvas.height = selectedArea.height
    const ctx = canvas.getContext('2d')!
    const screenshotCanvas = screenshotRef.current
    if (!screenshotCanvas) return
    ctx.drawImage(screenshotCanvas, selectedArea.left, selectedArea.top, selectedArea.width,
      selectedArea.height, 0, 0, selectedArea.width, selectedArea.height)

    const image = canvas.toDataURL()
    const res = await fetch(image)
    const buff = await res.arrayBuffer()
    const blob = new Blob([buff], { type: 'image/png' })

    onScreenshotSaved(blob)
    onReset()
    exitScreenshotMode()
  }

  return (
    <Wrapper $isShown={isEditing}>
      <Container style={{ ...screenshotResolution }}>
        <CanvasWrapper style={{ ...screenshotResolution }} onMouseDown={onMouseDown}>
          <Canvas ref={screenshotRef} $isShown={isEditing} />
          <Canvas ref={areaRef} $isShown={isEditing} />
        </CanvasWrapper>

        {isEditing && (
          <Overlay style={selectedArea ?? {}}>
            <Actions
              isShown={!isMoving && Boolean(selectedArea)}
              onSave={handleCaptureAndSave}
              onCancel={onReset}
            />
          </Overlay>
        )}
      </Container>

      {!isMoving && !selectedArea && <BottomHint />}
    </Wrapper>
  )
}

const Container = styled.div`
  position: relative;
`

const Wrapper = styled.div<{ $isShown: boolean }>`
  z-index: 2000;
  position: fixed;
  inset: 0;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;

  ${({ $isShown }) => !$isShown && css`
    visibility: hidden;
  `}
`

const CanvasWrapper = styled.div`
  position: relative;
  cursor: crosshair;
`

const Canvas = styled.canvas<{ $isShown: boolean }>`
  position: absolute;

  ${({ $isShown }) => !$isShown && css`
    display: none;
  `}
`

const Overlay = styled.div`
  position: absolute;
`
