import { attachWrapper } from '@42px/effector-extra'
import { AxiosResponse } from 'axios'

import { Method, authRequestFx } from '../request'
import { defaultErrorHandler } from '../error-handler'
import { MetricsRequestParams } from './types'
import { DefaultResponseFail } from '../interfaces'

export const sendMetricsReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (body: MetricsRequestParams) => ({
    url: '/metrics/obtain_cid/',
    method: Method.post,
    body,
  }),
  mapResult: ({ result }: { result: AxiosResponse }) => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})
