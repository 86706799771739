import {
  ResponsibleAppealAlias, DocumentAlias, LkpSection, OtherAppealAlias, AppealAlias,
} from '@/dal'
import { getDocumentNameByType } from '@/lib/documentResolvers'

import { AppealSection } from './types'

export const SUPPORT_BOT_LINK = process.env.TELEGRAM_HELPER_LINK

export const FILE_MAX_COUNT = 5

export const SCREENSHOT_NAME = 'Скриншот страницы'

export const responsibleAppealTypes: AppealAlias[] = [
  ResponsibleAppealAlias.Act,
  ResponsibleAppealAlias.ActReq,
  ResponsibleAppealAlias.ConstructionDir,
  ResponsibleAppealAlias.Contract,
  ResponsibleAppealAlias.Estimate,
  ResponsibleAppealAlias.ProjectCor,
  ResponsibleAppealAlias.ProjectDir,
]

export const APPEAL_SECTIONS: AppealSection[] = [
  {
    alias: OtherAppealAlias.TechSupport,
    name: 'В техническую поддержку',
    shortDescription: 'По вопросам работы ЛКП',
    description: 'Обращение по вопросам технических проблем с личным кабинетом',
  },
  {
    alias: ResponsibleAppealAlias.ProjectCor,
    name: 'Координатору проекта',
    shortDescription: 'По вопросам согласующих',
    description:
      'Обращение по вопросам отсутствия согласующих и некорректно назначенных согласующих',
  },
  {
    alias: ResponsibleAppealAlias.ConstructionDir,
    name: 'Директору стройки',
    shortDescription: 'По вопросам объема работ и сроков строительства',
    description: 'Обращение по вопросам объема работ и сроков строительства по договору',
  },
  {
    alias: ResponsibleAppealAlias.Contract,
    name: 'Ответственному по договору',
    shortDescription: 'По общим вопросам текущих условий договора',
    description: 'Обращение по общим вопросам текущих условий договора',
  },
  {
    alias: ResponsibleAppealAlias.ProjectDir,
    name: 'Директору проекта',
    shortDescription: 'По вопросам финансирования работ по договору',
    description: 'Обращение по вопросам финансирования работ по договору',
  },
  {
    alias: ResponsibleAppealAlias.Act,
    name: 'Финансисту',
    shortDescription: 'По вопросам ГУ, зачета аванса и иных удержаний',
    description:
      'Обращение по вопросам размера гарантийных удержаний, зачета аванса и иных удержаний',
  },
  {
    alias: ResponsibleAppealAlias.ActReq,
    name: 'Бухгалтеру',
    shortDescription: 'По вопросам первичной документации и актов сверки',
    description: 'Обращение по вопросам первичной документации и актов сверки',
  },
  {
    alias: ResponsibleAppealAlias.Estimate,
    name: 'Сметчику',
    shortDescription: 'По общим вопросам текущих условий договора',
    description: 'Обращение по вопросам текущей подписанной сметы',
  },
  {
    alias: OtherAppealAlias.CreateUser,
    name: 'Добавить пользователя в ЛКП',
    shortDescription: 'Добавление нового пользователя в ЛКП',
    description: 'Добавление нового пользователя в ЛКП',
  },
  {
    alias: OtherAppealAlias.BlockUser,
    name: 'Отключить пользователя от ЛКП',
    shortDescription: 'Отключение пользователя от ЛКП',
    description: 'Отключение пользователя от ЛКП',
  },
  {
    alias: ResponsibleAppealAlias.Contragent,
    name: 'На подрядчика',
    shortDescription: '',
    description: '',
  },
]

export const DOCUMENT_TYPES = [
  DocumentAlias.ACT,
  DocumentAlias.PAYMENT,
  DocumentAlias.KOR_DOC,
  DocumentAlias.CONTRACT,
  DocumentAlias.ADDITIONAL_AGREEMENT,
  DocumentAlias.COMPENSATION_DOCUMENT,
].map((alias) => ({
  id: alias,
  label: getDocumentNameByType(alias),
}))


export const LKP_SECTIONS = [
  {
    id: LkpSection.ADDITIONAL_REQUESTS,
    label: 'Доп. запросы',
  },
  {
    id: LkpSection.FILE_OPERATIONS,
    label: 'Работа с файлами',
  },
  {
    id: LkpSection.COMPANY_INFO,
    label: 'Моя компания',
  },
  {
    id: LkpSection.USER_PROFILE,
    label: 'Профиль пользователя',
  },
  {
    id: LkpSection.AUTHORIZATION,
    label: 'Авторизация',
  },
  {
    id: LkpSection.SUPPORT,
    label: 'Помощь',
  },
  {
    id: LkpSection.MAIN_PAGE,
    label: 'Главная',
  },
  {
    id: LkpSection.DOCUMENT_FLOW,
    label: 'Документооборот',
  },
  {
    id: LkpSection.NOTIFICATIONS,
    label: 'Уведомления',
  },
  {
    id: LkpSection.GENERAL_SETTINGS,
    label: 'Общие настройки',
  },
]
