import React from 'react'
import styled from 'styled-components'

import { ApprovementProcessItem } from '@/dal'
import { noop } from '@/lib/noop'

import { SquareButton } from '../../buttons'
import { ApprovementProcess } from '../approvement-process'

type Props = {
  approvementProcess: ApprovementProcessItem[]
}

export const ApprovementProcessButton = ({ approvementProcess }: Props) => {
  return (
    <ButtonWrapper>
      <SquareButton
        icon="processLine"
        buttonType="grey"
        size="S"
        dataTestId="show-approvement-process"
        onClick={noop}
      />

      <ApprovementProcessWrapper>
        <ApprovementProcess items={approvementProcess} />
      </ApprovementProcessWrapper>
    </ButtonWrapper>
  )
}

const ApprovementProcessWrapper = styled.div`
  visibility: hidden;
  position: absolute;
  z-index: 20;
  padding-top: 5px;
  right: 0;
`

const ButtonWrapper = styled.div`
  &:hover ${ApprovementProcessWrapper} {
    visibility: visible;
  }
`
