import { AxiosResponse } from 'axios'
import { attachWrapper } from '@42px/effector-extra'

import { prepareFormData } from '@/lib/form-data'

import { Method, authRequestFx } from '../request'
import {
  AddUserParams,
  AdministrationAppealFilterItem,
  AppealComment,
  AdministrativeAppealItemType,
  AppealFiltersResponse,
  AppealItem,
  CompleteTaskPayload,
  SendDocumentProblemPayload,
  GetAdministrativeAppealInfoParams,
  GetAdministrativeAppealInfoResponse,
  GetAdministrativeRequestTypesResponse,
  GetAppealsParams,
  GetDocumentListParams,
  GetDocumentListResponse,
  GetJiraDocumentsByType,
  JiraDocument,
  MyUsersInfo,
  PostAppealFormParams,
  SendLkpSectionProblemPayload,
  CancelAppealPayload,
  SendMessagePayload,
  AppealAlias,
  AppealCommentV2,
  ResumeAppealPayload,
} from './types'
import { defaultErrorHandler } from '../error-handler'
import { AbortableRequest, DefaultResponseFail, Response } from '../interfaces'

export const getAdministrativeAppealInfoReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({
    signal,
    request_type,
    status,
    ...query
  }: AbortableRequest<GetAdministrativeAppealInfoParams>) => ({
    method: Method.get,
    url: '/responsible-tasks/tasks/',
    query: {
      ...query,
      status: status?.join(','),
      request_type: request_type?.join(','),
    },
    signal,
  }),
  mapResult: ({ result }: Response<GetAdministrativeAppealInfoResponse>) => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const getSupportAppealsReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({
    signal,
    issue_type,
    contragent_id,
    project_id,
    ...query
  }: AbortableRequest<GetAppealsParams>) => ({
    method: Method.get,
    url: '/support/issues/',
    signal,
    query: {
      ...query,
      issue_type: issue_type?.join(','),
      project_id: project_id?.join(','),
      contragent_id: contragent_id?.join(','),
    },
  }),
  mapResult: ({ result }): AppealItem[] => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const getSupportAppealFiltersReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ signal }: AbortableRequest) => ({
    method: Method.get,
    url: '/support/issues/filters/',
    signal,
  }),
  mapResult: ({ result }): AppealFiltersResponse => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const getAppealMessagesReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ signal, id, alias }: AbortableRequest<{ id: AppealItem['id'], alias: AppealAlias }>) => ({
    method: Method.get,
    url: `/support/issue/${alias}/${id}/`,
    signal,
  }),
  mapResult: ({ result }): AppealCommentV2[] => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const getJiraDocumentsReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ type, signal }: AbortableRequest<GetJiraDocumentsByType>) => ({
    method: Method.get,
    url: `/support/doc_by_type/${type}/`,
    signal,
  }),
  mapResult: ({ result }: {result: AxiosResponse<JiraDocument[]>}) => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const addUserReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (body: AddUserParams) => ({
    method: Method.post,
    url: '/support/user/new',
    body,
  }),
  mapResult: ({ result }: {result: AxiosResponse<AppealItem>}) => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const getMyUsersReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: () => ({
    method: Method.get,
    url: '/support/disable_user_ticket/list_users/',
  }),
  mapResult: ({ result }: {result: AxiosResponse<MyUsersInfo[]>}) => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const blockUserReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (id: MyUsersInfo['id']) => ({
    method: Method.patch,
    url: `/support/user/block/${id}/`,
  }),
  mapResult: ({ result }: {result: AxiosResponse<AppealItem>}) => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const getAdministrativeRequestTypesReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ signal }: AbortableRequest<void>) => ({
    method: Method.get,
    url: '/responsible-tasks/types/',
    signal,
  }),
  mapResult: ({ result }: Response<GetAdministrativeRequestTypesResponse>) => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const getAppealDocumentListReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ signal, task_type, ...query }: AbortableRequest<GetDocumentListParams>) => ({
    method: Method.get,
    url: `/support/responsible_tasks/get-documents/${task_type}/`,
    query,
    signal,
  }),
  mapResult: ({ result }: Response<GetDocumentListResponse>) => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const getAdministrationCommentsReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, signal }: AbortableRequest<{ id: AdministrativeAppealItemType['id'] }>) => ({
    method: Method.get,
    url: `/support/responsible_tasks/details/${id}/`,
    signal,
  }),
  mapResult: ({ result }: Response<AppealComment[]>) => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const postAdministrativeAppealReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ signal, ...params }: AbortableRequest<PostAppealFormParams>) => ({
    method: Method.post,
    url: '/support/responsible_tasks/create/',
    body: prepareFormData(params),
    signal,
  }),
  mapResult: ({ result }): AppealItem => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const postDocumentProblemReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (body: SendDocumentProblemPayload) => ({
    method: Method.post,
    url: '/support/request/create/',
    body: prepareFormData(body, 'files'),
  }),
  mapResult: ({ result }): AppealItem => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const postLkpSectionProblemAppealReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (body: SendLkpSectionProblemPayload) => ({
    method: Method.post,
    url: '/support/request/create/',
    body: prepareFormData(body, 'files'),
  }),
  mapResult: ({ result }): AppealItem => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const putMarkAppealAsReadReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ signal, id }: AbortableRequest<{ id: number }>) => ({
    method: Method.put,
    url: `/support/responsible_tasks/mark_as_read/${id}/`,
    signal,
  }),
  mapResult: ({ result }: Response<AdministrativeAppealItemType>) => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const getAdministrationAppealsFiltersReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ signal }: AbortableRequest) => ({
    method: Method.get,
    url: '/responsible-tasks/tasks/filters/',
    signal,
  }),
  mapResult: ({ result }: Response<AdministrationAppealFilterItem[]>) => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const getAdministrationAppealsCountReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ signal, status }: AbortableRequest<{ status?: string[] }>) => ({
    method: Method.get,
    url: '/support/responsible_tasks/tasks/count/',
    signal,
    query: {
      status: status?.join(','),
    },
  }),
  mapResult: ({ result }: Response<{ count: number }>) => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const sendAppealChatMessageReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, ...body }: SendMessagePayload) => ({
    method: Method.post,
    url: `/support/request/${id}/add-comment/`,
    body: prepareFormData(body, 'files'),
  }),
  mapResult: ({ result }): AppealCommentV2 => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const completeTaskReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, ...body }: CompleteTaskPayload) => ({
    method: Method.put,
    url: `/support/responsible_tasks/complete_task/${id}/`,
    body: prepareFormData(body),
  }),
  mapResult: ({ result }: Response<AdministrativeAppealItemType>) => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const cancelSupportAppealReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, appealType, reason }: CancelAppealPayload) => ({
    method: Method.patch,
    url: `/support/request/${appealType}/${id}/update-status/`,
    body: {
      reason,
      action: 'cancel',
    },
  }),
  mapResult: ({ result }): AppealItem => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const resumeSupportAppealReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, appealType }: ResumeAppealPayload) => ({
    method: Method.patch,
    url: `/support/request/${appealType}/${id}/update-status/`,
    body: {
      action: 'resume',
    },
  }),
  mapResult: ({ result }): AppealItem => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const initBotSupportApplicationReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: () => ({
    method: Method.get,
    url: `/telegram_helper/continue_create_support_application/`,
  }),
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})
