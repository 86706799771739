import React from 'react'
import styled from 'styled-components'

import { TableHeaderCell, TableHeaderCellProps } from '@/ui'
import { CELL_BORDER_COLOR } from '@/features/act/view'

import { GaGridWrapper } from './GaGridWrapper'

const HeaderCell = (props: TableHeaderCellProps) => (
  <TableHeaderCell textAlign="center" {...props} />
)

export const GaTableHeader = () => {
  return (
    <Wrapper>
      <GaGridWrapper as="th">
        <HeaderCell label="№" />
        <HeaderCell label="Наименование групп актирования/работ" textAlign="left" />
        <HeaderCell label="Ед. изм" />
        <HeaderCell label="Кол-во" />
        <HeaderCell label="Договорная цена" />

        <TripleSplitCell>
          <SplitCellRow>
            <HeaderCell label="Выполнено работ" />
          </SplitCellRow>

          <SplitCellRow>
            <HeaderCell label="С начала строительства" />
            <HeaderCell label="За отчетный период" />
          </SplitCellRow>

          <SplitCellRow>
            <HeaderCell label="Кол-во" />
            <HeaderCell label="Стоимость" />
            <HeaderCell label="Кол-во" />
            <HeaderCell label="Стоимость" />
          </SplitCellRow>
        </TripleSplitCell>
      </GaGridWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.tr`
  display: block;
`

const TripleSplitCell = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: span 4;

  & > * {
    height: 33.3%;
  }

  & > *:not(:last-child) {
    border-bottom: 1px solid ${CELL_BORDER_COLOR};
  }
`

const SplitCellRow = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
`
