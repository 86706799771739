import React from 'react'
import { useUnit } from 'effector-react'
import styled from 'styled-components'

import {
  HeadM,
  Icon2,
  PaginationItem,
  palette,
} from '@/ui'

import {
  $articleTotal,
  $articlesList,
  $filters,
  $hasMore,
  $itemsToLoad,
  getArticlesFx,
  getMoreArticlesFx,
  onLoadMoreArticles,
  openArticle,
} from '../../model/private'
import { AllArticlesSkeleton, WideArticleCard } from '../parts'

export const AllArticlesList = React.memo(() => {
  const articles = useUnit($articlesList)
  const articleTotal = useUnit($articleTotal)
  const filters = useUnit($filters)

  const hasMore = useUnit($hasMore)
  const isLoading = useUnit(getArticlesFx.pending)
  const isNextPageLoading = useUnit(getMoreArticlesFx.pending)
  const itemsToLoad = useUnit($itemsToLoad)

  if (isLoading) {
    return (
      <ArticlesContainer>
        <AllArticlesSkeleton count={3} />
      </ArticlesContainer>
    )
  }

  if (!articleTotal) {
    return (
      <EmptyNews>
        <Icon2 icon="emptyTasks" size={170} />
        <EmptyNewsText>
          {filters.search ? 'Новости не найдены' : 'У вас нет новостей'}
        </EmptyNewsText>
      </EmptyNews>
    )
  }

  return (
    <ArticlesContainer>
      {articles?.map(({
        title,
        description,
        created_at,
        publication_type,
        id,
        is_read,
        comments_count,
      }) => (
        <WideArticleCard
          key={id}
          id={id}
          onCardClick={openArticle}
          date={created_at}
          description={description}
          title={title}
          type={publication_type}
          isRead={is_read}
          commentsCount={comments_count}
          searchValue={filters.search ?? ''}
        />
      ))}

      {hasMore && (
        <PaginationItem
          onLoadMore={onLoadMoreArticles}
          isLoading={isNextPageLoading}
        >
          <LoadingArticlesContainer>
            <AllArticlesSkeleton count={itemsToLoad || 1} />
          </LoadingArticlesContainer>
        </PaginationItem>
      )}
    </ArticlesContainer>
  )
})

const EmptyNews = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  height: 100%;
`

const EmptyNewsText = styled.p`
  ${HeadM}
  text-align: center;
  color: ${palette.grey60};
`

const ArticlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const LoadingArticlesContainer = styled(ArticlesContainer)`
  width: 100%;
`
