import styled from 'styled-components'

import { palette } from '@/ui'

export const FirstCellWrapper = styled.div`
  position: sticky;
  left: 0;
  z-index: 2;
  width: 124px;
  min-width: 124px;
  border-left: 1px solid ${palette.grey50};
`
