import { sample } from 'effector'

import {
  ActDTO,
  DocumentAlias,
} from '@/dal'
import { copyDocument, deleteDocument, onActDeleted } from '@/features/document-manager/model'
import { RouterPaths, pushNavigate, replaceNavigate } from '@/features/app/model'
import { createToast } from '@/features/toast-service/model'

import {
  onDeleteButton,
  cancelActApproveFx,
  sendActToApproveFx,
  $isCancelApprovingModalOpen,
  openCancelApprovingModal,
  closeCancelApprovingModal,
  onSendToApprove,
  clearApproveTimeoutFx,
  setApproveTimeoutFx,
  onTimeLeft,
  $approveTimeoutId,
  cancelApprovingForm,
  onCopy,
  successfulCancelModal,
} from './header.private'
import {
  $allDocumentInfo,
  $documentId,
  $documentInfo,
  $isGaAct,
  ActGate,
} from './public'
import { getActInfoFx, updateDocumentInfo } from './private'
import { ActDocInfo } from './types'
import { onActError } from '../act-error-modal'

$approveTimeoutId
  .on(setApproveTimeoutFx.doneData, (_, id) => id)
  .reset([clearApproveTimeoutFx.doneData, onTimeLeft, ActGate.close])

$isCancelApprovingModalOpen
  .on(openCancelApprovingModal, () => true)
  .reset(closeCancelApprovingModal, ActGate.close)

sample({
  clock: onActDeleted,
  source: {
    docId: $documentId,
    gateStatus: ActGate.status,
  },
  filter: ({ docId, gateStatus }, { id }) => (gateStatus && docId === id),
  fn: () => RouterPaths.Administrative,
  target: replaceNavigate,
})

sample({
  clock: onDeleteButton,
  source: $allDocumentInfo,
  filter: (
    info: ActDTO | null,
  ): info is ActDTO => Boolean(info && info.features.can_delete),
  fn: (info) => ({
    id: info.id,
    type: DocumentAlias.ACT as const,
    documentName: info.name,
  }),
  target: deleteDocument,
})

sample({
  clock: onSendToApprove,
  source: $documentInfo,
  filter: Boolean,
  fn: (info) => ({ id: info.id }),
  target: sendActToApproveFx,
})

sample({
  clock: sendActToApproveFx.doneData,
  target: updateDocumentInfo,
})

sample({
  clock: cancelApprovingForm.formValidated,
  source: $documentId,
  filter: Boolean,
  fn: (id, { comment }) => ({ id, comment }),
  target: cancelActApproveFx,
})

sample({
  clock: cancelActApproveFx.doneData,
  target: [updateDocumentInfo, closeCancelApprovingModal],
})

sample({
  clock: cancelActApproveFx.done,
  source: {
    info: $documentInfo,
    isGa: $isGaAct,
  },
  filter: ({ info, isGa }) => Boolean(info && isGa),
  fn: ({ info }) => info!.contract.id,
  target: [
    successfulCancelModal.open,
    pushNavigate.prepend((contractId: number) => `${RouterPaths.Administrative}/${contractId}`),
  ],
})

sample({
  clock: $documentInfo,
  source: $approveTimeoutId,
  filter: (timeoutId) => Boolean(timeoutId),
  fn: (timeoutId) => timeoutId as NodeJS.Timeout,
  target: clearApproveTimeoutFx,
})

sample({
  clock: onTimeLeft,
  source: $documentId,
  filter: Boolean,
  fn: (actId) => ({ actId }),
  target: getActInfoFx,
})

sample({
  clock: $documentInfo.updates,
  filter: (info): info is ActDocInfo => Boolean(info?.reject_time),
  fn: ({ reject_time }: ActDocInfo) => ({
    endTime: reject_time as string,
  }),
  target: setApproveTimeoutFx,
})

sample({
  clock: onCopy,
  source: $documentInfo,
  filter: Boolean,
  fn: (info) => ({
    id: info.id,
    type: info.type,
    documentName: info.name,
  }),
  target: copyDocument,
})

setApproveTimeoutFx.use(({ endTime }) => {
  // с бэка приходит время по МСК без UTC offset, считаем разницу с локальным временем в ms
  const offset = (new Date().getTimezoneOffset() + 180) * 60 * 1000
  const diff = +new Date(endTime) - +new Date() - offset + 1000

  if (diff < 0) throw Error(`invalid value ms=${diff}`)

  return setTimeout(() => {
    onTimeLeft()
  }, diff)
})

clearApproveTimeoutFx.use((timeoutId) => {
  clearTimeout(timeoutId)
})

sample({
  clock: sendActToApproveFx.failData,
  target: onActError,
})

createToast({
  effect: sendActToApproveFx,
  doneText: 'Отправлено на согласование',
})
