import React from 'react'
import styled from 'styled-components'

import { SimpleLoader } from '@/ui'

export const RowLoader = () => {
  return (
    <Wrapper>
      <SimpleLoader sizePx={40} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  grid-column-start: 1;
  grid-column-end: -1;
  padding: 24px 0;
`
