import React from 'react'
import { StyleSheetManager } from 'styled-components'
import * as Sentry from '@sentry/react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from 'history'
// eslint-disable-next-line import/no-extraneous-dependencies
import isPropValid from '@emotion/is-prop-valid'

import { Routes } from './Routes'

import './init'
import { appInit } from './features/app/model'

// Themes and styles
import 'react-day-picker/dist/style.css'
import GlobalCSS from './ui/theming/main.css'
import './ui/theming/global.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'swiper/css'
import 'react-loading-skeleton/dist/skeleton.css'

import { ThemeProvider } from './ui'
import { ToastList } from './features/toast-service/view'
import { ScreenCapture } from './features/screen-capture'

const history = createBrowserHistory()

const isDevelopment = process.env.NODE_ENV === 'development'

if (!isDevelopment) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV5BrowserTracingIntegration({
        history,
      }),
    ],
    tracesSampleRate: 1.0,
  })
}

export const App = Sentry.withProfiler(() => {
  React.useEffect(() => {
    appInit()
  }, [])

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp} enableVendorPrefixes={!isDevelopment}>
      <ThemeProvider>
        <GlobalCSS />
        <ScreenCapture />
        <ToastList />
        <Routes />
      </ThemeProvider>
    </StyleSheetManager>
  )
})

// This implements the default behavior from styled-components v5
function shouldForwardProp(propName: string, target: unknown) {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName)
  }
  // For other elements, forward all props
  return true
}
