import { attachWrapper } from '@42px/effector-extra'

import { defaultErrorHandler } from '../error-handler'
import { DefaultResponseFail, Response } from '../interfaces'
import { Method, authRequestFx } from '../request'
import { SendSurveyResultParams, SurveyInfo } from './types'

export const getActualSurveyReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: () => ({
    method: Method.get,
    url: '/quiz/get-actual-quizes/',
  }),
  mapResult: ({ result }: Response<SurveyInfo>) => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const sendSurveyResultReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, ...body }: SendSurveyResultParams & { id: number }) => ({
    method: Method.post,
    url: `/quiz/quiz/${id}/push-result/`,
    body,
  }),
  mapResult: ({ result }: Response) => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})
