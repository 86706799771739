import { attach, combine } from 'effector'
import { createGate } from 'effector-react'

import { createSingleEffect } from '@/lib/createSingleEffect'
import { createPagination } from '@/features/shared/createPagination'

import {
  ActTableRow,
  GetActTableParams,
  ActWorksExtraFields,
  fillByRemainingReqFx,
  getActWorkFolderContentReqFx,
  getActFlatTableReqFx,
  getActTreeTableReqFx,
  putActWorkVolumeReqFx,
  uploadActTableTemplateReqFx,
  ActTableFolder,
  getGaActingGroupsReqFx,
  getGaActTableItemContentReqFx,
  resetActTableReqFx,
  getGaActTableReqFx,
  ActId,
} from '@/dal'
import { createUpdateTreeItemsFx } from '@/lib/tree'
import { getFlatTree } from '@/lib/tree/getFlatTree'
import { d } from './domain'
import { OpenTableItemPayload, TreeType } from './types'

export const ActTableGate = createGate<{ hasMorphology: boolean }>('ActTableGate')

export const $hasMorphology = ActTableGate.state.map(({ hasMorphology }) => Boolean(hasMorphology))

export const $isTree = d.store(false)
export const toggleIsTree = d.event<void>()

export const getTableData = d.event<void>()

export const $tableRoot = d.store<ActTableRow[] | null>(null)
export const $tableTree = d.store<TreeType>({})

export const setRootUpdate = d.event<ActTableRow[] | null>()
export const setMapUpdate = d.event<TreeType>()
export const closeFolders = d.event<TreeType>()

export const $tableInfo = d.store<ActWorksExtraFields | null>(null)

export const $actTotalWorks = d.store<number | null>(null)
export const setActTotalWorks = d.event<number>()

export const templateSelected = d.event<File>()

export const $flatItems = combine(
  {
    root: $tableRoot,
    tree: $tableTree,
    isTree: combine(
      [$isTree, $hasMorphology],
      ([isTree, hasMorphology]) => isTree || hasMorphology,
    ),
  },
  getFlatTree<ActTableRow>,
)

export const $itemToFocus = d.store<ActTableRow['id'] | null>(null)
export const setItemToFocus = d.event<ActTableRow['id'] | null>()
export const resetItemToFocus = d.event()
export const onEnterSubmitItem = d.event<ActTableRow['id']>()

export const $isCellGroupHidden = d.store<boolean>(true)
export const toggleHiddenGroup = d.event<void>()

export const onWorkInputError = d.event<void>()
export const onChangeSMRActWork = d.event<{ workId: ActTableRow['id']; value: string }>()
export const onChangePIRActWork = d.event<{ workId: ActTableRow['id']; value: boolean }>()

export const onDownloadTemplate = d.event<File[]>()

export const onFillByRemaining = d.event<boolean>()

export const openTableItem = d.event<OpenTableItemPayload>()
export const closeTableItem = d.event<ActTableFolder['id']>()

export const sendActWorkVolumeFx = attach({
  effect: putActWorkVolumeReqFx,
})

export const fillByRemainingFx = attach({
  effect: fillByRemainingReqFx,
})

export const $pendingFolderIds = d.store<ActTableFolder['id'][]>([])

export const onLoadMore = d.event<void>()
export const { $hasMore, initEffect, paginationEffect } = createPagination({
  fetchEffect: getActFlatTableReqFx,
  limit: 100,
  domain: d,
})

export const getGaActTable = d.event<ActId>()
export const getActTable = d.event<ActId>()

export const getTreeTable = d.event<GetActTableParams>()
export const initFlatTable = d.event<GetActTableParams>()

export const resetActTable = d.event()

export const getActWorkFolderContent = d.event<OpenTableItemPayload>()
export const getGaActTableItemContent = d.event<OpenTableItemPayload>()

export const uploadTemplateFx = attach({
  effect: uploadActTableTemplateReqFx,
})

export const { requestFx: initFlatTableFx } = createSingleEffect(initEffect, {
  abortEvents: [ActTableGate.close],
})

export const { requestFx: paginateFlatTableFx } = createSingleEffect(paginationEffect, {
  abortEvents: [ActTableGate.close],
})

export const { requestFx: getActTreeTableFx } = createSingleEffect(getActTreeTableReqFx, {
  abortEvents: [ActTableGate.close],
})

export const { requestFx: getGaActingGroupsFx } = createSingleEffect(getGaActingGroupsReqFx, {
  abortEvents: [ActTableGate.close],
})

export const { requestFx: getGaActTableFx } = createSingleEffect(getGaActTableReqFx, {
  abortEvents: [ActTableGate.close],
})

export const getActWorkFolderContentFx = attach({
  effect: getActWorkFolderContentReqFx,
})

export const getGaActTableItemContentFx = attach({
  effect: getGaActTableItemContentReqFx,
})

export const resetActTableFx = attach({ effect: resetActTableReqFx })

export const updateItemsFx = createUpdateTreeItemsFx<ActTableRow>({ hasOnlyTargetInFolder: true })

export const $isTableLoading = combine(
  fillByRemainingFx.pending,
  initFlatTableFx.pending,
  getActTreeTableFx.pending,
  getGaActingGroupsFx.pending,
  paginateFlatTableFx.pending,
  sendActWorkVolumeFx.pending,
  updateItemsFx.pending,
  uploadActTableTemplateReqFx.pending,
  (...args) => args.some((val) => val),
)

export const $isFillByRemainingPending = combine(
  $isTableLoading,
  getActWorkFolderContentFx.pending,
  (...args) => args.some((val) => val),
)
