import { sample } from 'effector'

// @TODO возможно нужно будет убрать. Или вынести ошибки отдельно.
import { onActError } from '@/features/act'

import {
  CreateActGate,
  createActForm,
  createActFx,
  getStartDateActFx,
  onConfirmCreatePIRAct,
} from './create-act.private'
import { $createDocumentInfo, closeCreateDocumentModal } from './private'
import { onCreatedDocument } from './public'

sample({
  clock: CreateActGate.open,
  source: $createDocumentInfo,
  filter: Boolean,
  fn: ({ contractId }) => ({ id: contractId }),
  target: getStartDateActFx,
})

sample({
  clock: getStartDateActFx.doneData,
  target: createActForm.set,
})

sample({
  clock: createActForm.formValidated,
  source: $createDocumentInfo,
  filter: Boolean,
  fn: ({ contractId }, fields) => ({
    contractId,
    reporting_period: fields,
  }),
  target: createActFx,
})

sample({
  clock: onConfirmCreatePIRAct,
  source: $createDocumentInfo,
  filter: Boolean,
  fn: ({ contractId }) => ({ contractId }),
  target: createActFx,
})

sample({
  clock: createActFx.failData,
  target: [onActError, closeCreateDocumentModal],
})

sample({
  clock: createActFx.doneData,
  target: onCreatedDocument,
})

sample({
  clock: CreateActGate.close,
  target: createActForm.reset,
})
