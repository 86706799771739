import React, { memo } from 'react'
import styled, { css } from 'styled-components'
import { useUnit } from 'effector-react'

import {
  DefaultButton, HeadL, Icon2, NewTextButton, palette, TextMLight,
} from '@/ui'

import { $isCatalogOpen } from '../../model/private'
import { openCatalog } from '../../model'
import { CatalogHeader, CatalogList } from '../containers'

export const KvrKerCatalog = memo(() => {
  const isOpen = useUnit($isCatalogOpen)

  return (
    <Wrapper>
      <ButtonWrapper isVisible={!isOpen} onClick={() => openCatalog()}>
        <Icon2 icon="burger" size={20} color="grey90" />
        <TextWrapper>
          <Text>
            Справочник работ
          </Text>
        </TextWrapper>
      </ButtonWrapper>

      {isOpen && (
        <CatalogWrapper>
          <CatalogHeader />
          <CatalogList />
          <CatalogFooter>
            Если вы не нашли нужную работу в справочнике — направьте
            {' '}
            <NewTextButton
              url="mailto:mock@a.a"
              label="запрос на добавление"
              textStyle="ML"
              dataTestId=""
              isInline
              hasUnderline
            />
            .
          </CatalogFooter>
        </CatalogWrapper>
      )}
    </Wrapper>
  )
})

const Wrapper = styled.div`
  position: relative;
`

const ButtonWrapper = styled(DefaultButton)<{ isVisible: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  width: 70px;
  height: 100%;
  padding: 24px;
  background-color: ${palette.white};
  border-radius: 12px;

  ${({ isVisible }) => !isVisible && css`
    visibility: hidden;
  `}
`

const TextWrapper = styled.div`
  position: relative;
  transform: rotate(-90deg);
`

const Text = styled.p`
  ${HeadL}
  position: absolute;
  top: -12px;
  right: 0;
  width: max-content;
  height: 24px;
  user-select: none;
`

const CatalogWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;

  display: flex;
  flex-direction: column;

  width: 920px;
  height: 100%;
  max-width: calc(100dvw - 64px);
  background-color: ${palette.white};
  border-radius: 12px;
  box-shadow: 0px 2px 10px 0px #ABABAB40;
`

const CatalogFooter = styled.div`
  ${TextMLight}
  padding: 24px;
`
