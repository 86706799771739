import React from 'react'
import { useStoreMap, useUnit } from 'effector-react'

import {
  ActRowType,
  ActTableFolder as ActTableFolderType,
  isPIRTableFolder,
} from '@/dal'
import {
  CellWrapper,
  FirstTableCell,
} from '@/ui'
import { numberWithSpaces } from '@/lib/number-represent'

import {
  $isCellGroupHidden,
  $isTree,
  $pendingFolderIds,
  $tableTree,
  closeTableItem,
  openTableItem,
} from '../../model/private'
import { $actType } from '../../../model'
import { $worksTableSearch } from '../../model/filters.private'
import {
  CellRow, FirstCellWrapper, RowLoader, SplitCellWrapper, TableRowWrapper,
} from '../parts'

type Props = {
  item: ActTableFolderType
  isScrolled?: boolean
}

export const ActTableFolder = React.memo(({ item, isScrolled }: Props) => {
  const [isHiddenGroup, isTree, actType, searchName]  = useUnit([
    $isCellGroupHidden,
    $isTree,
    $actType,
    $worksTableSearch,
  ])

  const {
    id,
    is_folder,
    article,
    name,
    note,
    number_1c,
    depth,
    has_children,
    morphology_id: morphologyId,
    morphology_type: morphologyType,
  } = item

  const isLoading = useStoreMap({
    store: $pendingFolderIds,
    keys: [id, is_folder],
    fn: (arr, [id, isFolder]) => isFolder && arr.includes(id),
  })

  const isExpanded = useStoreMap({
    store: $tableTree,
    keys: [id],
    fn: (map, [rowId]) => Boolean(map[rowId]),
  })

  if (!actType) return null

  const isPIRFolder = isPIRTableFolder(item)

  const handleToggleExpand = () => (
    isExpanded
      ? closeTableItem(id)
      : openTableItem({ id, morphologyId, morphologyType, type: ActRowType.Folder })
  )

  return (
    <>
      <TableRowWrapper
        actType={actType}
        hiddenFirstGroup={isHiddenGroup}
        as="td"
      >
        <FirstCellWrapper hasShadow={isScrolled}>
          <FirstTableCell
            hierarchyCode={number_1c}
            depth={depth}
            hasChildren={has_children}
            isFolder={is_folder}
            isExpanded={isExpanded}
            isTree={isTree}
            name={name}
            project=""
            hasRightDivider={isHiddenGroup}
            searchValue={searchName}
            onToggleExpand={handleToggleExpand}
          />
        </FirstCellWrapper>

        {!isHiddenGroup && (
          <>
            <CellWrapper isGray>{note}</CellWrapper>
            <CellWrapper isGray>{article}</CellWrapper>
          </>
        )}

        <CellWrapper />
        <CellWrapper />
        <CellWrapper />
        <CellWrapper />

        {isPIRFolder ? (
          <>
            <CellWrapper />
            <CellWrapper />
            <CellWrapper />
            <CellWrapper align="right">{numberWithSpaces(item.total_cost)}</CellWrapper>
            <CellWrapper align="right">{numberWithSpaces(item.period_cost)}</CellWrapper>
            
          </>
        ) : (
          <>
            <SplitCellWrapper>
              <CellWrapper />
              <CellRow>
                <CellWrapper />
                <CellWrapper />
              </CellRow>
            </SplitCellWrapper>

            <SplitCellWrapper>
              <CellWrapper align="center">{numberWithSpaces(item.period_cost.total)}</CellWrapper>
              
              <CellRow>
                <CellWrapper align="center">{numberWithSpaces(item.period_cost.material)}</CellWrapper>
                <CellWrapper align="center">{numberWithSpaces(item.period_cost.work)}</CellWrapper>
              </CellRow>
            </SplitCellWrapper>
          </>
        )}
      </TableRowWrapper>
    
      {isLoading && (
        <TableRowWrapper
          actType={actType}
          hiddenFirstGroup={isHiddenGroup}
          as="td"
        >
          <RowLoader />
        </TableRowWrapper>
      )}
    </>
  )
})
