import { sample, split } from 'effector'

import {
  ActCompensationsError,
  DocumentAlias,
  ErrorAsIssueError,
  TextError,
  isActCompensationsError,
  isApproveError,
  isCreateActConflictError,
  isCreateActReqieredFieldError,
  isErrorAsIssueError,
  isTextError,
} from '@/dal'
import { RouterPaths } from '@/features/app/model'
import { onErrorAsIssueOccured } from '@/features/support/error-modal/model'
import { getDocumentNameByType } from '@/lib/documentResolvers'

import {
  $actCompensationsErrorData,
  $actModalError,
  onActDefaultError,
  onAproveActError,
  onCreateActReqieredFieldError,
  onCreateConflictError,
  setActCompensationsErrorData,
  setActError,
} from './private'
import { clearActError, onActError } from './public'
import { checkDocumentBlocking } from '../../model'

$actModalError
  .on(setActError, (_, error) => error)
  .reset(clearActError)

$actCompensationsErrorData
  .on(setActCompensationsErrorData, (_, error) => error)
  .reset(clearActError)

sample({
  clock: onActDefaultError,
  filter: (err) => err instanceof TextError,
  fn: ({ data }: TextError) => ({
    title: data.detail,
  }),
  target: setActError,
})

sample({
  clock: onActDefaultError,
  filter: (err) => err instanceof ErrorAsIssueError,
  target: onErrorAsIssueOccured,
})

// есть ощущение, что не очень хороший вариант; лучше реализация через SSE
sample({
  clock: onActDefaultError,
  target: checkDocumentBlocking,
})

sample({
  clock: onAproveActError,
  fn: ({ data }) => ({
    link: [{
      href: `${RouterPaths.ActPage}/${data.id}`,
      text: `${getDocumentNameByType(DocumentAlias.ACT)} ${data.number}`,
    }],
    title: 'Отправка на согласование невозможна',
    body: 'Запуск документа на согласование не возможен. Дождитесь результата согласования',
  }),
  target: setActError,
})

sample({
  clock: onCreateConflictError,
  fn: ({ data }) => ({
    link: [{
      href: `${RouterPaths.ActPage}/${data.id}`,
      text: `${getDocumentNameByType(DocumentAlias.ACT)} ${data.number}`,
    }],
    body: data.detail,
  }),
  target: setActError,
})

sample({
  clock: onCreateActReqieredFieldError,
  filter: Boolean,
  fn: ({ data }) => ({
    link: [{
      href: `${RouterPaths.MyCompany}?id=${data.detail.contragent_id}`,
      text: 'Моя компания',
    }, {
      // TODO SUPPORT `${RouterPaths.CreateSupportAppeal}/${SupportAppealTypeAlias.LKP_ERRORS}`,
      href: RouterPaths.Support,
      text: 'техническую поддержку',
    }],
    body: data.detail.message,
    title: 'Создать выполнение невозможно',
  }),
  target: setActError,
})

const cases = {
  onAproveActError,
  onActDefaultError,
  onCreateConflictError,
  onCreateActReqieredFieldError,
  onActCompensationsError: setActCompensationsErrorData.prepend(
    ({ data }: ActCompensationsError) => data.detail,
  ),
  __: setActError.prepend(() => ({
    body: 'Обратитесь в тех.поддержку',
    title: 'Неизвестная ошибка',
  })),
}

split({
  source: onActError,
  match: {
    onAproveActError: isApproveError,
    onCreateConflictError: isCreateActConflictError,
    onCreateActReqieredFieldError: isCreateActReqieredFieldError,
    onActCompensationsError: isActCompensationsError,
    onActDefaultError: (err) => isTextError(err) || isErrorAsIssueError(err),
  },
  cases,
})
