import React from 'react'
import styled, { css } from 'styled-components'

import { useTooltip } from '@/lib/hooks'
import { subsystemIcons } from './assets/subsystems'
import { serviceIcons } from './assets/services'
import { fileIcons } from './assets/files'
import { notificationIcons } from './assets/notifications'
import { contractsIcon } from './assets/contracts'

import ArrowRight from './assets/arrow-right.svg'
import ArrowRightSmall from './assets/arrow-right-sm.svg'
import ArrowRightMedium from './assets/arrow-right-md.svg'
import ArrowUp from './assets/arrow-up.svg'
import ArrowDown from './assets/arrow-down.svg'
import ArrowLeft from './assets/arrow-left.svg'
import ArrowLeftMedium from './assets/arrow-left-md.svg'
import LinkIcon from './assets/link-icon.svg'
import Logout from './assets/logout.svg'
import Cross from './assets/cross.svg'
import CrossM from './assets/cross-md.svg'
import Search from './assets/search.svg'
import Bell from './assets/bell.svg'
import ListChecks from './assets/list-checks.svg'
import Burger from './assets/burger.svg'
import Lock from './assets/lock.svg'
import Message from './assets/message.svg'
import User from './assets/user.svg'
import Building from './assets/building.svg'
import Tree from './assets/tree.svg'
import Folder from './assets/folder.svg'
import ActiveFolder from './assets/active-folder.svg'
import PlusWithCircle from './assets/plus-with-cirlce.svg'
import MinusWithCircle from './assets/minus-with-circle.svg'
import VerticalDots from './assets/vertical-dots.svg'

import LikeIcon from './assets/like-icon.svg'
import DislikeIcon from './assets/dislike-icon.svg'
import OpenEye from './assets/open-eye.svg'
import Eye from './assets/eye.svg'
import ShareArrow from './assets/share-arrow.svg'
import GraphPeriodIcon from './assets/graph-period-icon.svg'
import EmptyChartIcon from './assets/empty-chart-icon.svg'
import LightningIcon from './assets/lightning.svg'
import AgreedContractorIcon from './assets/agrred-contractor.svg'
import VisaTask from './assets/visa-task.svg'
import SignInDiadoc from './assets/sign-in-diadoc.svg'
import EmptyTasks from './assets/empty-tasks.svg'
import Phone from './assets/phone.svg'
import Filter from './assets/filter.svg'

import PlusButton from './assets/plus-button-icon.svg'
import Minimize from './assets/minimize.svg'
import Edit from './assets/edit.svg'
import EditStroke from './assets/edit-stroke.svg'
import Delete from './assets/delete.svg'
import Refresh from './assets/refresh.svg'
import Calendar from './assets/calendar.svg'
import Attach from './assets/attach.svg'
import SendIcon from './assets/send-icon.svg'
import AddFile from './assets/add-file.svg'

import Download from './assets/download.svg'
import GrandTenderIcon from './assets/grand-tender-icon.svg'
import AddFolder from './assets/add-folder.svg'
import SplitVolume from './assets/split-volume.svg'

import Copy from './assets/copy.svg'

import Check from './assets/check.svg'
import CheckboxMarkIcon from './assets/checkbox-mark.svg'
import Star from './assets/star.svg'
import Document from './assets/document.svg'
import DownloadTemplate from './assets/download-template.svg'
import UploadTemplate from './assets/upload-template.svg'
import InfoCircle from './assets/info-circle.svg'
import Builder from './assets/builder.svg'
import BuilderCircle from './assets/builder-circle.svg'
import FSK from './assets/fsk.svg'
import SuccessIcon from './assets/success-icon.svg'
import UserInfo from './assets/user-info.svg'
import Messages from './assets/messages.svg'
import StatusCircle from './assets/status-circle.svg'
import Clock from './assets/clock.svg'
import ProcessLine from './assets/process-line.svg'
import Mail from './assets/mail.svg'
import MailWithDot from './assets/mail-with-dot.svg'
import CopyDoc from './assets/copy-doc.svg'

import LoaderIcon from './assets/loader-icon.svg'
import Eraser from './assets/eraser.svg'
import Stars from './assets/stars.svg'
import Scissors from './assets/scissors.svg'
import AutomaticCircle from './assets/automatic-circle.svg'
import GroupExpand from './assets/group-expand.svg'
import GroupCollapse from './assets/group-collapse.svg'
import ActGroup from './assets/act-group.svg'

import { PaletteColor, getColor, palette } from '../palette'
import { ToolTipV2 } from '../components/tooltip/ToolTipV2'
import { TextSLight } from '../typography'

const Icons = {
  ...subsystemIcons,
  ...serviceIcons,
  ...fileIcons,
  ...notificationIcons,
  ...contractsIcon,

  linkIcon: LinkIcon,
  logout: Logout,
  cross: Cross,
  crossM: CrossM,
  search: Search,
  arrowUp: ArrowUp,
  arrowDown: ArrowDown,
  arrowLeft: ArrowLeft,
  arrowLeftM: ArrowLeftMedium,
  arrowRight: ArrowRight,
  arrowRightS: ArrowRightSmall,
  arrowRightM: ArrowRightMedium,
  bell: Bell,
  listChecks: ListChecks,
  burger: Burger,
  lock: Lock,
  message: Message,
  user: User,
  building: Building,
  likeIcon: LikeIcon,
  dislikeIcon: DislikeIcon,
  openEye: OpenEye,
  shareArrow: ShareArrow,
  graphPeriodIcon: GraphPeriodIcon,
  emptyChartIcon: EmptyChartIcon,
  lightning: LightningIcon,
  agreedContractor: AgreedContractorIcon,
  visaTask: VisaTask,
  signInDiadoc: SignInDiadoc,
  emptyTasks: EmptyTasks,
  phone: Phone,
  plusButton: PlusButton,
  minimize: Minimize,
  edit: Edit,
  editStroke: EditStroke,
  delete: Delete,
  check: Check,
  checkboxMark: CheckboxMarkIcon,
  refresh: Refresh,
  calendar: Calendar,
  attach: Attach,
  sendIcon: SendIcon,
  addFile: AddFile,
  download: Download,
  tree: Tree,
  folder: Folder,
  activeFolder: ActiveFolder,
  plusWithCircle: PlusWithCircle,
  minusWithCircle: MinusWithCircle,
  filter: Filter,
  verticalDots: VerticalDots,
  grandTender: GrandTenderIcon,
  addFolder: AddFolder,
  splitVolume: SplitVolume,
  copy: Copy,
  star: Star,
  document: Document,
  downloadTemplate: DownloadTemplate,
  uploadTemplate: UploadTemplate,
  infoCircle: InfoCircle,
  builder: Builder,
  builderCircle: BuilderCircle,
  fsk: FSK,
  eye: Eye,
  successIcon: SuccessIcon,
  userInfo: UserInfo,
  messages: Messages,
  statusCircle: StatusCircle,
  clock: Clock,
  processLine: ProcessLine,
  mail: Mail,
  mailWithDot: MailWithDot,
  copyDoc: CopyDoc,
  loaderIcon: LoaderIcon,
  eraser: Eraser,
  stars: Stars,
  scissors: Scissors,
  automaticCircle: AutomaticCircle,
  // выпилить groupExpand и groupCollapse, если останутся незаюзаны
  groupExpand: GroupExpand,
  groupCollapse: GroupCollapse,
  actGroup: ActGroup,
}

export type IconName2 = keyof typeof Icons

type Props = {
  icon: IconName2
  size?: number
  height?: number
  color?: PaletteColor | 'inherit'
  fill?: PaletteColor | 'transparent' | 'none'
  tooltipText?: string
  padding?: number
}

export const Icon2 = ({
  icon,
  size = 16,
  height,
  color = 'inherit',
  fill = 'none',
  tooltipText,
  padding = 0,
}: Props) => {
  const Component = Icons[icon]
  const fillColor = getColor(fill)

  const {
    refs,
    arrowProps,
    ...tooltip
  } = useTooltip({
    strategy: 'fixed',
    placement: 'bottom',
  })

  return (
    <Wrapper
      ref={refs.setReference}
      $size={size}
      $color={color}
      $height={height}
      $padding={padding}
    >
      <Component fill={fillColor} />
      {tooltipText && (
        <ToolTipV2
          {...tooltip}
          refs={refs}
        >
          <ToolTipText>
            {tooltipText}
          </ToolTipText>
        </ToolTipV2>
      )}
    </Wrapper>
  )
}

type WrapperProps = {
  $size: number
  $height?: number
  $color: PaletteColor | 'inherit'
  $padding: number
}

const Wrapper = styled.div<WrapperProps>`
  display: inline-flex;
  flex-shrink: 0;

  ${({ $size, $color, $height, $padding }) => css`
    color: ${getColor($color)};
    width: ${$size + $padding * 2}px;
    height: ${$height || ($size + $padding * 2)}px;
    padding: ${$padding}px;
  `}
`

const ToolTipText = styled.div`
  ${TextSLight}
  color: ${palette.white};
`
