import { attachWrapper } from '@42px/effector-extra'

import { Method, authRequestFx } from '../request'
import { GetGroupTreeParams, TreeItem } from './types'
import { AbortableRequest, DefaultResponseFail, Response } from '../interfaces'
import { defaultErrorHandler } from '../error-handler'

export const getGroupsTreeReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ documentId, documentType, signal }: AbortableRequest<GetGroupTreeParams>) => ({
    method: Method.get,
    url: `/${documentType}/groups/${documentId}/`,
    signal,
  }),
  mapResult: ({ result }: Response<TreeItem[]>) => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})
