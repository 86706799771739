import React from 'react'
import styled from 'styled-components'

import { HeadL, HeadM, palette } from '@/ui'

type Props = {
  workCount?: number | null
  title?: string
}

export const GroupTitle = ({ workCount, title = 'Список работ' }: Props) => {
  return (
    <Title>
      {title}
      {typeof workCount === 'number' && (
        <WorkCount>
          {workCount}
        </WorkCount>
      )}
    </Title>
  )
}

const Title = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  ${HeadL}
`

const WorkCount = styled.span`
  ${HeadM}
  color: ${palette.grey60};
`
