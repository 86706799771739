import React from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import { useUnit } from 'effector-react'
import styled from 'styled-components'

import {
  NewButton, NewCheckbox, SimpleLoader,
} from '@/ui'

import {
  $closureDocumentInfo,
  $currStep,
  $downloadClosureDocumentLink,
  $selectedDocIds,
  getClosureDocumentsByIdFx,
  toggleDocId,
} from '../../model/private'


export const DocumentList = React.memo(() => {
  const step = useUnit($currStep)
  const info = useUnit($closureDocumentInfo)
  const selectedIds = useUnit($selectedDocIds)
  const downloadLink = useUnit($downloadClosureDocumentLink)
  const isPending = useUnit(getClosureDocumentsByIdFx.pending)

  const isDownload = step === 1
  return (
    <>
      <Scrollbars autoHeight autoHeightMin={0} autoHeightMax="30dvh">
        <Wrapper>
          {isPending && (
            <LoaderWrapper>
              <SimpleLoader sizePx={24} />
            </LoaderWrapper>
          )}
          {!isPending && info?.docs?.map((item) => (
            <NewCheckbox
              key={item.id}
              label={item.name}
              onChange={toggleDocId}
              dataTestId={`doc-${item.id}`}
              value={item.id}
              isChecked={selectedIds.includes(item.id)}
            />
          ))}
        </Wrapper>
      </Scrollbars>

      {
        isDownload && (
          <a href={downloadLink} download target="_blank" rel="noreferrer">
            <NewButton
              dataTestId="download-all-selected-docs"
              label="Скачать документы"
              isFill
              buttonType="secondary"
              isDisabled={!downloadLink}
            />
          </a>
        )
      }

    </>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 24px;
`

const LoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
