import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'
import { useTransition, animated } from '@react-spring/web'

import { HEADER_HEIGHT } from '@/ui'
import { ToastItem } from '../parts'

import { $toasts, onCloseButton } from '../../model/private'

export const ToastList = React.memo(() => {
  const toasts = useUnit($toasts)

  const transitions = useTransition(toasts, {
    keys: (toast) => toast.id,
    from: { opacity: 1, y: -50 },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0 },
  })

  return (
    <ListWrapper>
      <List>
        {transitions((style, item) => (
          <animated.div key={item.id} style={style}>
            <ToastItem
              content={item.content}
              icon={item.icon}
              id={item.id}
              onClose={item.showClose ? onCloseButton : undefined}
            />
          </animated.div>
        ))}

      </List>
    </ListWrapper>
  )
})

const ListWrapper = styled.div`
  position: fixed;
  top: ${HEADER_HEIGHT + 15}px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  pointer-events: none;
  z-index: 999;
`

const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
`
