import { attach, combine, createEvent, createStore } from 'effector'
import { createGate } from 'effector-react'

import {
  ActGaGroupRow,
  ActGaRow,
  getGaActingGroupsReqFx,
  isGaGroupRow,
  resetActingGroupReqFx,
  toggleMorphologyWorksReqFx,
} from '@/dal'
import { createModal } from '@/features/factories'

export const ActingGroupsGate = createGate('ActingGroupsGate')

export const $allGaTableItems = createStore<(ActGaRow | ActGaGroupRow)[]>([])

export const $gaTableSearch = createStore('')
export const changeGaTableSearch = createEvent<string>()
export const resetGaTableSearch = createEvent()
export const acceptGaTableSearch = createEvent()

export const $gaTableItems = combine([$allGaTableItems, $gaTableSearch], ([items, search]) =>
  items.filter(
    (item) =>
      isGaGroupRow(item) || item.action_group_name.toLowerCase().includes(search.toLowerCase()),
  ),
)

export const fillMorphologyWorks = createEvent<string[]>()
export const clearMorphologyWorks = createEvent<string[]>()

export const $openedActingGroupId = createStore<ActGaRow['id'] | null>(null)

export const resetActingGroup = createEvent<ActGaRow['id']>()

export const $openedActingGroup = combine(
  [$openedActingGroupId, $allGaTableItems],
  ([id, actionGroups]) =>
    (actionGroups.find((item) => 'id' in item && item.id === id) as ActGaRow | undefined) ?? null,
)

export const actingGroupModal = createModal<ActGaRow['id']>()

export const getGaActingGroupsFx = attach({ effect: getGaActingGroupsReqFx })
export const toggleMorphologyWorksFx = attach({ effect: toggleMorphologyWorksReqFx })

export const resetActingGroupFx = attach({ effect: resetActingGroupReqFx })
