import React, { memo, useMemo } from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { ResponsibleAppealAlias, OtherAppealAlias } from '@/dal'
import { ModalLayoutV2 } from '@/ui'
import { APPEAL_SECTIONS } from '@/features/support/model/const'
import { SecondStep } from '@/features/support/new-appeal'
import { $isWaitingForScreenshotMode, askForScreenCapture } from '@/features/screen-capture/model'

import { resetContactsStep } from '../../model'
import {
  $contactsStep,
  $isAppealSending,
  $selectedAppealType,
  $techSupportAppealType,
  createAppealModal,
} from '../../model/private'
import { $isBottomBlockHidden } from '../../model/screenshot.private'
import {
  AddUserForm,
  BlockUserForm,
  ContactsForm,
  DocumentProblemForm,
  LkpSectionProblem,
  SelectTechSupportType,
} from '../containers'
import { MinimizedModal } from '../parts'

export const CreateAppealModal = memo(() => {
  const [isOpen, isMinimized, selectedType, techSupportAppealType, contactsStep] = useUnit([
    createAppealModal.$isOpen,
    createAppealModal.$isMinimized,
    $selectedAppealType,
    $techSupportAppealType,
    $contactsStep,
  ])
  const isWaitingForScreenshot = useUnit($isWaitingForScreenshotMode)
  const isBottomBlockHidden = useUnit($isBottomBlockHidden)
  const isSending = useUnit($isAppealSending)

  const content = useMemo(() => {
    if (contactsStep) {
      return <ContactsForm />
    }
    if (techSupportAppealType === 'section') {
      return <LkpSectionProblem />
    }
    if (techSupportAppealType === 'document') {
      return <DocumentProblemForm />
    }

    switch (selectedType) {
      case ResponsibleAppealAlias.Act:
      case ResponsibleAppealAlias.ActReq:
      case ResponsibleAppealAlias.ProjectCor:
      case ResponsibleAppealAlias.ProjectDir:
      case ResponsibleAppealAlias.ConstructionDir:
      case ResponsibleAppealAlias.Contract:
      case ResponsibleAppealAlias.Estimate:
        return <SecondStep />
      case OtherAppealAlias.TechSupport:
        return <SelectTechSupportType />
      case OtherAppealAlias.CreateUser:
        return <AddUserForm />
      case OtherAppealAlias.BlockUser:
        return <BlockUserForm />
      default:
        return null
    }
  }, [selectedType, contactsStep, techSupportAppealType])

  if (isMinimized) {
    if (isBottomBlockHidden) return null

    const section = APPEAL_SECTIONS.find(({ alias }) => alias === selectedType)
    const techSupportTitle = techSupportAppealType
      && `Вопрос с ${techSupportAppealType === 'document' ? 'документом' : 'разделом ЛКП'}`
    const title = techSupportTitle ?? section?.name ?? ''

    return (
      <MinimizedModal
        hasScreenshotButton={isWaitingForScreenshot}
        title={title}
        description={section?.description ?? null}
        onOpen={createAppealModal.unfold}
        onClose={createAppealModal.close}
        onScreenshot={askForScreenCapture}
      />
    )
  }

  if (!isOpen) return null

  const handleGoBack = () => {
    if (!isSending) {
      resetContactsStep()
    }
  }

  return (
    <ModalLayoutV2
      borderRadius={12}
      canClose={!isSending}
      onMinimize={createAppealModal.minimize}
      closeModal={createAppealModal.close}
      onGoBack={contactsStep ? handleGoBack : undefined}
    >
      <Wrapper>
        {content}
      </Wrapper>
    </ModalLayoutV2>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: fit-content;
`
