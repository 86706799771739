import React from 'react'
import styled, { css } from 'styled-components'
import { useDropzone } from 'react-dropzone'
import { usePopperTooltip } from 'react-popper-tooltip'

import { themeVar } from './theming'
import { SimpleLoader } from './Loader'
import { PopperToolTip } from './PopperToolTip'

type Props = {
  onFiles: (files: File[]) => void
  height?: string
  maxFiles?: number
  disabled?: boolean
  isLoading?: boolean
  tooltip?: string | JSX.Element
}
export const Dragger: React.FC<Props> = ({
  onFiles,
  height = '80px',
  disabled = false,
  isLoading,
  tooltip,
}) => {
  const onDrop = React.useCallback(
    (files: File[]) => {
      onFiles(files)
    },
    [onFiles],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    // https://react-dropzone.js.org/#section-accepting-specific-file-types
    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
    accept: { 'application/pdf': ['.pdf'] },
    maxSize: 10 * 1024 * 1024,
    onDrop,
    disabled: Boolean(disabled),
  })

  const popperTooltip = usePopperTooltip({
    placement: 'top',
  })

  return (
    <>
      {tooltip && (
        <PopperToolTip
          getArrowProps={popperTooltip.getArrowProps}
          getTooltipProps={popperTooltip.getTooltipProps}
          setTooltipRef={popperTooltip.setTooltipRef}
          tooltip={tooltip}
          visible={popperTooltip.visible}
        />
      )}
      <Wrapper
        {...getRootProps()}
        height={height}
        disabled={disabled}
        ref={popperTooltip.setTriggerRef}
      >
        <Input {...getInputProps()} data-testid="input-dragger" />
        {!isDragActive ? (
          isLoading ? <SimpleLoader sizePx={24} /> : 'Перетащите файлы сюда'
        ) : (
          <h3>Нажмите или перетащите сюда файлы.</h3>
        )}
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div<{ height: string, disabled: boolean }>`
  width: 100%;
  border: 1px dashed ${themeVar('line')};
  color: ${themeVar('gray4')};
  font-size: 12px;
  height: ${({ height }) => height};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${({ disabled }) => !disabled && css`
    &:hover {
      border: 1px dashed ${themeVar('fontColor')};
      background-color: ${themeVar('mainOrange')};
      color: ${themeVar('main')};
    }
  `}

  svg {
    margin-right: 8px;
    font-size: 24px;
  }
`

const Input = styled.input`
  display: none;
`
