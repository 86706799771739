import { ContractDirection } from '../administrative'
import { CompensationDocument } from '../compensation'
import { DefaultErrorResponseData } from '../error-handler'
import { Group } from '../group-tree'
import {
  ActId,
  DefaultUpdateFileParams,
  DocumentAlias,
  DocumentExclusiveBlock,
  DocumentFeatures,
  DocumentProgress,
  DocumentStatuses,
  DocumentTracking,
  FileDTO,
  RejectionReason,
} from '../interfaces'

export type KS2Data = {
  material: {
    declared: string
    approved: string
    diff: string | null
  }
  work: {
    declared: string
    approved: string
    diff: string | null
  }
  total: {
    declared: string
    approved: string
    diff: string | null
  }
}

export type KS3Data = {
  guarantee_deductions?: {
    percent: string
    declared: string
    approved: string
    diff: string | null
  }
  advans: {
    percent: string
    declared: string
    approved: string
    can_edit: boolean
    diff: string | null
  }
  advans_remainder?: {
    declared: string
    can_approve: boolean
    diff: string | null
    approving_available: boolean
  }
  deductions?: {
    items: {
      name: string
      percent?: string
      declared: string
      approved: string
    }[]
    declared: string
    approved: string
    diff: string | null
  }
  total: {
    declared: string
    approved: string
    diff: string | null
  }
  total_cost?: {
    declared: string
    approved: string
    diff: string | null
  }
  sum_total?: {
    declared: string
    approved: string
    diff: string | null
  }
}

export type PaymentOrder = {
  id: number
  number: string
  date: string // YYYY-MM-DD
}

export type NewPaymentOrder = Omit<PaymentOrder, 'id'> & {
  id: null
}

export enum ActWorkStatus {
  /** Не выполнено */
  NotCompleted = 'not_completed',
  /** Промежуточная приемка */
  InterimAcceptance = 'interim_acceptance',
  /** Заявлено */
  Declared = 'declared',
  /** Выполнено */
  Completed = 'completed',
}

export type ReportPeriod = {
  date_start: string // YYYY-MM-DD
  date_end: string // YYYY-MM-DD
}

export type ActDTO = {
  id: ActId
  name: string
  number: string | null
  type: DocumentAlias.ACT
  act_type: ContractDirection
  /** Есть ли группы актирования */
  is_act_group_used: boolean
  /** ISO date */
  last_update: string
  status: DocumentStatuses
  progress: DocumentProgress
  comment: string | null
  ks2: KS2Data | null
  ks3: KS3Data
  attachments: FileDTO[] | null
  documents_link: string
  payment_orders: PaymentOrder[] | null
  instruction_link?: string | null
  reject_comment: RejectionReason
  cancel_comment: RejectionReason
  approve_comment: string | null
  cancellation_comment: {
    description: string
    help_description: string
  } | null
  report_period: ReportPeriod | null
  contract: {
    id: number
    name: string
    project: {
      id: number
      name: string
    }
  }
  /** ISO date */
  reject_time: string | null
  features: DocumentFeatures & {
    can_process_templates: boolean
    can_edit_payment_orders: boolean
    can_count_advance: boolean
    has_negative_completions_counts: boolean
    can_delete_last_payment_order: boolean
    editable_fields: {
      comment: boolean
      reporting_period__date_end: boolean
      ks3_advans_declared: boolean
    }
    document_exclusive_block: DocumentExclusiveBlock
  }
  document_tracking: DocumentTracking
  related_payment_document: {
    id: PaymentOrder['id']
    name: string
    status: DocumentStatuses
    progress: DocumentProgress
  } | null
  draft?: {
    id: number
    name: string
  }
  compensation_documents_info: {
    items: {
      id: number
      name: string
      status: DocumentStatuses
      consumption_period: string | null
      sum: string // float number
      signed_docs_link: string // url
    }[]
    total_sum: string
  } | null
}

type BaseActTableFolder = {
  is_folder: true
  name: string
  /** uuid */
  id: string
  note: string
  number_1c: string
  article: string
  group_id: string | null
  depth: number
  has_children: boolean
  morphology_id: string | null
  morphology_type: ActRowType | null
}

export type ActTableFolderSMR = BaseActTableFolder & {
  period_cost: {
    total: string
    material: string
    work: string
  }
}

export type ActTableFolderPIR = BaseActTableFolder & {
  total_cost: string
  period_cost: string
}

export enum ActRowType {
  ActionGroup = 'action_group',
  ConstructionObject = 'construction_object',
  Building = 'building',
  Section = 'section',
  Floor = 'floor',
  Folder = 'folder',
  Group = 'group',
  Work = 'work',
}

type ActMophologyType =
  | ActRowType.ConstructionObject
  | ActRowType.Building
  | ActRowType.Section
  | ActRowType.Floor

export type ActTableFolder = ActTableFolderSMR | ActTableFolderPIR

type BaseActTableItem = {
  is_folder: false
  name: string
  id: string // uuid
  number_1c: string
  project: string
  article: string
  note: string
  group_id: string | null
  depth: number
  estimate: {
    current: string
  }
  remainder: {
    current: string
  }
  unit: string
  // TODO не уверен, что по итогу будет
  row_type?: ActRowType
}

export type ActWorkSMR = BaseActTableItem & {
  completed: {
    current: string
    diff: string | null
    corrected: {
      value: string | null
      comment: string
    }
    filled_value: string // для заполнения по остатку
    can_edit: boolean
  }
  total_cost: {
    total: string
    material: string
    work: string
  }
  period_cost: {
    total: string
    material: string
    work: string
  }
}

export type ActWorkPIR = BaseActTableItem & {
  status: ActWorkStatus
  start_date: string // YYYY-MM-DD
  end_date: string // YYYY-MM-DD
  is_completed: {
    value: boolean
    can_change: boolean
  }
  total_cost: string
  period_cost: string
}

export type ActTableItem = ActWorkSMR | ActWorkPIR

// также есть некоторые null поля, неиспользуемые на фронте
export type ActGroupItem = {
  id: string
  name: string
  row_type: ActRowType.ActionGroup
  construction_object_name: string
  depth: number
  is_folder: boolean
  is_accepted: boolean | null
  has_children: boolean
  period_cost: {
    total: string
    material: string
    work: string
  }
}

export type GaMorphologyItem = Omit<ActGroupItem, 'construction_object_name' | 'row_type'> & {
  row_type: ActMophologyType
}

export enum ActGaGroupType {
  WithPlaces = 'works_with_places',
  WithoutPlaces = 'works_without_places',
}

export type ActGaRow = {
  /** uuid */
  id: string
  action_group_name: string
  unit: string
  /** float */
  approved_cost: string
  /** float */
  start_cost: string
  /** float */
  period_cost: string
  ordering: number
  count_all: number
  start_count: number
  period_count: number
  is_accepted: boolean
  is_rejected: boolean
  buildings: GaBuilding[]
}

export type ActGaGroupRow = {
  type: ActGaGroupType
  ordering: number
  isAccepted: boolean
  /** float */
  periodCost: string
}

export type GaEndNode = {
  id: string
  name: string
  /** float */
  total_price: string
  comment: string | null
  is_closed_current_period: boolean | null
  is_closed_last_period: boolean | null
  is_rejected: boolean
  works_uuids: string[]
}

export type GaBuilding = {
  id: string
  name: string
  period_total_cost: string
  period_total_material: string
  period_total_work: string
  sections: GaSection[]
}

export type GaSection = {
  id: string
  name: string
} & (
  | ({
      floors: []
    } & Omit<GaEndNode, 'name'>)
  | {
      floors: GaEndNode[]
    }
)

export enum RowFilterEnum {
  IsCorrected = 'corrected_by_technical_supervision',
  HideDeleted = 'hide_deleted',
}

export enum RemainedFilter {
  FilledJobs = 'filled_jobs',
  UnFilledJobs = 'unfilled_jobs',
  CompletedJobs = 'previously_completed_jobs',
}

export type ActionGroupItem = {
  id: number
  name: string
  unit: number
}

export type GetActTableParams = {
  actType: ContractDirection
  actId: ActId
  groupId?: Group['id']
  actionGroups?: ActionGroupItem['id'][]
  name?: string
  article?: number[]
  remained?: RemainedFilter[]
  rowFilters?: RowFilterEnum[]
  // для СМР
  reportPeriod?: RemainedFilter[]
  // для ПИР
  acceptanceStatus?: ActWorkStatus | null
  dateFrom?: string
  dateTo?: string
}

export type ActTableRow =
  | ({
      is_folder: true
    } & ActTableFolder)
  | ({
      is_folder: false
    } & ActTableItem)
  | GaMorphologyItem

export type ActWorksExtraFields = {
  fill_by_remaining: boolean
  can_fill_by_remaining: boolean
  total_volume: string
  download_template_link: string
  all_period_cost?: string // стоимость за отчётный период в ПИР
  // суммы в шапке таблицы
  period_cost?: {
    total: string
    material: string
    work: string
  }
}

export type GetTreeTableResponse = ActWorksExtraFields & {
  root: ActTableRow[]
  total: number | null
}

export type GetFlatTableResponse = { items: ActTableItem[] } & ActWorksExtraFields

export type GetGaTableParams = {
  actId: ActId
}

type WorksGroupRow = {
  is_accepted: boolean
  /** float */
  period_total_cost: string
  /** float */
  period_total_material: string
  /** float */
  period_total_work: string
}

export type GetGaActingGroupsResponse = {
  action_groups: ActGaRow[]
  works_with_places: WorksGroupRow | null
  works_without_places: WorksGroupRow | null
}

export type GetFolderContentParams = Omit<GetActTableParams, 'groupId'> & {
  groupId: ActTableFolder['id']
}

export type GetGaActTableParams = {
  actId: ActId
  isFolderView?: boolean
  hasMorphology?: boolean
}

export type GetGaTableItemContentParams = GetGaActTableParams & {
  groupId: string
  groupType?: ActRowType
  morphologyId?: string | null
  morphologyType?: ActRowType | null
}

export type ResetActTableParams = {
  id: ActId
  hasMorphology: boolean
}

export type FillByRemainingParams = {
  isFill: boolean
  actId: ActId
}

export type UploadActTemplateResponse = {
  ks: FillByRemainingResponse
}

export type FillByRemainingResponse = {
  ks2: KS2Data
  ks3: KS3Data
  is_fill_by_remaining: boolean
}

export type SendWorkVolumeFxPayload = {
  actId: ActId
  actType: ContractDirection
  workId: string
  value: string | boolean
  isTree: boolean
}

export type UpdateTableResponse = ActWorksExtraFields & {
  ks2: KS2Data | null
  ks3: KS3Data
  total_volume: string
  // updated root item, for update a root array
  root?: ActTableRow
  // tree updated items
  tree?: { [key in ActTableFolder['id']]: ActTableRow[] }
  // target item
  target?: ActTableRow
}

export type ArticleOutlay = {
  name: string
  number_1c: string
  id: number
}

export type UploadActTableTemplate = {
  actId: ActId
  file: File
}

export type PostPaymentOrdersParams = {
  id: ActId
  to_delete?: PaymentOrder['id'][]
  to_update?: (PaymentOrder | NewPaymentOrder)[]
}

export type ResponseWithKSData = {
  ks2: KS2Data
  ks3: KS3Data
  is_fill_by_remaining: boolean
}

export type PostActAttachmentsParams = {
  id: ActId
} & DefaultUpdateFileParams

// @TODO актуализировать позже
export enum ActErrorCode {
  ApproveError = 'ACT_APPROVING_CONFLICT_WITH_OTHER',
  CreateConflictError = 'ACT_NEW_CONFLICT',
  CreateErrorRequiredFields = 'CONTRACT_EMPTY_REQUIRED_FIELDS_CONFLICT',
  CompensationsError = 'UNSIGNED_COMPENSATIONS_IN_DIADOC',
}

export type CreateActReqiredFieldErrorData = {
  code: ActErrorCode.CreateErrorRequiredFields
  detail: {
    contragent_id: string
    message: string
  }
}

export type ActForApproveErrorData = {
  code: ActErrorCode.ApproveError
  detail: string
  id?: ActId
  number?: string
}

export type CreateActConflictErrorData = {
  detail: string
  code: ActErrorCode.CreateConflictError
  id: ActId
  number: string
}

export type ActCompensationsErrorData = {
  code: ActErrorCode.CompensationsError
  detail: {
    id: CompensationDocument['id']
    name: string
  }[]
}

export type ActErrorResponse =
  | ActForApproveErrorData
  | CreateActConflictErrorData
  | CreateActReqiredFieldErrorData
  | ActCompensationsErrorData
  | DefaultErrorResponseData

export type CreateActPayload = {
  contractId: number
  // не нужно, если создаётся выполнение ПИР
  reporting_period?: {
    /** YYYY-MM-DD */
    date_start: string
    /** YYYY-MM-DD */
    date_stop: string
  }
}

export type ToggleMorphologyWorksPayload = {
  actId: ActId
  worksToFill?: GaEndNode['works_uuids']
  worksToClear?: GaEndNode['works_uuids']
}
