import React, { memo } from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { NewInput, palette } from '@/ui'

import {
  $gaTableSearch,
  acceptGaTableSearch,
  changeGaTableSearch,
  resetGaTableSearch,
} from '../../model/private'

export const GaTableFilters = memo(() => {
  const search = useUnit($gaTableSearch)

  return (
    <Wrapper>
      <InputWrapper>
        <NewInput
          placeholder="Поиск по наименованию группы актирования"
          value={search}
          postfixIcon="search"
          dataTestId="ga-act-search-input"
          onChange={changeGaTableSearch}
          onEnterPress={acceptGaTableSearch}
          onClear={resetGaTableSearch}
          onPostfixIconClick={acceptGaTableSearch}
        />
      </InputWrapper>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  padding: 20px;
  background-color: ${palette.white};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`

const InputWrapper = styled.div`
  width: 300px;
`
