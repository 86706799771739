import { attach, combine } from 'effector'
import { createForm } from 'effector-forms'

import { rules } from '@/lib/form-validators'
import { EstimateTableRow, FileDTO, splitVolumeReqFx } from '@/dal'

import { d } from './domain'
import { $flatItems } from './private'


export const $splitTargetId = d.store<EstimateTableRow['id'] | null>(null)
export const closeModal = d.event<void>()

export const $splitTarget = combine({
  items: $flatItems,
  id: $splitTargetId,
}, ({ items, id }) => items?.find((item) => item.id === id) ?? null)

export const splitVolumeForm = createForm({
  domain: d,
  validateOn: ['change'],
  fields: {
    old_count_in_estimate: {
      init: '',
      rules: [rules.required(), rules.greaterThanZero()],
    },
    old_justification_of_changes_current: {
      init: '',
    },
    old_files_to_save: {
      init: [] as File[],
    },
    old_files_to_delete: {
      init: [] as FileDTO['id'][],
    },

    new_count_in_estimate: {
      init: '',
      rules: [rules.required()],
    },
    new_material: {
      init: '',
      rules: [rules.sumBothFieldsMustBeGreaterThanZero('new_work')],
    },
    new_work: {
      init: '',
      rules: [rules.sumBothFieldsMustBeGreaterThanZero('new_material')],
    },
    new_justification_of_changes_current: {
      init: '',
    },
    new_files_to_save: {
      init: [] as File[],
    },
    // TODO убрать эти поля и обновить DTO
    new_provider: {
      init: '',
    },
    new_specifications: {
      init: '',
    },
    new_stamp: {
      init: '',
    },
  },
})

export const splitVolumeFx = attach({
  effect: splitVolumeReqFx,
})
