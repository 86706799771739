import React from 'react'
import styled, { css } from 'styled-components'

import { noop } from '@/lib/noop'
import { useTooltip } from '@/lib/hooks'
import { mergeRefs } from '@/lib/mergeRefs'

import { TextL, TextLLight } from '../../typography'
import { DefaultButton } from '../DefaultButton'
import { styles } from './styles'
import { AnimationWrapper } from './wrappers'
import { ButtonSize, ButtonType } from './types'
import { ToolTipV2 } from '../../components/tooltip'
import { ButtonLoader } from './ButtonLoader'
import { palette } from '../../palette'
import { OverflowText } from '../../components/OverflowText'
import { NewIconButton } from '../NewIconButton'

type HandleClick = ((e: React.MouseEvent) => void) | (() => void)

type Props = {
  label: string
  size?: ButtonSize
  isPending?: boolean
  tooltip?: string
  dataTestId: string
  isFill?: boolean
  count?: number | null
  totalCount?: number
  canClose?: boolean
  isTextLimited?: boolean
  isDisabled?: boolean
  onClick?: HandleClick
  onClose: HandleClick
}

export const TagButton = React.forwardRef(({
  isPending,
  label,
  size = 'S',
  tooltip,
  dataTestId,
  isFill,
  count,
  totalCount,
  canClose = true,
  isTextLimited = true,
  isDisabled,
  onClick,
  onClose,
}: Props, ref: React.ForwardedRef<HTMLDivElement>) => {
  const {
    floatingStyles,
    getFloatingProps,
    getReferenceProps,
    isOpen,
    refs,
  } = useTooltip({ tooltipOffset: 4 })

  const handleClick = isPending ? noop : onClick

  const countString = count && count !== totalCount ? `(${count}/${totalCount})` : ''
  const buttonType = countString ? 'grey' : 'line-grey'

  return (
    <AnimationWrapper
      $isFill={isFill}
      {...getReferenceProps()}
      ref={mergeRefs([ref ?? null, refs.setReference])}
    >
      <Wrapper
        onClick={(e) => handleClick?.(e)}
        $size={size}
        $buttonType={buttonType}
        data-testid={dataTestId}
        disabled={isDisabled}
        data-pending={isPending || undefined}
      >
        <Label $isTextLimited={isTextLimited}>
          <OverflowText maxTooltipWidth={360}>
            {label}
          </OverflowText>
        </Label>

        {countString && (
          <Count>
            {countString}
          </Count>
        )}

        {canClose && (
          <NewIconButton
            icon="cross"
            color="accent100"
            disabledColor="accent70"
            disabled={isDisabled}
            dataTestId=""
            onClick={onClose}
          />
        )}
      </Wrapper>

      {isPending && (
        <ButtonLoader buttonType={buttonType} />
      )}

      {tooltip && (
        <ToolTipV2
          floatingStyles={floatingStyles}
          getFloatingProps={getFloatingProps}
          isOpen={isOpen}
          refs={refs}
          size="M"
        >
          {tooltip}
        </ToolTipV2>
      )}
    </AnimationWrapper>
  )
})

type WrapperProps = {
  $size: ButtonSize
  $buttonType: ButtonType
}

const Wrapper = styled(DefaultButton)<WrapperProps>`
  ${TextLLight}
  transition: color 0.15s linear, background-color 0.15s linear, border 0.15s linear;
  border-radius: 8px;
  gap: 8px;
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: max-content;
  justify-content: center;
  border: 1px solid transparent;
  padding: ${({ $size }) => ($size === 'M' ? '9px 23px' : '5px 19px')};
  cursor: default;
  ${({ $buttonType: type }) => styles[type]}
`

const Label = styled.div<{ $isTextLimited: boolean }>`
  ${({ $isTextLimited }) => $isTextLimited && css`
    max-width: 360px;
  `}
`

const Count = styled.div`
  ${TextL}
  color: ${palette.accent100};
`
