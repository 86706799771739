import { attachWrapper } from '@42px/effector-extra'
import { AxiosResponse } from 'axios'

import { Method, authRequestFx } from '../request'
import { ChartData } from './type'
import { defaultErrorHandler } from '../error-handler'
import { DefaultResponseFail } from '../interfaces'

export const getGraphDataReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: () => ({
    method: Method.get,
    url: '/main/timing-statistics-dataset/',
  }),
  mapResult: ({ result }: {result: AxiosResponse<ChartData>}) => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})
