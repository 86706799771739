import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { SimpleLoader } from '@/ui'

import { TopRowContainer, TreeBreadcrumbs, TreeList } from '../containers'
import { getGroupsTreeFx } from '../../model/private'

type Props = {
  workCount: number | null
  title?: string
}

export const GroupTree = React.memo(({ workCount, title }: Props) => {
  const isPending = useUnit(getGroupsTreeFx.pending)

  if (isPending) return <SimpleLoader />

  return (
    <>
      <Wrapper>
        <TopRowContainer workCount={workCount} title={title} />
        <TreeBreadcrumbs />
      </Wrapper>
      <TreeList />
    </>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

