import React from 'react'
import styled from 'styled-components'

import { ActGaGroupRow, ActGaGroupType } from '@/dal'
import { DefaultButton, Icon2, palette, TextMLight } from '@/ui'
import { numberWithSpaces } from '@/lib/number-represent'
import { CELL_BORDER_COLOR } from '@/features/act/view'

import { GaGridWrapper } from './GaGridWrapper'

type Props = ActGaGroupRow & {
  onOpenTable: () => void
}

export const GaGroupTableRow = ({ isAccepted, ordering, type, periodCost, onOpenTable }: Props) => {
  return (
    <GaGridWrapper as="td">
      <Cell>{ordering}</Cell>

      <Button onClick={onOpenTable}>
        <Cell>
          <Text>
            {type === ActGaGroupType.WithPlaces
              ? 'Работы с указанием мест работ'
              : 'Работы без указания мест работ'}
          </Text>
          {isAccepted && <Icon2 icon="check" padding={10} color="accent100" />}
          <Icon2 icon="arrowRightM" padding={10} />
        </Cell>
        <div />
      </Button>

      {[...Array(6)].map((_, i) => (
        <Cell key={i} />
      ))}

      <Cell>{numberWithSpaces(periodCost)}</Cell>
    </GaGridWrapper>
  )
}

const Button = styled(DefaultButton)`
  width: 100%;
`

const Cell = styled.div`
  ${TextMLight}
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 72px;
  padding: 8px 12px 8px 20px;
  background-color: ${palette.white};
  text-align: center;
  word-break: break-word;

  &:not(:last-child) {
    border-right: 1px solid ${CELL_BORDER_COLOR};
  }
`

const Text = styled.p`
  flex-grow: 1;
  text-align: left;
`
