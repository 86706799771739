import React from 'react'
import styled from 'styled-components'
import {
  flip, offset, shift, useFloating,
} from '@floating-ui/react'

import { mergeRefs } from '@/lib/mergeRefs'
import { NewButton, NewIconButton, palette } from '@/ui'

const BASE_FLOATING_OPTIONS = {
  middleware: [
    offset(6),
    flip(),
    shift({
      crossAxis: true,
      padding: 8,
    }),
  ],
  strategy: 'absolute' as const,
}

type Props = {
  isShown: boolean
  onSave: () => void
  onCancel: () => void
}

export const Actions = ({ isShown, onSave, onCancel }: Props) => {
  const {
    refs: bottomRefs,
    floatingStyles: bottomFloatingStyles,
  } = useFloating({
    ...BASE_FLOATING_OPTIONS,
    open: isShown,
    placement: 'bottom-end',
  })

  const {
    refs: sideRefs,
    floatingStyles: sideFloatingStyles,
  } = useFloating({
    ...BASE_FLOATING_OPTIONS,
    open: isShown,
    placement: 'right-start',
  })

  if (!isShown) return null

  return (
    <Wrapper ref={mergeRefs([bottomRefs.setReference, sideRefs.setReference])}>
      {/* TODO пока без дополнительного редактирования, мб нужно будет вынести отдельно */}
      {/* <EditButtons
        ref={sideRefs.setFloating}
        style={sideFloatingStyles}
      >
        <NewIconButton icon="edit" size={16} dataTestId="" onClick={() => ({})} />
        <NewIconButton icon="edit" size={16} dataTestId="" onClick={() => ({})} />
      </EditButtons> */}

      <BottomButtons
        ref={bottomRefs.setFloating}
        style={bottomFloatingStyles}
      >
        <NewButton
          label="Отменить"
          size="S"
          buttonType="secondary"
          dataTestId="screen-capture-cancel-btn"
          onClick={onCancel}
        />
        <NewButton
          label="Прикрепить к обращению"
          size="S"
          dataTestId="screen-capture-submit-btn"
          onClick={onSave}
        />
      </BottomButtons>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  inset: 0;
`

const EditButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 8px;
  border-radius: 12px;
  background-color: ${palette.white};
`

const BottomButtons = styled.div`
  display: flex;
  gap: 6px;
`
