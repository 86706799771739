import React from 'react'
import { useUnit } from 'effector-react'

import { DocumentHeader } from '@/ui'
import { RouterPaths } from '@/features/app/model'
import { DocumentAlias, DocumentStatuses, PAYMENT_INSTRUCTION_URL, PaymentSubType } from '@/dal'
import { usePendingDocument } from '@/features/document-manager/hooks'

import { copyDocument } from '@/features/document-manager/model'
import {
  $isThirdPerson,
  $paymentInfo,
  onApproveButton,
  onDelete,
  postPaymentToApproveFx,
} from '../../../model/private'
import { PAYMENT_TABS } from '../../../model/const'
import { $isCancelPending, openCancelApproveModal } from '../../../model/cancel-approve.private'
import { $isPutAvansItem } from '../../../model/table.private'
import { $activeTabId, $invalidActiveTabId, changeActiveTab } from '../../../model/public'
import { putPaymentInfoFx } from '../../../model/edit-private'
import { PaymentSubTypeLabel } from '../parts'

export const PaymentHeader = React.memo(() => {
  const info = useUnit($paymentInfo)
  const isPending = useUnit(postPaymentToApproveFx.pending)
  const isPendinCancel = useUnit($isCancelPending)
  const isPutAvansItem = useUnit($isPutAvansItem)
  const activeTab = useUnit($activeTabId)
  const invalidTab = useUnit($invalidActiveTabId)
  const isThirdPerson = useUnit($isThirdPerson)
  const isSavingPending = useUnit(putPaymentInfoFx.pending)

  const {
    isDeleting,
  } = usePendingDocument({
    id: info?.id || null,
    type: DocumentAlias.PAYMENT,
  })

  const onCopy = React.useCallback(() => {
    if (!info) return

    copyDocument({
      id: info.id,
      type: info.type,
      documentName: info.name,
    })
  }, [info])

  if (!info) return null

  const paymentTypeSlot = info.pay_type !== PaymentSubType.ACT && (
    <PaymentSubTypeLabel isThirdParties={isThirdPerson} />
  )

  return (
    <DocumentHeader
      approveTitle="Отправить на согласование"
      documentName={info.name || ''}
      lastUpdate={info.last_update || ''}
      link={RouterPaths.Administrative}
      canCancelApproving={info.features?.can_cancel_approving}
      canCopy={info.features?.can_copy}
      canDelete={info.features?.can_delete}
      disableApproveButton={isPutAvansItem || isSavingPending}
      isPendingCancel={isPendinCancel}
      showApproveButton={info.features?.can_send_to_approving}
      isPendingApprove={isPending || isDeleting}
      isPendingDelete={isDeleting}
      activeTabId={activeTab}
      downloadInstructionText="Как подписать документы?"
      downloadInstructionURL={
        info.status === DocumentStatuses.SignInDiadoc ? PAYMENT_INSTRUCTION_URL : null
      }
      tabs={PAYMENT_TABS}
      docSubTypeSlot={paymentTypeSlot}
      invalidTabId={invalidTab}
      onApproveButton={onApproveButton}
      onCancelApprove={openCancelApproveModal}
      onDeleteButton={onDelete}
      onChangeTab={changeActiveTab}
      onCopy={onCopy}
    />
  )
})
