import React from 'react'
import { useUnit, useStoreMap } from 'effector-react'
import styled, { css } from 'styled-components'
import { useForm } from 'effector-forms'

import { DocumentStatuses, EstimateTableRow } from '@/dal'
import {
  NewCheckbox, SimpleLoader, TABLE_BOX_SHADOW, palette,
} from '@/ui'
import { $documentStatus, $unitOptions } from '@/features/estimate-correction/shared-model'

import {
  $pendingFolderIds,
  $tableTree,
  onCloseFolder,
  onOpenTableItemFiles,
  onOpenTableItemSplitRowFiles,
  openFolder,
} from '../../../model/private'
import { $hiddenCellGroups, $isResetRowsPending, CheckboxState } from '../../../model'
import {
  FIRST_GROUP_ID,
  SECOND_GROUP_ID,
  TABLE_BORDER_STYLE,
  THIRD_GROUP_ID,
} from '../../../model/const'
import { TableCell, TableGridWrapper, VolumeRow } from '../parts'
import { $editTargetId, editItemForm } from '../../../model/edit-table-item.private'
import { FirstTableCell } from './FirstTableCell'
import {
  $isResettingAll,
  $isResetModeEnabled,
  $resettingRows,
  toggleResettingRow,
} from '../../../model/reset-multiple-items.private'
import {
  $invalidRowIds,
  $isMultipleEditModeEnabled,
  $itemToFocus,
  $rowsFormDataMap,
  changeSpecificField,
  onEnterSubmitItem,
  resetItemToFocus,
} from '../../../model/edit-multiple-items.private'

type Props = {
  item: EstimateTableRow
  isScrolled: boolean
}

export const TableItemContainer = React.memo(({ item, isScrolled }: Props) => {
  const { fields: formFields } = useForm(editItemForm)
  const [
    hiddenCols,
    editTargetId,
    unitOptions,
    status,
    itemToFocus,
    isResetMode,
    isMultipleEditMode,
    isResettingAll,
    isResetRowsPending,
  ] = useUnit([
    $hiddenCellGroups,
    $editTargetId,
    $unitOptions,
    $documentStatus,
    $itemToFocus,
    $isResetModeEnabled,
    $isMultipleEditModeEnabled,
    $isResettingAll,
    $isResetRowsPending,
  ])

  const { id, is_folder: isFolder } = item

  const isLoading = useStoreMap({
    store: $pendingFolderIds,
    keys: [id, isFolder],
    fn: (arr, [id, isFolder]) => isFolder && arr.includes(id),
  })

  const isExpanded = useStoreMap({
    store: $tableTree,
    keys: [id],
    fn: (map, [rowId]) => Boolean(map[rowId]),
  })

  const checkboxState = useStoreMap({
    store: $resettingRows,
    keys: [id],
    fn: (map, [id]) => map[id]?.state ?? CheckboxState.Unchecked,
  })

  const genericFields = useStoreMap({
    store: $rowsFormDataMap,
    keys: [id],
    fn: (map, [id]) => map[id] || {},
  })

  const isValidEditingRow = useStoreMap({
    store: $invalidRowIds,
    keys: [id],
    fn: (arr, [id]) => !arr.some((item) => item === id),
  })

  const isEditMode = id === editTargetId || isMultipleEditMode
  const isOffHover = isEditMode || isLoading || status !== DocumentStatuses.Draft

  const genericFieldsWithHandler = React.useMemo(() => (
    Object.fromEntries(
      Object.entries(genericFields).map(([name, item]) => [
        name,
        {
          name: item.name,
          value: item.value,
          isValid: item.isValid,
          onChange: (value: any) => changeSpecificField({ id, value, name }),
        },
      ]),
    )
  ), [genericFields, id])

  const handleOpenAttachments = () => {
    onOpenTableItemFiles({ id: item.id })
  }

  const handleOpenSplitRowAttachments = () => {
    if (!item.new_lines) return
    onOpenTableItemSplitRowFiles({ id: item.new_lines[0].id })
  }

  const hiddenFirstGroup = hiddenCols.includes(FIRST_GROUP_ID)
  const hiddenSecondGroup = hiddenCols.includes(SECOND_GROUP_ID)
  const hiddenThirdGroup = hiddenCols.includes(THIRD_GROUP_ID)

  const fields = isMultipleEditMode ? genericFieldsWithHandler : formFields

  const splitFields = {
    count_in_estimate: fields.new_count_in_estimate,
    material: fields.new_material,
    work: fields.new_work,
    provider: fields.new_provider,
    stamp: fields.new_stamp,
    specifications: fields.new_specifications,
    justification_of_changes_current: fields.new_justification_of_changes_current,
  }

  const isChecked = checkboxState === CheckboxState.Checked || isResettingAll
  const isIndeterminate = checkboxState === CheckboxState.Indeterminate

  const onItemClick = () => {
    if (!item.children || isEditMode || isLoading) return
    if (isExpanded) {
      onCloseFolder(id)
    } else {
      openFolder(id)
    }
  }

  const rowColor = (() => {
    if (isChecked || isIndeterminate) return palette.accent10
    if (isEditMode && isValidEditingRow) return palette.yellow10
    if (!item.is_valid || !isValidEditingRow) return palette.red10
    if (item.is_changed) return palette.yellow10
    return null
  })()

  const newLine = item.new_lines?.[0]

  return (
    <RowWrapper
      isChanged={item.is_changed || isEditMode}
      isInvalid={!item.is_valid}
      isOffHover={isOffHover}
      isChecked={isChecked || isIndeterminate}
    >
      <TableGridWrapper
        hiddenFirstGroup={hiddenFirstGroup}
        hiddenSecondGroup={hiddenSecondGroup}
        hiddenThirdGroup={hiddenThirdGroup}
      >
        <FirstCellWrapper cellColor={rowColor} className="cell" hasShadow={isScrolled}>
          <FirstTableCell
            item={item}
            fields={fields}
            isExpanded={isExpanded}
            isEditMode={isEditMode}
            disableActionMenu={Boolean(editTargetId)}
            hasRightDivider={hiddenFirstGroup}
            onToggleExpand={onItemClick}
          />
          {isResetMode && (
            <CheckboxWrapper onClick={(e: React.MouseEvent) => e.stopPropagation()}>
              <NewCheckbox
                isChecked={isChecked}
                disabled={isResettingAll || isResetRowsPending}
                isIndeterminate={isIndeterminate}
                isBoolean
                onChange={() => toggleResettingRow({ id: item.id })}
              />
            </CheckboxWrapper>
          )}
        </FirstCellWrapper>

        {!hiddenFirstGroup && (
          <>
            <TableCell
              itemId={id}
              isEmpty={isFolder}
              cellInfo={item.note}
              isGray
              cellColor={rowColor}
            />
            <TableCell
              itemId={id}
              isEmpty={isFolder}
              cellInfo={item.cost_item}
              isGray
              cellColor={rowColor}
            />
          </>
        )}

        <TableCell
          itemId={id}
          isEmpty={isFolder}
          cellInfo={item.unit}
          isActive
          field={fields.unit}
          options={unitOptions}
          isEditMode={isEditMode}
          cellColor={rowColor}
          />

        <VolumeWrapper hiddenSecondGroup={hiddenSecondGroup} hiddenThirdGroup={hiddenThirdGroup}>
          <VolumeRow
            hiddenSecondGroup={hiddenSecondGroup}
            hiddenThirdGroup={hiddenThirdGroup}
            fields={fields}
            isEditMode={isEditMode}
            isFolder={isFolder}
            rowId={id}
            item={item}
            cellColor={rowColor}
            itemToFocus={!itemToFocus?.isNewLines ? itemToFocus : null}
            onOpenAttachments={handleOpenAttachments}
            onEnter={(val) => onEnterSubmitItem({ ...val, isNewLines: false })}
            onFocus={resetItemToFocus}
          />
          {newLine && (
            <VolumeRow
              rowId={id}
              hiddenSecondGroup={hiddenSecondGroup}
              hiddenThirdGroup={hiddenThirdGroup}
              fields={splitFields}
              isEditMode={isEditMode}
              isFolder={isFolder}
              item={newLine}
              cellColor={rowColor}
              itemToFocus={itemToFocus?.isNewLines ? itemToFocus : null}
              onOpenAttachments={handleOpenSplitRowAttachments}
              onEnter={(val) => onEnterSubmitItem({ ...val, isNewLines: true })}
              onFocus={resetItemToFocus}
            />
          )}
        </VolumeWrapper>

        {isLoading && (
          <LoaderWrapper>
            <SimpleLoader sizePx={40} />
          </LoaderWrapper>
        )}
      </TableGridWrapper>
    </RowWrapper>
  )
})

const LoaderWrapper = styled.div`
  padding: 12px 0;
  display: flex;
  justify-content: center;
  grid-column-start: 1;
  grid-column-end: -1;
`

type FirstCellWrapperProps = {
  cellColor: string | null
  hasShadow: boolean
}

const FirstCellWrapper = styled.div<FirstCellWrapperProps>`
  position: sticky;
  left: 0;
  background-color: ${palette.white};
  z-index: 3;
  transition: 0.15s linear;
  transition-property: border-color, background-color, box-shadow;
  border-bottom: 1px solid transparent;
  clip-path: inset(0px -16px 0px 0px);

  ${({ cellColor }) => cellColor && css`
    background-color: ${cellColor};
  `}

  ${({ hasShadow }) => hasShadow && css`
    box-shadow: ${TABLE_BOX_SHADOW};
  `}
`

const CheckboxWrapper = styled.div`
  position: absolute;
  // TODO пока по дизайну не очень понятно насчёт размещения чекбокса, пока так
  left: 14px;
  bottom: 16px;
`

type RowWrapperProps = {
  isChanged: boolean
  isInvalid: boolean
  isOffHover: boolean
  isChecked: boolean
}

const RowWrapper = styled.td<RowWrapperProps>`
  border-bottom: ${TABLE_BORDER_STYLE};
  position: relative;
  width: 100%;
  padding: 0;
  background-color: transparent;
  transition: background-color 0.15s linear;

  ${({ isOffHover }) => !isOffHover && css`
    @media (hover: hover) {
      &:hover {
        background-color: ${palette.grey20};
        .cell {
          background-color: ${palette.grey20};
        }
      }
    }
  `}

  ${({ isChanged }) => isChanged && css`
    background-color: ${palette.yellow10};
  `}
  ${({ isInvalid }) => isInvalid && css`
    background-color: ${palette.red10};
  `}
  ${({ isChecked }) => isChecked && css`
    && {
      background-color: ${palette.accent10};
    }
  `}
`

const STARTED_COLUMN_COUNT = 6
const COLUMN_IN_SECOND_GROUP_COUNT = 3
const COLUMN_IN_THIRD_COLUMN_COUNT = 2

type VolumeWrapperProps = {
  hiddenSecondGroup: boolean
  hiddenThirdGroup: boolean
}

const VolumeWrapper = styled.div<VolumeWrapperProps>`
  display: grid;
  grid-auto-rows: minmax(0, 1fr);

  ${({ hiddenSecondGroup, hiddenThirdGroup }) => css`
    grid-column: span ${STARTED_COLUMN_COUNT
    + (hiddenSecondGroup ? 0 : COLUMN_IN_SECOND_GROUP_COUNT)
    + (hiddenThirdGroup ? 0 : COLUMN_IN_THIRD_COLUMN_COUNT)};
  `};
`
