import { d } from './domain'

// после нажатия «Сделать скриншот» и до нажатия кнопки для самого скриншота
export const $isWaitingForScreenshotMode = d.store(false)
export const waitForScreenshotMode = d.event()
export const resetIsWaitingForScreenshotMode = d.event()

// для запроса на шеринг экрана
export const $isAskingForScreenCapture = d.store(false)
export const askForScreenCapture = d.event()

export const onScreenCaptureRequestCanceled = d.event()
export const onScreenshotSaved = d.event<Blob>()
export const onScreenshotModeExited = d.event()
