import React, { useRef } from 'react'
import { useGate, useUnit } from 'effector-react'
import { useRouteMatch } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { ActId, DocumentAlias } from '@/dal'
import {
  $documentInfo,
  $isDocumentPending,
  ActGate,
  ActInfo,
  ActDataBlock,
  ActHeader,
  ActReportingDatesModal,
  PaymentOrdersModal,
  Works,
  CancelApprovingModal,
  $isEditBlocking,
  ActTab,
  ActAttachments,
  $activeTabId,
  KS3Data,
  $isPIRAct,
  PirActSums,
  SmrActSums,
  ActCompensationsErrorModal,
  $actExclusiveBlock,
  ActingGroupModal,
  WorksHeader,
  WorksModal,
} from '@/features/act'
import { DocumentFilesButton } from '@/features/document-files'
import {
  ContentContainer,
  DocumentBlockingOverlay,
  FlexScrollbar,
  Loader,
  LoaderOverlay,
  RejectReasonNotification,
  WINDOW_WIDTH,
} from '@/ui'
import { useBlockNestedInputs } from '@/lib/hooks/useBlockNestedInputs'

export const ActPage = () => {
  const router = useRouteMatch<{ id: string }>()
  const ref = useRef<HTMLDivElement>(null)

  const [isDocumentPending, info, isPir, isBlocked, activeTab] = useUnit([
    $isDocumentPending,
    $documentInfo,
    $isPIRAct,
    $isEditBlocking,
    $activeTabId,
  ])
  const exclusiveBlock = useUnit($actExclusiveBlock)

  const id = parseInt(router.params.id, 10) as ActId

  useGate(ActGate, id)

  useBlockNestedInputs(ref, Boolean(exclusiveBlock?.is_blocked) || isBlocked)

  if (!info && isDocumentPending) return <Loader />

  return (
    <>
      <FlexScrollbar>
        <ContentContainer isAutoHeight>
          <Wrapper>
            <ActHeader />

            <RejectReasonNotification rejectReason={info?.cancel_comment} />

            <ContentWrapper>
              <TabsWrapper ref={ref} $isBlocked={isBlocked}>
                <Tab $isHidden={activeTab !== ActTab.Info}>
                  <Row>
                    <Col>
                      <ActInfo />
                      <ButtonsRow>
                        <ActAttachments />
                        <DocumentFilesButton
                          id={id}
                          docType={DocumentAlias.ACT}
                          status={info?.status}
                          downloadLink={info?.documents_link}
                        />
                      </ButtonsRow>
                      {isPir ? <PirActSums /> : <SmrActSums />}
                    </Col>

                    <Col>
                      <ActDataBlock />
                    </Col>
                  </Row>
                </Tab>

                <Tab $isHidden={activeTab !== ActTab.Works}>
                  <WorksWrapper>
                    <WorksHeader />
                    <Works/>
                  </WorksWrapper>
                </Tab>

                <Tab $isHidden={activeTab !== ActTab.Deductions}>
                  <KS3Data />
                </Tab>
              </TabsWrapper>

              {exclusiveBlock && (
                <DocumentBlockingOverlay
                  description={exclusiveBlock.description}
                  isShown={exclusiveBlock.is_blocked}
                />
              )}
            </ContentWrapper>
          </Wrapper>
        </ContentContainer>
      </FlexScrollbar>

      <ActReportingDatesModal />
      <PaymentOrdersModal />
      <CancelApprovingModal />
      <ActCompensationsErrorModal />
      <ActingGroupModal />
      <WorksModal />
      <LoaderOverlay isShown={isDocumentPending} position="fixed" />
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow: 1;
`

const ContentWrapper = styled.div`
  position: relative;
`

const TabsWrapper = styled.div<{ $isBlocked: boolean }>`
  position: relative;

  ${({ $isBlocked }) => $isBlocked && css`
    opacity: 0.4;

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      z-index: 1;
    }
  `}
`

const Tab = styled.div<{ $isHidden: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  ${({ $isHidden }) => $isHidden && css`
    display: none;
  `}
`

const Row = styled.div`
  display: flex;
  gap: 16px;
`

const Col = styled.div`
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 0;
`

const WorksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
  padding-top: 8px;
`

const ButtonsRow = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: ${WINDOW_WIDTH.desktop - 1}px) {
    flex-direction: column;
  }

  @media (min-width: ${WINDOW_WIDTH.desktop}px) {
    & > * {
      flex: 0 1 50%;
    }
  }
`
