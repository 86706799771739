import React from 'react'
import { useGate, useUnit } from 'effector-react'
import { TableVirtuoso } from 'react-virtuoso'
import styled from 'styled-components'

import { ActTableRow, isGaMorphologyElement, isPIRActWork } from '@/dal'
import { LoaderOverlay } from '@/ui'
import { useCheckScrolled } from '@/lib/hooks'

import { TableRow } from './TableRow'
import {
  $flatItems,
  $hasMore,
  $isTableLoading,
  $isTree,
  ActTableGate,
  onLoadMore,
  paginateFlatTableFx,
} from '../../model/private'
import { $isPIRAct } from '../../../model'
import { TableHeader } from './TableHeader'
import { ActTableFolder } from './TableFolder'
import { TableRowPIR } from './TableRowPIR'
import { TableHeaderPIR } from './TableHeaderPIR'
import { GaMorphologyRow } from './GaMorphologyRow'

type Props = {
  hasMorphology?: boolean
  minHeight?: string
}

export const Table = React.memo(({ hasMorphology = false, minHeight = '70dvh' }: Props) => {
  const [items, isPIR] = useUnit([$flatItems, $isPIRAct])
  const isShowPendingOverlay = useUnit($isTableLoading)
  const isTree = useUnit($isTree)
  const hasMore = useUnit($hasMore)
  const isPaginationPending = useUnit(paginateFlatTableFx.pending)

  const { isScrolled, containerRef } = useCheckScrolled([items])

  const MemoHeader = React.useCallback(() => {
    if (isPIR) {
      return <TableHeaderPIR isScrolled={isScrolled.horizontal} />
    }
    return <TableHeader isScrolled={isScrolled.horizontal} />
  }, [isScrolled, isPIR])

  const MemoComponent = React.useCallback(
    (_: number, item: ActTableRow) => {
      if (isGaMorphologyElement(item)) {
        return <GaMorphologyRow item={item} isScrolled={isScrolled.horizontal} />
      }
      if (isPIRActWork(item)) {
        return <TableRowPIR item={item} isScrolled={isScrolled.horizontal} />
      }
      // группа актирования и элемент морфологии тоже могут иметь is_folder: true
      if (item.is_folder) {
        return <ActTableFolder item={item} isScrolled={isScrolled.horizontal} />
      }
      return <TableRow item={item} isScrolled={isScrolled.horizontal} />
    },
    [isScrolled],
  )

  const handleScrollerRef = React.useCallback((ref) => {
    containerRef.current = ref
  }, [containerRef])

  useGate(ActTableGate, { hasMorphology })

  if (!items) return null

  return (
    <TableWrapper $minHeight={minHeight}>
      <TableVirtuoso
        style={{ flexGrow: 1 }}
        data={items}
        fixedHeaderContent={MemoHeader}
        itemContent={MemoComponent}
        endReached={!isTree ? () => hasMore && !isPaginationPending && onLoadMore() : undefined}
        scrollerRef={handleScrollerRef}
      />
      <LoaderOverlay isShown={isShowPendingOverlay} />
    </TableWrapper>
  )
})

const TableWrapper = styled.div<{ $minHeight: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  min-height: ${({ $minHeight }) => $minHeight};

  table {
    width: 100%;
  }

  thead {
    z-index: 5 !important;
  }
`
