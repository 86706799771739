import React, { memo } from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { DocumentStatuses } from '@/dal'
import {
  DocumentStatusLabel,
  HeadXL,
  ModalLayoutV2,
  NewButton,
  NewTextButton,
  palette,
  TextLLight,
} from '@/ui'
import { useOnChangeLocation } from '@/lib/useOnChangeLocation'
import { RouterPaths } from '@/features/app/model'
import { noop } from '@/lib/noop'

import { $actCompensationsErrorData } from '../../model/private'
import { clearActError } from '../../model'

export const ActCompensationsErrorModal = memo(() => {
  const documents = useUnit($actCompensationsErrorData)

  useOnChangeLocation(clearActError)

  if (!documents) return null

  return (
    <ModalLayoutV2
      hasCloseButton={false}
      dataTestId="act-compensations-error-modal"
      closeModal={noop}
    >
      <Wrapper>
        <Content>
          <UpperBlock>
            <Title>У вас не подписаны документы по компенсации</Title>
            <Subtitle>
              Подпишите документы в Диадок, после чего функция отправки выполнения на согласование
              будет доступна
            </Subtitle>
          </UpperBlock>

          <DocList>
            {documents.map(({ id, name }) => (
              <DocItem key={id}>
                <NewTextButton
                  label={name}
                  url={`${RouterPaths.Compensation}/${id}`}
                  textStyle="M"
                  dataTestId={`act-compensation-${id}-link-btn`}
                  isNewBlank
                  hasUnderline
                />
                <DocumentStatusLabel status={DocumentStatuses.SignInDiadoc} hasDefaultColor />
              </DocItem>
            ))}
          </DocList>
        </Content>

        <NewButton
          label="Закрыть"
          dataTestId="act-compensations-close-btn"
          isFill
          onClick={clearActError}
        />
      </Wrapper>
    </ModalLayoutV2>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px;
  width: 562px;
  text-align: center;
`

const UpperBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const Title = styled.div`
  ${HeadXL}
`

const Subtitle = styled.div`
  ${TextLLight}
  color: ${palette.grey70};
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const DocList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const DocItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
`
