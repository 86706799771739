import React, { useCallback } from 'react'
import styled, { css } from 'styled-components'
import ru from 'date-fns/locale/ru'
import DatePicker from 'react-datepicker'

import { noop } from '@/lib/noop'
import { formatDateForBackend, getDayJsObject } from '@/lib/date'

import { themeVar } from '../../../theming'
import { InputField } from '../InputField'

type Props = {
  placeholder?: string
  onChange: (e: string) => void
  onClear?: () => void
  inputValue?: string | Date | null // YYYY-MM-DD or isoDate
  disabled?: boolean
  label?: string
  required?: boolean
  errorText?: string
  fullWidth?: boolean
  includeDates?: Date[]
  dataTestId: string
}

export const MonthInput = ({
  placeholder,
  onChange,
  onClear,
  inputValue,
  disabled,
  label,
  required,
  errorText,
  fullWidth,
  includeDates,
  dataTestId,
}: Props) => {
  const date = React.useMemo(() => {
    if (!inputValue) return null
    getDayJsObject(inputValue).toDate()
  }, [inputValue])

  const formattedValue = React.useMemo(() => {
    if (!inputValue) return ''
    return getDayJsObject(inputValue).format('MM/YYYY')
  }, [inputValue])

  const handleChange = useCallback(
    (newValue: Date | null) => {
      const date = formatDateForBackend(newValue)
      onChange(date)
    },
    [onChange],
  )

  return (
    <Wrapper fullWidth={fullWidth}>
      <DatePicker
        selected={date}
        onChange={handleChange}
        showMonthYearPicker
        locale={ru}
        placeholderText={placeholder || 'мм/гггг'}
        includeDates={includeDates}
        value={formattedValue}
        disabled={disabled}
        customInput={(
          <InputField
            dataTestId={dataTestId}
            label={label}
            readOnly
            isLabel={false}
            onChange={noop}
            onClear={onClear}
            size="L"
            value=""
            disabled={disabled}
            error={errorText}
            isRequired={required}
            postfixIcon="calendar"
          />
        )}
      />
    </Wrapper>
  )
}

type WrapperProps = {
  fullWidth?: boolean
}
const Wrapper = styled.div<WrapperProps>`
  ${({ fullWidth }) => fullWidth
    && css`
      .react-datepicker-wrapper {
        width: 100%;
      }
    `}

  .dialog-sheet {
    background-color: ${themeVar('lightBackground')};
    border: 1px solid ${themeVar('calendarBorderColor')};
    z-index: 2;
  }

  .react-datepicker {
    font-family: ${themeVar('baseFont')};
  }

  .react-datepicker__month-text {
    padding: 6px;
  }

  .react-datepicker__month-text--keyboard-selected {
    background-color: ${themeVar('main')} !important;

    &:hover {
      background-color: ${themeVar('mainOrange')} !important;
    }
  }

  .react-datepicker__month-text--selected {
    background-color: ${themeVar('main')} !important;
    font-size: 14px;
    padding: 6px;

    &:hover {
      background-color: ${themeVar('mainOrange')} !important;
    }

    &:not(:hover) {
      color: ${themeVar('white')};
    }
  }

  .react-datepicker-year-header {
    background-color: ${themeVar('lightBackground')};
  }

  .react-datepicker-popper {
    padding-top: 0;
    border-top: none;
    background-color: ${themeVar('lightBackground')};
    z-index: 2;

    .react-datepicker__triangle {
      display: none;
    }

    .react-datepicker__header {
      border-bottom: none;
    }

    .react-datepicker {
      width: 100%;

      .react-datepicker__month-container {
        float: none;
      }
    }
  }
`
