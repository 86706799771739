import React from 'react'
import styled, { css, RuleSet } from 'styled-components'
import { IconButton } from './buttons/iconButton'

import { Icon, IconName } from './Icon'
import { ThemedStyledProps, themeVar, useTheme } from './theming'

type Props = {
  icon?: IconName
  value: string
  onClick?: () => void
  onChange: (s: string) => void
  onBlur?: () => void
  placeholder?: string
  error?: boolean
  errorText?: string
  fill?: boolean
  disabled?: boolean
  label?: string
  rows?: number
  maxSymbols?: number
  required?: boolean
  resizeBlock?: boolean
  dataTestId: string
  inputHeight?: string
}
export const TextArea: React.FC<Props> = ({
  icon,
  placeholder,
  value,
  onChange,
  fill = false,
  onClick,
  disabled,
  error = false,
  errorText,
  rows,
  label,
  maxSymbols,
  required,
  resizeBlock,
  dataTestId,
  inputHeight = '100%',
  onBlur,
}) => {
  const theme = useTheme()
  const inputRef = React.useRef<HTMLTextAreaElement>(null)

  const onClickHandler = () => {
    if (onClick) {
      onClick()
    }
    inputRef.current?.focus()
  }
  return (
    <Container disabled={Boolean(disabled)}>
      {label && (
        <Label>
          {label}
          {' '}
          {required && <span style={{ color: theme.main }}>*</span>}
        </Label>
      )}
      <Wrapper inputHeight={inputHeight} onClick={onClickHandler} fullWidth={fill} error={error}>
        {icon && (
          <IconWrapper>
            <Icon icon={icon} size={12} />
          </IconWrapper>
        )}
        <TextAreaWrapper>
          <TextAr
            onBlur={onBlur}
            rows={rows}
            disabled={disabled}
            readOnly={disabled}
            ref={inputRef}
            value={value}
            placeholder={placeholder}
            onChange={(e) => onChange(e.target.value)}
            maxLength={maxSymbols}
            resizeBlock={resizeBlock}
            data-testid={dataTestId}
          />
        </TextAreaWrapper>

        {value.length > 0 && !disabled && (
          <IconButton
            onClick={() => onChange('')}
            size={24}
            iconSize={24}
            dataTestId="clear-input-button"
            icon="cross"
          />
        )}
      </Wrapper>
      {error && <ErrorText>{errorText}</ErrorText>}
    </Container>
  )
}

type WrapperProps = {
  fullWidth: boolean
  error?: boolean
  customStyles?: RuleSet
  inputHeight: string
}

const Container = styled.div<{disabled: boolean}>`
  width: 100%;
  height: 100%;

  ${({ disabled }) => disabled && css`
  opacity: 0.6;
    
  `}

`

const Wrapper = styled.div<ThemedStyledProps & WrapperProps>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: ${(props) => props.inputHeight};
  cursor: text;
  padding: 8px;
  border: 1px solid ${themeVar('baseInputBorderColor')};

  ${({ fullWidth }) => Boolean(fullWidth)
    && fullWidth
    && css`
      width: 100%;
    `}

  ${({ error }) => Boolean(error)
    && error
    && css`
      border: 1px solid ${themeVar('main')};
    `}

  ${({ customStyles }) => customStyles}
`

const IconWrapper = styled.div`
  margin-right: 8px;
`

const InputCss = css`
  background: none;
  border: none;
  font-weight: 300;
  font-size: 14px;
  width: 100%;
  height: 100%;
  &::placeholder {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: ${themeVar('gray4')};
    font-family: ${themeVar('baseFont')};
  }

  &:focus {
    outline: none;
  }
`

type TextArProps = {
  resizeBlock?: boolean
  customStyles?: RuleSet
}

const TextAreaWrapper = styled.div`
  width: 100%;
  height: 100%;
`

const TextAr = styled.textarea<TextArProps>`
  ${InputCss}

  ${({ resizeBlock }) => resizeBlock
    && css`
      resize: none;
    `}

  ${({ customStyles }) => customStyles}
`

const Label = styled.label`
  font-weight: 400;
  line-height: 18px;
  font-size: 14px;
  color: ${themeVar('text')};
  margin-bottom: 4px;
  display: inline-block;
`

const ErrorText = styled.div`
  color: ${themeVar('main')};
  margin-top: 4px;
  font-size: 12px;
  height: 16px;
`
