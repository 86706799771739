import { FILE_MAX_COUNT, SCREENSHOT_NAME } from '../../model'

export const maxFilesFilterFn = (files: File[]) => files.length < FILE_MAX_COUNT

export const appendScreenshotToFiles = (files: File[], { blob }: { blob: Blob }) => {
  const maxCurrNumber = files.reduce((acc, curr) => {
    const screenshotNumber = parseInt(curr.name.split(SCREENSHOT_NAME)[1] ?? 0, 10)
    return Math.max(acc, screenshotNumber)
  }, 0)
  const number = maxCurrNumber + 1
  const file = new File([blob], `${SCREENSHOT_NAME} ${number}.png`, { type: 'image/png' })
  return { files: [...files, file] }
}
