import styled, { css } from 'styled-components'

export const TableGridWrapper = styled.div<{
  hiddenFirstGroup: boolean,
  hiddenSecondGroup: boolean,
  hiddenThirdGroup: boolean,
}>`
  display: grid;
  ${({ hiddenFirstGroup, hiddenSecondGroup, hiddenThirdGroup }) => css`
    grid-template-columns: minmax(330px, 330fr)
      ${hiddenFirstGroup ? '' : 'repeat(2, minmax(150px, 150fr))'} 
      minmax(90px, 90fr) repeat(${hiddenSecondGroup ? 4 : 7}, minmax(145px, 145fr))
      repeat(${hiddenThirdGroup ? 2 : 4}, minmax(150px, 150fr))
  `}
`
