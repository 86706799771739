import React, { memo } from 'react'
import styled from 'styled-components'

import { AppealItem, AppealStatus } from '@/dal'
import {
  DefaultButton, Icon2, Label, NewTextButton, palette, PaletteColor, TextMLight,
} from '@/ui'
import { formatDateString } from '@/lib/date'
import { APPEAL_SECTIONS } from '@/features/support/model'
import { resumeAppeal } from '@/features/support/support-appeals/model/private'
import { cancelAppealModal } from '@/features/support/cancel-appeal/model'
import { setAppealIdToCopy } from '@/features/support/support-appeals/model/copy.private'

import { GridWrapper } from '../parts'
import { STATUS_NAME_MAP } from '../../model'
import { openAppealChat } from '../../model/private'

const DEFAULT_COLORS = {
  bg: 'accent10',
  text: 'accent100',
}

const STATUS_COLOR_MAP: Record<AppealStatus, { bg: PaletteColor, text: PaletteColor }> = {
  [AppealStatus.New]: {
    bg: 'yellow30',
    text: 'grey80',
  },
  [AppealStatus.InWork]: {
    bg: 'violet10',
    text: 'violet100',
  },
  [AppealStatus.WaitingResponse]: {
    bg: 'accent10',
    text: 'accent100',
  },
  [AppealStatus.Canceled]: {
    bg: 'grey20',
    text: 'grey80',
  },
  [AppealStatus.Closed]: {
    bg: 'lightGreen10',
    text: 'lightGreen100',
  },
}

type Props = {
  item: AppealItem
  isSelected: boolean
}

export const AppealListItem = memo(({ item, isSelected }: Props) => {
  const {
    id,
    number,
    subject,
    date,
    author,
    status,
    issue_type: issueType,
    project_name: project,
    is_automatic: isAutomatic,
    can_cancelled: canCancel,
    can_resume: canResume,
    can_copy: canCopy,
    is_my_issues: isMyIssue,
    related_document_name: documentName,
    can_open_chat: canOpenChat,
  } = item
  const colors = STATUS_COLOR_MAP[status] || DEFAULT_COLORS
  const issueTypeName = APPEAL_SECTIONS.find(({ alias }) => alias === issueType)?.name ?? ''
  const actionBtnLabel = canCancel
    ? 'Отменить обращение'
    : canResume
      ? 'Возобновить обращение'
      : canCopy
        ? 'Создать копию обращения'
        : null

  const handleAction = () => {
    if (canCancel) {
      cancelAppealModal.open({ id, number, issue_type: issueType })
      return
    }
    if (canResume) {
      resumeAppeal({ id, appealType: issueType })
      return
    }
    if (canCopy) {
      setAppealIdToCopy(id)
    }
  }

  return (
    <Wrapper isSelected={isSelected}>
      {canOpenChat && (
        <OverlayButton onClick={() => openAppealChat(item)} />
      )}

      <Info>
        <Text>{number}</Text>
        <Text>{issueTypeName}</Text>
        <Text>{subject}</Text>
        <Text>{project}</Text>
        <Text>
          {formatDateString(date, true)}
        </Text>
        <Text>{author}</Text>
        <Label
          text={STATUS_NAME_MAP[status]}
          colorText={colors.text}
          bgColor={colors.bg}
          size="M"
        />
      </Info>

      <Separator />

      <Footer>
        {documentName && (
          <Text color="grey70">
            {documentName}
          </Text>
        )}

        {isAutomatic && (
          <AppealType>
            <Icon2 icon="automaticCircle" color="grey60" size={20} />
            <Text>Автоматическое обращение</Text>
          </AppealType>
        )}

        {isMyIssue && (
          <AppealType>
            <Icon2 icon="builderCircle" color="violet100" size={20} />
            <Text color="violet100">Мои задачи</Text>
          </AppealType>
        )}

        {actionBtnLabel && (
          <ActionButtonWrapper>
            <NewTextButton
              label={actionBtnLabel}
              color="grey70"
              textStyle="ML"
              dataTestId=""
              onClick={handleAction}
            />
          </ActionButtonWrapper>
        )}
      </Footer>
    </Wrapper>
  )
})

const Wrapper = styled.div<{ isSelected: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  border-radius: 8px;
  background-color: ${({ isSelected }) => (isSelected ? palette.grey40 : palette.grey10)};
  transition: background-color 0.15s linear;

  @media (pointer: fine) {
    &:hover {
      background-color: ${palette.grey40};
    }
  }
`

const OverlayButton = styled(DefaultButton)`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
`

const Info = styled(GridWrapper)`
  min-height: 51px;
`

const Text = styled.p<{ color?: PaletteColor }>`
  ${TextMLight}
  color: ${({ color = 'grey80' }) => palette[color]};
  word-break: break-word;
`

const Separator = styled.div`
  height: 1px;
  background-color: ${palette.grey40};
`

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  min-height: 16px;
`

const AppealType = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`

const ActionButtonWrapper = styled.div`
  z-index: 1;
  height: fit-content;
  width: fit-content;
  margin-left: auto;
`
