import { AxiosResponse } from 'axios'
import { attachWrapper } from '@42px/effector-extra'

import { prepareFormData } from '@/lib/form-data'
import { authRequestFx, Method } from '../request'
import {
  PayloadGetMessageList,
  PayloadPostMessage,
  PayloadPutMessage,
  AdditionalRequestResponse,
} from './types'
import { defaultErrorHandler } from '../error-handler'
import { DefaultResponseFail, Response } from '../interfaces'

export const getMessageListReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ docType, docId }: PayloadGetMessageList) => ({
    method: Method.get,
    url: `/visa/list_by_document/${docType}/${docId}/`,
  }),
  mapResult: ({ result }: { result: AxiosResponse<AdditionalRequestResponse> }) => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const putMessageReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (payload: PayloadPutMessage) => ({
    method: Method.put,
    url: `/visa/send_answer/${payload.id}/`,
    body: prepareFormData(payload),
  }),
  mapResult: ({ result }: { result: AxiosResponse<AdditionalRequestResponse> }) => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const postMessageReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (payload: PayloadPostMessage) => ({
    method: Method.post,
    url: `/visa/ask_question_for_document/${payload.docType}/${payload.id}/`,
    body: prepareFormData(payload),
  }),
  mapResult: ({ result }: { result: AxiosResponse<AdditionalRequestResponse> }) => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const markAdditionalRequestAnswerAsReadReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ docType, docId }: PayloadGetMessageList) => ({
    method: Method.post,
    url: `/visa/read_by_document/${docType}/${docId}/`,
  }),
  mapResult: ({ result }: Response<void>) => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})
