import React from 'react'
import styled from 'styled-components'

import { mergeRefs } from '@/lib/mergeRefs'
import { BaseInputStyle, InputProps } from './BaseInputStyle'
import { InputWrapper } from './InputWrapper'
import { DefaultInputProps } from './types'

type Props = DefaultInputProps & {
  formatFor?: string
  type?: 'password' | 'email' | 'text' | 'phone'
}

export const TextInput = ({
  icon,
  placeholder,
  value,
  onChange,
  onClick,
  onClear,
  iconColor = 'gray4',
  hoverIconColor = 'main',
  type = 'text',
  disabled,
  error = false,
  errorText,
  label,
  required,
  isErrorSoft,
  labelSize,
  fontSize,
  formatFor,
  asDropdown,
  isPointer,
  noOpenOnClear = false,
  noNumberStyle = false,
  dataTestId,
  tooltip,
  onBlur,
  labelColor,
  inputStyle = 'second',
  hiddenError,
  padding,
  height,
  clearIconSize,
  onIconClick,
  onPostFixIconClick,
  onFocus,
  postFixIcon,
  elementRef,
}: Props) => {
  const inputRef = React.createRef<HTMLInputElement>()

  const handleClick = () => {
    if (onClick) {
      onClick()
    }
    inputRef.current?.focus()
  }

  const handleClear = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (noOpenOnClear) {
      e.stopPropagation()
    }
    onClear?.()
  }

  return (
    <InputWrapper
      type={inputStyle}
      onClear={onClear ? handleClear : undefined}
      disabled={disabled}
      asDropdown={asDropdown}
      onClick={handleClick}
      error={error}
      errorText={errorText}
      hasVal={Boolean(value)}
      icon={icon}
      iconColor={iconColor}
      isErrorSoft={isErrorSoft}
      label={label}
      labelColor={labelColor}
      hoverIconColor={hoverIconColor}
      labelSize={labelSize}
      noNumberStyle={noNumberStyle}
      required={required}
      tooltip={tooltip}
      isPointer={isPointer}
      hiddenError={hiddenError}
      padding={padding}
      clearIconSize={clearIconSize}
      onIconClick={onIconClick}
      onPostFixIconClick={onPostFixIconClick}
      postFixIcon={postFixIcon}
      dataTestId={dataTestId}
    >
      <Input
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
        readOnly={disabled}
        type={type}
        ref={mergeRefs([elementRef ?? null, inputRef])}
        value={value}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
        fontSize={fontSize}
        formatFor={formatFor}
        isPointer={isPointer}
        data-testid={dataTestId}
        height={height}
      />
    </InputWrapper>
  )
}

const Input = styled.input<InputProps>`
  ${BaseInputStyle}
`
