import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'
import { useForm } from 'effector-forms'

import { FormModalWrapper, NumberInputField } from '@/ui'

import {
  $isNewBankAccountSuccess,
  $isOpenCreateBankModal,
  closeCreateBank,
  createBankAccountForm,
  createBankAccountFx,
} from '../../../model/create-bank-private'
import { Success } from '../../../view'

export const CreateBankAccountModal = React.memo(() => {
  const isOpen = useUnit($isOpenCreateBankModal)
  const isPendind = useUnit(createBankAccountFx.pending)
  const isSuccess = useUnit($isNewBankAccountSuccess)
  const { fields, isValid, submit } = useForm(createBankAccountForm)

  if (!isOpen) return null
  if (isSuccess) return <Success type="bankAccount" onClose={closeCreateBank} />

  const {
    account,
    bic,
  } = fields

  return (
    <FormModalWrapper
      closeModal={closeCreateBank}
      dataTestId="add-bank-account"
      onSubmit={submit}
      title="Добавление расчетного счета"
      submitLabel="Сохранить"
      cancelLabel="Отменить"
      isDisableSubmit={!isValid}
      isPending={isPendind}
      width="500px"
    >
      <FormBody>

        <NumberInputField
          dataTestId="add-bank-account-bic"
          label="БИК Банка"
          onChange={bic.onChange}
          value={bic.value}
          error={bic.firstError?.errorText}
          placeholder="000000000"
          format="#########"
          mask="_"
          decimalScale={0}
          isRequired
          allowLeadingZeros
          size="L"
        />

        <NumberInputField
          dataTestId="add-bank-account-number"
          label="Расчетный счет"
          onChange={account.onChange}
          value={account.value}
          error={account.firstError?.errorText}
          placeholder="0000 0000 0000 0000 0000"
          format="#### #### #### #### ####"
          allowLeadingZeros
          mask="_"
          decimalScale={0}
          isRequired
          size="L"
        />

      </FormBody>
    </FormModalWrapper>
  )
})

const FormBody = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
  flex-direction: column;
`
