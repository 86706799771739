
import { sample, split } from 'effector'

import { AppealAlias } from '@/dal'
import {
  askForScreenCapture,
  onScreenCaptureRequestCanceled,
  onScreenshotModeExited,
  onScreenshotSaved,
  resetIsWaitingForScreenshotMode,
  waitForScreenshotMode,
} from '@/features/screen-capture/model'

import { $selectedAppealType, $techSupportAppealType, createAppealModal } from './private'
import { TechSupportAppealType } from './types'
import { responsibleAppealTypes } from '../../model'
import {
  $isBottomBlockHidden,
  addScreenshotToAppealChat,
  addScreenshotToDocumentProblemForm,
  addScreenshotToLkpProblemForm,
  addScreenshotToResponsibleForm,
  setIsBottomBlockHidden,
} from './screenshot.private'
import { appendScreenshotToFiles, maxFilesFilterFn } from './helpers'
import { appealForm } from '../../new-appeal/model/forms'
import { documentProblemForm, lkpSectionProblemForm } from './forms'
import {
  $commentFormFiles,
  $hasSelectedAppeal,
  closeAppealChat,
  minimizeAppealChat,
  onAppealChatClosed,
  setCommentForm,
  setIsAppealChatHidden,
} from '../../request-history/model'

$isBottomBlockHidden
  .on(setIsBottomBlockHidden, (_, val) => val)
  .reset(createAppealModal.close, closeAppealChat, onScreenCaptureRequestCanceled)

sample({
  clock: waitForScreenshotMode,
  filter: createAppealModal.$isOpen,
  target: createAppealModal.minimize,
})

sample({
  clock: waitForScreenshotMode,
  filter: $hasSelectedAppeal,
  target: minimizeAppealChat,
})

sample({
  clock: [createAppealModal.close, onAppealChatClosed],
  target: resetIsWaitingForScreenshotMode,
})

sample({
  clock: askForScreenCapture,
  fn: () => true,
  target: [setIsBottomBlockHidden, setIsAppealChatHidden],
})

sample({
  clock: onScreenshotModeExited,
  fn: () => false,
  target: [setIsBottomBlockHidden, setIsAppealChatHidden],
})

type MatchPayload = {
  appealType: AppealAlias | null
  techSupportType: TechSupportAppealType | null
  hasSelectedAppeal: boolean
  blob: Blob
}

split({
  source: sample({
    clock: onScreenshotSaved,
    source: {
      appealType: $selectedAppealType,
      techSupportType: $techSupportAppealType,
      hasSelectedAppeal: $hasSelectedAppeal,
    },
    filter: ({ appealType, techSupportType, hasSelectedAppeal }) => (
      Boolean(appealType || techSupportType || hasSelectedAppeal)
    ),
    fn: (src, blob) => ({ ...src, blob }),
  }),
  match: {
    addScreenshotToResponsibleForm: ({ appealType }: MatchPayload) => Boolean(
      appealType && responsibleAppealTypes.includes(appealType),
    ),
    addScreenshotToDocumentProblemForm: ({ techSupportType }: MatchPayload) => (
      techSupportType === 'document'
    ),
    addScreenshotToLkpProblemForm: ({ techSupportType }: MatchPayload) => (
      techSupportType === 'section'
    ),
    addScreenshotToAppealChat: ({
      appealType,
      techSupportType,
      hasSelectedAppeal,
    }: MatchPayload) => (
      !appealType && !techSupportType && hasSelectedAppeal
    ),
  },
  cases: {
    addScreenshotToResponsibleForm,
    addScreenshotToDocumentProblemForm,
    addScreenshotToLkpProblemForm,
    addScreenshotToAppealChat,
  },
})

sample({
  clock: addScreenshotToResponsibleForm,
  source: appealForm.fields.files.$value,
  filter: maxFilesFilterFn,
  fn: appendScreenshotToFiles,
  target: appealForm.set,
})

sample({
  clock: addScreenshotToDocumentProblemForm,
  source: documentProblemForm.fields.files.$value,
  filter: maxFilesFilterFn,
  fn: appendScreenshotToFiles,
  target: documentProblemForm.set,
})

sample({
  clock: addScreenshotToLkpProblemForm,
  source: lkpSectionProblemForm.fields.files.$value,
  filter: maxFilesFilterFn,
  fn: appendScreenshotToFiles,
  target: lkpSectionProblemForm.set,
})

sample({
  clock: addScreenshotToAppealChat,
  source: $commentFormFiles,
  filter: maxFilesFilterFn,
  fn: appendScreenshotToFiles,
  target: setCommentForm,
})

sample({
  clock: onScreenshotModeExited,
  filter: createAppealModal.$isMinimized,
  target: createAppealModal.unfold,
})
