import React from 'react'
import { useUnit } from 'effector-react'

import { DocumentStatuses, SummaryFieldAlias } from '@/dal'
import { $documentStatus } from '@/features/estimate-correction/shared-model'

import { $summary, onFieldSubmit } from '../../model/private'
import {
  Col, DateCol, GridWrapper, Label, Value,
} from '../parts'

export const DateRow = React.memo(() => {
  const summary = useUnit($summary)
  const status = useUnit($documentStatus)

  const memoHandleAvansSubmit = React.useCallback((val: string) => {
    onFieldSubmit({ field: SummaryFieldAlias.DATE, value: val })
  }, [onFieldSubmit])

  if (!summary) return null

  const isDraft = status === DocumentStatuses.Draft

  const {
    date_end_work: { current, adjusted, approved, is_changeable: isChangeable },
  } = summary

  return (
    <GridWrapper>
      <Label>Срок окончания работ</Label>
      {current ? (
        <DateCol date={current} />
      ) : (
        <Col>
          <Value>
            Бессрочный
          </Value>
        </Col>
      )}
      <DateCol
        date={adjusted}
        isChangeble={isChangeable}
        onSubmit={memoHandleAvansSubmit}
      />
      {!isDraft &&
        (approved ? (
          <DateCol
            date={approved}
            isGrey={isDraft}
          />
        ) : (
          <Col />
        )
      )}
    </GridWrapper>
  )
})
