import React, { memo } from 'react'
import styled from 'styled-components'
import { combine } from 'effector'
import { useUnit } from 'effector-react'

import { ActGaGroupType } from '@/dal'
import { LoaderOverlay, NewButton } from '@/ui'
import { GaModalWrapper } from '@/features/act/view'
import { $isDraft } from '@/features/act/model'
import { worksModal } from '@/features/act/model/private'

import { Table, TableFilters } from '../containers'
import {
  getGaActTableFx,
  resetActTable,
  resetActTableFx,
  sendActWorkVolumeFx,
} from '../../model/private'

const $isPending = combine(resetActTableFx.pending, getGaActTableFx.pending, (...args) =>
  args.some((val) => val),
)

const Buttons = ({ canReset, isDisabled }: { canReset: boolean; isDisabled: boolean }) => {
  return (
    <>
      <NewButton
        label="Сбросить изменения"
        buttonType="grey"
        prefixIcon="refresh"
        isDisabled={!canReset || isDisabled}
        dataTestId="act-works-modal-reset-btn"
        onClick={resetActTable}
      />
      <NewButton
        label="Закрыть"
        width={200}
        isDisabled={isDisabled}
        dataTestId="act-works-modal-close-btn"
        onClick={worksModal.close}
      />
    </>
  )
}

export const WorksModal = memo(() => {
  const [isDraft, isOpen, type, isPending, isWorkPending] = useUnit([
    $isDraft,
    worksModal.$isOpen,
    worksModal.$meta,
    $isPending,
    sendActWorkVolumeFx.pending,
  ])

  if (!isOpen || !type) return null

  return (
    <GaModalWrapper
      buttonsSlot={<Buttons canReset={isDraft} isDisabled={isPending || isWorkPending} />}
      canClose={!isPending && !isWorkPending}
      onClose={worksModal.close}
    >
      <Wrapper>
        <TableFilters />
        <Table hasMorphology={type === ActGaGroupType.WithPlaces} minHeight="0" />
      </Wrapper>

      <LoaderOverlay position="fixed" isShown={isPending} />
    </GaModalWrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: calc(100dvw - 64px);
`
