import React from 'react'
import styled from 'styled-components'

import { palette, TextL, TextLLight } from '@/ui'

export const BottomHint = () => {
  return (
    <Wrapper>
      Выделите область скриншота мышкой. Для выхода из режима нажмите
      {' '}
      <BoldText>ESC</BoldText>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  ${TextLLight}
  position: fixed;
  bottom: 15%;
  left: 50%;
  z-index: 11;

  width: max-content;
  max-width: 100%;
  padding: 16px;
  border-radius: 12px;
  background-color: ${palette.grey20};
  user-select: none;
  pointer-events: none;
  transform: translateX(-50%);
`

const BoldText = styled.span`
  ${TextL}
`
