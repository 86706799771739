import { attach, combine } from 'effector'

import {
  changeEstimateLotWorkCommentReqFx,
  deleteEstimateLotFolderReqFx,
  deleteEstimateLotWorkReqFx,
  getEstimateLotFlatTableReqFx,
  getEstimateLotFolderNestedWorksReqFx,
  getEstimateLotTreeRowsReqFx,
  getEstimateLotTreeTableReqFx,
  getUnitsReqFx,
  LotTableItem,
  postLotWorkAttachmentsReqFx,
  UpdateLotWorkCommentPayload,
} from '@/dal'
import { createPagination } from '@/features/shared/createPagination'
import { createSingleEffect } from '@/lib/createSingleEffect'
import { getFlatTree } from '@/lib/tree/getFlatTree'
import { createUpdateTreeItemsFx } from '@/lib/tree'
import { createAttachmentsModalModel } from '@/features/factories/attach-modal'

import { d } from './domain'
import { Tree } from './types'
import { PAGINATION_LIMIT } from './const'

export const $isTreeTable = d.store(false)
export const setIsTreeTable = d.event<boolean>()

export const $isByConstructionElements = d.store(false)
export const setIsByConstructionElements = d.event<boolean>()

export const $isFirstGroupOpen = d.store(false)
export const setIsFirstGroupOpen = d.event<boolean>()

export const getTableData = d.event()
export const resetFilters = d.event()

export const $tableRoot = d.store<LotTableItem[] | null>(null)
export const setRootUpdate = d.event<LotTableItem[] | null>()

export const $tableTree = d.store<Tree>({})
export const setTreeUpdate = d.event<Tree>()

export const openFolder = d.event<LotTableItem['id']>()
export const closeFolder = d.event<LotTableItem['id']>()
export const closeFolders = d.event<Tree>()

export const $pendingFolderIds = d.store<LotTableItem['id'][]>([])

export const $totalWorkCount = d.store(0)

export const $workToDelete = d.store<LotTableItem | null>(null)
export const setWorkOrFolderToDelete = d.event<LotTableItem>()
export const resetWorkToDelete = d.event()

export const $nestedWorks = d.store<string[]>([])

export const resetTableData = d.event()

export const onLoadMore = d.event()

export const deleteLotWork = d.event<LotTableItem['id']>()
export const deleteLotFolder = d.event<LotTableItem['id']>()

export const $editingWork = d.store<UpdateLotWorkCommentPayload | null>(null)
export const setEditingWork = d.event<UpdateLotWorkCommentPayload>()
export const $updatingWorkIds = d.store<string[]>([])
export const changeLorWorkComment = d.event<UpdateLotWorkCommentPayload>()

export const $openedTableItem = d.store<LotTableItem | null>(null)
export const openTableItemFiles = d.event<LotTableItem>()

export const $flatItems = combine({
  root: $tableRoot,
  tree: $tableTree,
  isTree: $isTreeTable,
}, getFlatTree<LotTableItem>)

export const {
  $hasMore,
  initEffect,
  paginationEffect,
  $offset,
  decrementItems,
  incrementItems,
} = createPagination({
  fetchEffect: getEstimateLotFlatTableReqFx,
  limit: PAGINATION_LIMIT,
  domain: d,
})

export const $canSendFiles = d.store(Boolean('mock'))

export const postLotWorkAttachmentsFx = attach({
  effect: postLotWorkAttachmentsReqFx,
})

export const rowAttachmentsModel = createAttachmentsModalModel<LotTableItem['id']>({
  domain: d,
  sendFx: postLotWorkAttachmentsFx,
  $canSendFiles,
  $files: $openedTableItem.map((item) => item?.attachments || null),
})

export const {
  abortFx: abortInitFlatTabelFx,
  requestFx: initFlatTableFx,
} = createSingleEffect(initEffect)

export const {
  abortFx: abortPaginationFlatTabelFx,
  requestFx: paginateFlatTableFx,
} = createSingleEffect(paginationEffect)

export const getTreeTableFx = attach({
  effect: getEstimateLotTreeTableReqFx,
})

export const getTreeTableRowsFx = attach({
  effect: getEstimateLotTreeRowsReqFx,
})

export const getUnitsFx = attach({
  effect: getUnitsReqFx,
})

export const getFolderNestedWorksFx = attach({
  effect: getEstimateLotFolderNestedWorksReqFx,
})

export const deleteLotWorkFx = attach({
  effect: deleteEstimateLotWorkReqFx,
})

export const deleteLotFolderFx = attach({
  effect: deleteEstimateLotFolderReqFx,
})

export const changeLorWorkCommentFx = attach({
  effect: changeEstimateLotWorkCommentReqFx,
})

export const updateItemsFx = createUpdateTreeItemsFx<LotTableItem>()

export const $isTablePending = combine(
  getTreeTableFx.pending,
  initFlatTableFx.pending,
  paginateFlatTableFx.pending,
  (...args) => args.some((val) => val),
)
