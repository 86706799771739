import React from 'react'
import styled from 'styled-components'

import {
  HeadL, NewIconButton, palette, TextMLight,
} from '@/ui'

import { ScreenshotButton } from '../../../shared'

type Props = {
  title: string
  description: string | null
  hasScreenshotButton: boolean
  onOpen: () => void
  onClose: () => void
  onScreenshot: () => void
}

export const MinimizedModal = ({
  title, description, hasScreenshotButton, onOpen, onClose, onScreenshot,
}: Props) => {
  return (
    <Wrapper>
      {hasScreenshotButton && (
        <ScreenshotButton onClick={onScreenshot} />
      )}

      <Block>
        <Col>
          <Title>
            {title}
          </Title>
          {description && (
            <Description>
              {description}
            </Description>
          )}
        </Col>

        <Buttons>
          <NewIconButton
            borderRadius="50%"
            backgroundColor="white"
            icon="plusButton"
            color="grey60"
            hoverColor="grey80"
            hoverBackgroundColor="grey20"
            size={20}
            dataTestId=""
            padding="6px"
            onClick={onOpen}
          />
          <NewIconButton
            borderRadius="50%"
            backgroundColor="white"
            icon="cross"
            color="grey60"
            hoverColor="grey80"
            hoverBackgroundColor="grey20"
            size={20}
            dataTestId=""
            padding="6px"
            onClick={onClose}
          />
        </Buttons>
      </Block>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: fixed;
  right: 32px;
  bottom: 32px;
  z-index: 102;

  display: flex;
  align-items: center;
  gap: 8px;
`

const Block = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;

  padding: 20px;
  border: 1px solid ${palette.grey40};
  background-color: ${palette.white};
  border-radius: 12px;
  box-shadow: 0px 4px 20px 0px #1C1D1E26;
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const Title = styled.header`
  ${HeadL}
`

const Description = styled.p`
  ${TextMLight}
  color: ${palette.grey70};
`

const Buttons = styled.div`
  display: flex;
`
