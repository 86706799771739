import React from 'react'
import styled from 'styled-components'

import { DocumentProgress, DocumentStatuses } from '@/dal'
import { getActStatus, getProgressData } from '@/lib/progressBarHelpers'
import { Badge } from './Badge'
import { Icon } from './Icon'
import { RoundButton } from './RoundButton'
import { themeVar } from './theming'
import { MailToolTip } from './MailToolTip'
import { H3TitleCSS } from './Typography'

type Props = {
  status: DocumentStatuses
  showResponsible?: boolean
  progress: DocumentProgress | null
  onCopyToClipboard: (mail: string | null) => void
  isShowAdditionalRequest?: boolean
  onClickReloadButton: () => void
  dataTestId: string
  dialog?: {
    answer_required?: boolean
    onClick: () => void
  }
  hasTaskToResponsibleButton?: boolean
  onSetTaskToResponsible?: () => void
}

/** @deprecated */
export const DocumentProgressBar = ({
  status,
  progress,
  showResponsible,
  onCopyToClipboard,
  isShowAdditionalRequest,
  onClickReloadButton,
  dataTestId,
  dialog,
  hasTaskToResponsibleButton = true,
  onSetTaskToResponsible,
}: Props) => {
  const isSigned = status === DocumentStatuses.Signed

  const data = React.useMemo(
    () => getProgressData(progress, progress?.responsible || ''), [progress, progress?.responsible],
  )

  if (
    !progress
    || [
      DocumentStatuses.Draft,
      DocumentStatuses.Declined,
      DocumentStatuses.OnCancellation,
    ].includes(status)
  ) return null

  const isHideResponsible = isSigned || (showResponsible !== undefined && !showResponsible)

  return (
    <Wrapper>
      <TopRow>
        {![DocumentStatuses.Draft, DocumentStatuses.Declined].includes(
          status,
        ) && <Badge>{getActStatus(status, progress)}</Badge>}
        {isShowAdditionalRequest && <LegendRequest>{data.legend}</LegendRequest>}
      </TopRow>

      <ProgressWrapper>
        <Progress>
          <Filter completed={data.progresses[0]} />
          <Filter completed={data.progresses[1]} secondColor />
        </Progress>
        <Text>{`${data.progresses[0].toFixed(0)}%`}</Text>
        <IconWrapper onClick={onClickReloadButton} data-testid={dataTestId}>
          <Icon icon="reload" />
        </IconWrapper>
      </ProgressWrapper>

      <ElementWrapper>
        <LegendItem>
          <LegendIcon color="main" />
          Основной процесс согласования
        </LegendItem>
        <LegendItem>
          <LegendIcon color="second" />
          Доп. запросы
        </LegendItem>
      </ElementWrapper>

      <RowWrapper>
        {dialog && (
          <AdditionRequestWrapper>
            <RoundButton
              onClick={dialog.onClick}
              icon="act-dialog"
              iconSizePx={12}
              style={dialog.answer_required ? 'actHasMessage' : 'actNoMessage'}
              iconPosition="left"
              dataTestId="button-administrative-contract-open-dialog"
            >
              Доп. запрос
            </RoundButton>
          </AdditionRequestWrapper>
        )}

        {!isHideResponsible && (
          <ResponsibleWrapper>
            <IconResponsibleWrapper>
              <Icon icon="responsible-names" />
            </IconResponsibleWrapper>
            <ResponsibleTitle>Согласующий:</ResponsibleTitle>
            <ResponsibleList>
              {data.responsible || 'Ожидает назначения'}
              <MailToolTip
                copyDataTestId="progress-bar-mail-button"
                onCopy={() => onCopyToClipboard(progress.responsible_mail)}
                mail={progress.responsible_mail}
              />
            </ResponsibleList>
          </ResponsibleWrapper>
        )}
      </RowWrapper>
      {!isHideResponsible
        && progress.responsible === null
        && onSetTaskToResponsible !== undefined
        && (
          <CommonResponsible>
            <CommonCredential>
              <CommonTitle>Ответственный по договору:</CommonTitle>
              <CommonPerson>{progress.common_responsible || 'Ожидает назначения'}</CommonPerson>
            </CommonCredential>
            {hasTaskToResponsibleButton && (
              <CommonButtonWrapper>
                <RoundButton
                  onClick={onSetTaskToResponsible}
                  style="primary"
                  dataTestId="button-progress-bar-open-common-responsible"
                >
                  Запрос ответственному
                </RoundButton>
              </CommonButtonWrapper>
            )}
          </CommonResponsible>
        )}
    </Wrapper>
  )
}

const CommonButtonWrapper = styled.div`
  
`

const CommonPerson = styled.span`
  ${H3TitleCSS}
`

const CommonTitle = styled.span`
  ${H3TitleCSS}
  text-transform: uppercase;
  color: ${themeVar('textGray')};
`

const CommonCredential = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

const CommonResponsible = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 40px;
  margin-top: 20px;
`

const IconResponsibleWrapper = styled.div`
  width: 13px;
`

const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const AdditionRequestWrapper = styled.span`
  width: 117px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
`

const Text = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: ${themeVar('textGray')};
  margin-left: 6px;
  margin-right: 8px;
`

const Progress = styled.div`
  display: flex;
  justify-content: flex-start;
  max-width: 428px;
  width: 100%;
  height: 8px;
  background-color: ${themeVar('progressBackgroundColor')};
  border-radius: 2px;
  border: 0;
`

type FilterProps = {
  completed: number
  secondColor?: boolean
}

const Filter = styled.div<FilterProps>`
  height: 100%;
  width: ${({ completed }) => completed}%;
  background-color: ${({ secondColor }) => (secondColor
    ? themeVar('progressRequest')
    : themeVar('progressActiveColor'))};
  border-radius: 2px;
  border: 0;
`

const LegendRequest = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: ${themeVar('text')};
  background-color: ${themeVar('subsystemBorder')};
  padding: 3px 6px;
  border-radius: 100px;
`

const ProgressWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`

const TopRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const ElementWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  margin-bottom: 4px;
`

const LegendItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${themeVar('textGray')};
`

type LegendIconProps = {
  color: string
}

const LegendIcon = styled.div<LegendIconProps>`
  width: 8px;
  height: 8px;
  border-radius: 2px;
  margin-right: 10px;

  background-color: ${({ color }) => (color === 'main'
    ? themeVar('progressActiveColor')
    : themeVar('progressRequest'))};
`

const IconWrapper = styled.span`
  cursor: pointer;
`

const ResponsibleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`

const ResponsibleTitle = styled.span`
  font-weight: 300;
  font-size: 12px;
  line-height: 12px;
  color: ${themeVar('text')};
`

const ResponsibleList = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: ${themeVar('text')};
  white-space: nowrap;
`
