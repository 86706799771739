import React from 'react'
import styled from 'styled-components'

import { EditableValue, ToolTipV2 } from '@/ui'
import { useTooltip } from '@/lib/hooks'

import { Cell } from './Cell'

type Props = {
  value: string
  tooltipText?: string
  onSubmit: (val: string) => void
}

export const EditableCell = ({ value, tooltipText, onSubmit }: Props) => {
  const {
    floatingStyles,
    getFloatingProps,
    getReferenceProps,
    isOpen,
    refs,
  } = useTooltip({ tooltipOffset: 3 })

  return (
    <Cell>
      <div ref={refs.setReference} {...getReferenceProps()}>
        <EditableValue value={value} color="accent100" hasAbsoluteIcon onSubmit={onSubmit} />
      </div>
      {tooltipText && (
        <ToolTipV2
          floatingStyles={floatingStyles}
          isOpen={isOpen}
          refs={refs}
          getFloatingProps={getFloatingProps}
          size="M"
        >
          <TooltipText>{tooltipText}</TooltipText>
        </ToolTipV2>
      )}
    </Cell>
  )
}

const TooltipText = styled.p`
  width: 295px;
  text-align: center;
`
