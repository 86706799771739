import React, { ReactNode, useEffect } from 'react'
import styled, { css } from 'styled-components'

import { NewIconButton, SquareButton } from '../buttons'
import { palette } from '../palette'
import { FlexScrollbar } from '../components/FlexScrollbar'
import { IconName2 } from '../icons'

type Props = {
  children: ReactNode
  dataTestId?: string
  zIndex?: number
  isHidden?: boolean
  hasCloseButton?: boolean
  borderRadius?: number
  canClose?: boolean
  closeModal: () => void
  onGoBack?: () => void
  onMinimize?: () => void
}

type ModalButtonProps = {
  icon: IconName2
  dataTestId: string
  isDisabled: boolean
  onClick: () => void
}

const ModalButton = ({ icon, isDisabled, dataTestId, onClick }: ModalButtonProps) => (
  <NewIconButton
    borderRadius="50%"
    backgroundColor="white"
    icon={icon}
    color="grey60"
    hoverColor="grey80"
    hoverBackgroundColor="grey20"
    size={20}
    disabled={isDisabled}
    dataTestId={dataTestId}
    padding={6}
    onClick={onClick}
  />
)

export const ModalLayoutV2 = ({
  children,
  dataTestId,
  zIndex,
  isHidden,
  hasCloseButton = true,
  borderRadius = 20,
  canClose = true,
  closeModal,
  onGoBack,
  onMinimize,
}: Props) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.removeProperty('overflow')
    }
  }, [])

  return (
    <ModalOverlay
      $zIndex={zIndex}
      $isHidden={isHidden}
      data-testid={dataTestId}
    >
      <ModalWrapper $borderRadius={borderRadius}>
        <FlexScrollbar style={{ borderRadius }}>
          {children}
          <Buttons>
            {onMinimize && (
              <ModalButton
                icon="minimize"
                isDisabled={!canClose}
                dataTestId="minimize-modal-btn"
                onClick={onMinimize}
              />
            )}
            {hasCloseButton && (
              <ModalButton
                icon="cross"
                isDisabled={!canClose}
                dataTestId="close-modal-btn"
                onClick={closeModal}
              />
            )}
          </Buttons>
        </FlexScrollbar>

        {onGoBack && (
          <GoBack>
            <SquareButton
              icon="arrowLeftM"
              buttonType="grey"
              dataTestId="go-back-modal-btn"
              onClick={onGoBack}
            />
          </GoBack>
        )}
      </ModalWrapper>
    </ModalOverlay>
  )
}

const Buttons = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 3;
  display: flex;
`

type ModalOverlayProps = {
  $zIndex: Props['zIndex']
  $isHidden: Props['isHidden']
}

const ModalOverlay = styled.div<ModalOverlayProps>`
  position: fixed;
  inset: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(4px);
  z-index: ${({ $zIndex }) => $zIndex || 10};

  ${({ $isHidden }) => $isHidden && css`
    display: none;
  `}
`

const ModalWrapper = styled.div<{ $borderRadius: number }>`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  max-height: 100%;
  border-radius: ${({ $borderRadius }) => $borderRadius}px;
  background-color: ${palette.white};
`

const GoBack = styled.div`
  position: absolute;
  top: 0;
  right: calc(100% + 12px)
`
