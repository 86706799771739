import { useUnit } from 'effector-react'
import React, { memo } from 'react'
import styled from 'styled-components'

import {
  HeadXL,
  ModalLayoutV2,
  NewButton,
  TextLLight,
} from '@/ui'
import { successfulCancelModal } from '@/features/act/model/header.private'

export const SuccessfulCancelModal = memo(() => {
  const isOpen = useUnit(successfulCancelModal.$isOpen)

  if (!isOpen) return null

  const handleClose = successfulCancelModal.close

  return (
    <ModalLayoutV2
      closeModal={handleClose}
      dataTestId="act-successful-cancel-modal"
    >
      <Container>
        <Title>Согласование выполнения отменено</Title>
        <Body>Свяжитесь с представителем ФСК, ответственным по выполнению</Body>
        <NewButton
          label="Ок"
          dataTestId="act-successful-cancel-modal-close-btn"
          isFill
          onClick={handleClose}
        />
      </Container>

    </ModalLayoutV2>
  )
})

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px;
  max-width: 500px;
`

const Title = styled.div`
  ${HeadXL}
`

const Body = styled.div`
  ${TextLLight}
  white-space: pre-line;
`
