import { sample } from 'effector'

import { showToast } from '@/features/toast-service/model'
import { NotificationType } from '@/ui'

import { showMaxFileCountError, showTooLargeFileError } from './public'

sample({
  clock: showTooLargeFileError,
  fn: (fileNames) => {
    const fileString = fileNames.length > 1 ? 'Файлы превышают' : 'Файл превышает'

    return {
      content: `${fileString} максимальный размер: ${fileNames.join(', ')}`,
      icon: NotificationType.Alert,
    }
  },
  target: showToast,
})

sample({
  clock: showMaxFileCountError,
  fn: (count) => ({
    content: `Можно приложить не более ${count} файлов`,
    icon: NotificationType.Warning,
  }),
  target: showToast,
})
