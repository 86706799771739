import React, { memo } from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import {
  HeadL, NewTextButton, palette, TextMLight,
} from '@/ui'
import { $canEdit } from '@/features/estimate-lot/model'
import { $isActionPending } from '@/features/estimate-lot/model/header.private'
import {
  $hasFreeBuildings,
  $isNewRowAdded,
  $morphologyRows,
  setIsNewRowAdded,
} from '@/features/estimate-lot/model/morphology.private'

import { MorphologyRow } from './MorphologyRow'

export const LotMorphology = memo(() => {
  const [rows, canEdit, hasEmptyObject, hasFreeBuildings, isPending] = useUnit([
    $morphologyRows,
    $canEdit,
    $isNewRowAdded,
    $hasFreeBuildings,
    $isActionPending,
  ])

  const isDisabled = !canEdit || isPending

  return (
    <Wrapper>
      <Title>
        Структура элементов объекта строительства
      </Title>

      {rows.map((item) => (
        <MorphologyRow
          key={item.object ? `${item.object}-${item.building}` : 'empty'}
          {...item}
        />
      ))}

      <AddObjectButtonWrapper>
        <NewTextButton
          label="Добавить объект"
          iconSize={16}
          prefixIcon="plusWithCircle"
          textStyle="M"
          isDisabled={isDisabled || hasEmptyObject || !hasFreeBuildings}
          dataTestId="lot-add-object-btn"
          onClick={() => setIsNewRowAdded(true)}
        />
      </AddObjectButtonWrapper>

      <BottomText>
        Если вы не нашли объект — направьте
        {' '}
        <NewTextButton
          url="mailto:mock@a.a"
          label="запрос на добавление"
          textStyle="ML"
          dataTestId=""
          isInline
          hasUnderline
        />
        .
      </BottomText>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  padding: 20px;
  border-radius: 20px;
  background-color: ${palette.white};
`

const Title = styled.header`
  ${HeadL}
`

const AddObjectButtonWrapper = styled.div`
  padding: 7px 0;
`

const BottomText = styled.div`
  ${TextMLight}
`
