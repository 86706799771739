import React from 'react'
import styled, { css } from 'styled-components'

import { getHighlightedText } from '@/lib/getHighlightedText'

import { TABLE_BORDER_STYLE } from './const'
import { FolderComponent } from './FolderComponent'
import { OverflowText } from '../OverflowText'
import { TextMLight } from '../../typography'
import { palette } from '../../palette'
import { DefaultButton } from '../../buttons'

// временные изменения отступов мимо дизайна
const DEFAULT_ROW_PADDING = 40
const NESTED_ROW_DEFAULT_PADDING = 57
const DEFAULT_FOLDER_PADDING = 20
const STEP = 8

type Props = {
  isTree: boolean
  isFolder: boolean
  depth: number
  hasChildren: boolean
  isExpanded: boolean
  name: string
  hierarchyCode?: string
  project?: string
  searchValue?: string
  hasRightDivider?: boolean
  isAlignCenter?: boolean
  onToggleExpand: () => void
}

export const FirstTableCell = ({
  isFolder,
  isTree,
  depth,
  hasChildren,
  isExpanded,
  name,
  hierarchyCode,
  project,
  searchValue,
  hasRightDivider,
  isAlignCenter,
  onToggleExpand,
}: Props) => {
  const leftPadding = React.useMemo(() => {
    if (!isTree) return DEFAULT_ROW_PADDING
    let result = isFolder
      ? DEFAULT_FOLDER_PADDING : NESTED_ROW_DEFAULT_PADDING
    result += depth * STEP
    return result
  }, [isFolder, isTree, depth])

  const highlightedName = getHighlightedText(name, searchValue, palette.yellow30)

  const highlightedProject = project && getHighlightedText(project, searchValue, palette.yellow30)

  return (
    <Wrapper
      leftPadding={leftPadding}
      hasRightDivider={hasRightDivider}
      isAlignCenter={isAlignCenter}
      onClick={onToggleExpand}
    >
      <FolderComponent
        hasChildren={hasChildren}
        isExpanded={isExpanded}
        isFolder={isFolder}
      />
      <Info>
        <OverflowText
          maxTooltipWidth={350}
          maxLines={2}
        >
          {highlightedName}
        </OverflowText>

        {(hierarchyCode || highlightedProject) && (
          <GreyText>
            {highlightedProject && (
              <Project>
                <OverflowText
                  maxTooltipWidth={250}
                  maxLines={1}
                >
                  {highlightedProject}
                </OverflowText>
              </Project>
            )}
            {hierarchyCode}
          </GreyText>
        )}

      </Info>
    </Wrapper>
  )
}

type WrapperProps = {
  leftPadding: number
  hasRightDivider?: boolean
  isAlignCenter?: boolean
}

const Wrapper = styled(DefaultButton)<WrapperProps>`
  padding: 12px 20px 12px 38px;
  display: flex;
  gap: 10px;
  border-right: ${TABLE_BORDER_STYLE};
  width: 100%;
  height: 100%;
  text-align: left;

  ${({ leftPadding }) => css`
    padding-left: ${leftPadding}px;
  `};

  ${({ hasRightDivider }) => hasRightDivider && css`
    border-width: 4px;
  `}

  ${({ isAlignCenter }) => isAlignCenter && css`
    align-items: center;
  `}
`

const Info = styled.div`
  display: flex;
  gap: 2px;
  position: relative;
  flex-direction: column;
  flex-grow: 1;
  ${TextMLight}
`

const GreyText = styled.div`
  ${TextMLight}
  color: ${palette.grey70};
`

const Project = styled.div`
  margin-bottom: 6px;
`
