import React from 'react'
import { useUnit } from 'effector-react'
import { useForm } from 'effector-forms'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

import {
  FormModalWrapper,
  InputField,
  NotificationItem,
  NotificationType,
  NumberInputField,
} from '@/ui'
import { RouterPaths } from '@/features/app/model'
import { noop } from '@/lib/noop'

import { GridSpan, Success } from '../../../view'
import {
  $isCreateContragentOpen,
  $isPreview,
  $isShowSuccess,
  closeCreateContragent,
  createContragentForm,
  createContragentFx,
} from '../../../model/create-contragent.private'

export const CreateContragentModal = React.memo(() => {
  const isOpen = useUnit($isCreateContragentOpen)
  const isPendind = useUnit(createContragentFx.pending)
  const { fields, isValid, submit } = useForm(createContragentForm)
  const isPreview = useUnit($isPreview)
  const isShowSuccess = useUnit($isShowSuccess)

  if (!isOpen) return null
  if (isShowSuccess) return <Success onClose={closeCreateContragent} />

  const {
    account,
    bic,
    inn,
    kpp,
    name,
    ogrn,
  } = fields

  return (
    <FormModalWrapper
      closeModal={closeCreateContragent}
      dataTestId="create-contragent"
      onSubmit={submit}
      title={isPreview ? 'Данные контрагента' : 'Создание нового контрагента'}
      submitLabel="Сохранить"
      cancelLabel="Отменить"
      isDisableSubmit={!isValid}
      isPending={isPendind}
      width="500px"
      showButtons={!isPreview}
    >
      <Wrapper>
        {!isPreview && (
          <NotificationItem
            type={NotificationType.Info}
            body={(
              <>
                Контрагент по указанному ИНН отсутствует в системе,
                чтобы создать его, проверьте информацию ниже и заполните
                банковские реквизиты. Если информация по контрагенту указана неверно,
                обратитесь в
                {' '}
                {/* TODO SUPPORT поменялась ссылка */}
                <NavLink target="_blank" to={RouterPaths.Support}>техническую поддержку</NavLink>
                .
                Создание нового контрагента займет не более 30 минут.
              </>
          )}
          />
        )}
        <FormBody>
          <GridSpan span={3}>
            <InputField
              dataTestId="name"
              label="Наименование контрагента"
              onChange={noop}
              value={name.value}
              disabled
              size="L"
            />
          </GridSpan>

          <InputField
            dataTestId="inn"
            label="ИНН"
            onChange={noop}
            value={inn.value}
            disabled
            size="L"
          />

          <InputField
            dataTestId="kpp"
            label="КПП"
            onChange={noop}
            value={kpp.value}
            disabled
            size="L"
          />

          <InputField
            dataTestId="ogrn"
            label="ОГРН"
            onChange={noop}
            value={ogrn.value}
            disabled
            size="L"
          />

          <GridSpan span={3}>
            <NumberInputField
              dataTestId="create-contragent-bic"
              label="БИК банка"
              onChange={bic.onChange}
              value={bic.value}
              error={bic.firstError?.errorText}
              placeholder="000000000"
              format="#########"
              mask="_"
              decimalScale={0}
              isRequired={!isPreview}
              allowLeadingZeros
              disabled={isPreview}
              size="L"
            />
          </GridSpan>

          <GridSpan span={3}>
            <NumberInputField
              dataTestId="create-contragent-number"
              label="Расчетный счет"
              onChange={account.onChange}
              value={account.value}
              error={account.firstError?.errorText}
              placeholder="0000 0000 0000 0000 0000"
              format="#### #### #### #### ####"
              allowLeadingZeros
              mask="_"
              decimalScale={0}
              isRequired={!isPreview}
              disabled={isPreview}
              size="L"
            />
          </GridSpan>

        </FormBody>
      </Wrapper>
    </FormModalWrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const FormBody = styled.div`
  display: grid;
  gap: 16px;
  margin-bottom: 24px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
`
