import React from 'react'
import styled from 'styled-components'
import { IconButton } from './buttons/iconButton'

import { Icon, IconName } from './Icon'
import { ThemedStyledProps, themeVar } from './theming'

type Props = {
  value: string
  onChange: (s: string) => void
  onClear?: () => void
  onClickSearchButton: () => void
  onFocus?: () => void
  icon?: IconName
  placeholder?: string
  dataTestId: string
}

export const UiSearchInput = ({
  value,
  onChange,
  onClear,
  onClickSearchButton,
  icon,
  placeholder,
  dataTestId,
  onFocus,
}: Props) => {
  const inputRef = React.useRef<HTMLInputElement>(null)

  const handleFocus = React.useCallback(() => {
    if (onFocus) {
      onFocus()
    }
    inputRef.current?.focus()
  }, [inputRef])

  const handleKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') onClickSearchButton()
    }, [])

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value)
    }, [])

  return (
    <Container>
      <Wrapper onClick={handleFocus}>
        {icon && (
          <IconWrapper>
            <Icon icon={icon} />
          </IconWrapper>
        )}
        <Input
          ref={inputRef}
          value={value}
          placeholder={placeholder}
          onChange={handleChange}
          data-testid={dataTestId}
          onKeyDown={handleKeyDown}
        />

        {value.length > 0 && onClear && (
          <ClearWrapper onClick={onClear}>
            <Icon icon="cross" />
          </ClearWrapper>
        )}
      </Wrapper>

      <IconButton
        onClick={onClickSearchButton}
        dataTestId="button-simple-search"
        icon="action-search"
        borderColor="gray4"
      />
    </Container>
  )
}

const Container = styled.div<ThemedStyledProps>`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`

const Wrapper = styled.div`
  cursor: text;
  padding: 7px;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;

  border: 1px solid ${themeVar('line')};
  border-right: none;
`

const IconWrapper = styled.div`
  margin-right: 8px;
`

const ClearWrapper = styled.div`
  margin-left: 8px;
  cursor: pointer;
  min-width: 28px;
  height: 28px;
  color: ${themeVar('gray4')};
  transition: color 0.15 linear;

  &:hover {
    color: ${themeVar('gray8')};
  }
`

const Input = styled.input`
  background: none;
  height: 24px;
  border: none;
  font-weight: 300;
  font-size: 14px;
  font-family: ${themeVar('baseFont')};
  width: 100%;
  &::placeholder {
    color: ${themeVar('baseInputPlaceholderColor')};
  }
  &:focus {
    outline: none;
  }
`
