import React from 'react'
import styled, { css } from 'styled-components'

import { DefaultButton } from '@/ui'
import { Cell } from '@/features/act/view'
import { numberWithSpaces } from '@/lib/number-represent'

import { RejectionComment } from './RejectionComment'

type Props = {
  name: string
  /** float */
  price: string
  comment: string | null
  isClosingInCurrPeriod: boolean
  isClosedInPrevPeriod: boolean
  isRejected: boolean
  isDisabled: boolean
  onToggle: () => void
}

const getColorProps = (
  isClosingInCurrPeriod: boolean,
  isClosedInPrevPeriod: boolean,
  isRejected: boolean,
) => {
  if (isRejected) {
    return {
      borderColor: 'red100',
      bgColor: 'red10',
    } as const
  }
  if (isClosingInCurrPeriod) {
    return {
      borderColor: 'lightGreen100',
      bgColor: 'lightGreen10',
    } as const
  }
  if (isClosedInPrevPeriod) {
    return {
      bgColor: 'grey20',
    } as const
  }
  return {}
}

export const MorphologyEndNode = ({
  name,
  price,
  comment,
  isClosingInCurrPeriod,
  isClosedInPrevPeriod,
  isRejected,
  isDisabled,
  onToggle,
}: Props) => {
  const colorProps = getColorProps(isClosingInCurrPeriod, isClosedInPrevPeriod, isRejected)

  return (
    <Wrapper
      $isSelected={isClosingInCurrPeriod}
      disabled={isDisabled || isClosedInPrevPeriod}
      onClick={onToggle}
    >
      <Cell {...colorProps} borderPosition="bottom">
        <UpperCellContent>
          {comment && <RejectionComment comment={comment} />}
          <Name>{name}</Name>
        </UpperCellContent>
      </Cell>
      <Cell {...colorProps}>{numberWithSpaces(price)}</Cell>
    </Wrapper>
  )
}

const Wrapper = styled(DefaultButton)<{ $isSelected: boolean }>`
  width: 105px;

  &:disabled {
    cursor: initial;
  }

  ${({ $isSelected, disabled }) =>
    !$isSelected &&
    !disabled &&
    css`
      @media (pointer: fine) {
        &:hover .cell {
          --background-color: #e8f9f180;
        }
      }
    `}
`

const UpperCellContent = styled.div`
  display: flex;
  width: 100%;
`


const Name = styled.p`
  flex-grow: 1;
`
