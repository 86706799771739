import { attach, combine } from 'effector'
import { createForm } from 'effector-forms'

import { cancelActApproveReqFx, sendActToApproveReqFx } from '@/dal'
import { createModal } from '@/features/factories'
import { rules } from '@/lib/form-validators'

import { $documentInfo } from './public'
import { d } from './domain'

export const MAX_COMMENT_LENGTH = 1000

export const $headDocumentInfo = $documentInfo.map((info) => (info ? {
  name: info.name,
  lastUpdate: info.last_update,
  feature: info.features,
  rejectTime: info.reject_time,
  instructionLink: info.instruction_link,
  actType: info.act_type,
  isGa: info.is_act_group_used,
} : null))

export const onSendToApprove = d.event<void>()

export const onDeleteButton = d.event<void>()
export const onCopy = d.event<void>()

export const $isCancelApprovingModalOpen = d.store<boolean>(false)
export const openCancelApprovingModal = d.event<void>()
export const closeCancelApprovingModal = d.event<void>()

export const successfulCancelModal = createModal()

export const $approveTimeoutId = d.store<NodeJS.Timeout|null>(null)

export const onTimeLeft = d.event<void>()
export const setApproveTimeoutFx = d.effect<{ endTime: string }, NodeJS.Timeout, Error>()
export const clearApproveTimeoutFx = d.effect<NodeJS.Timeout, void, Error>()

export const cancelApprovingForm = createForm({
  validateOn: ['change'],
  fields: {
    comment: {
      init: '',
      rules: [rules.required(), rules.maxLength(MAX_COMMENT_LENGTH)],
    },
  },
})

export const sendActToApproveFx = attach({
  effect: sendActToApproveReqFx,
})

export const cancelActApproveFx = attach({
  effect: cancelActApproveReqFx,
})

export const $isActionPending = combine(
  [sendActToApproveFx.pending, cancelActApproveFx.pending],
  (values) => {
    for (const value of values) {
      if (value) return true
    }
    return false
  },
)
