import { d } from './domain'
import { CommentForm } from './types'

export const $hasSelectedAppeal = d.store(false)

export const closeAppealChat = d.event()
export const minimizeAppealChat = d.event()
export const onAppealChatClosed = d.event()

export const setIsAppealChatHidden = d.event()

export const $commentFormFiles = d.store<File[]>([])
export const setCommentForm = d.event<Partial<CommentForm>>()
