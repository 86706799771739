import {
  attach,
  combine,
  Domain,
  Effect,
  EventCallable,
  Store,
} from 'effector'

import {
  PaginationRequestParams,
  PaginationType,
} from '@/dal'
import { root } from '@/root-domain'

type PaginationEffectPayload<P> = P & { pageCount?: number }

type PaginationOptions<P, R, E> = {
  domain?: Domain
  fetchEffect: Effect<PaginationRequestParams & P, PaginationType<R>, E>
  limit?: number
}

type PaginationReturnType<P, R, E> = {
  $hasMore: Store<boolean>,
  paginationEffect: Effect<PaginationEffectPayload<P>, PaginationType<R>, E>,
  $offset: Store<number>,
  $total: Store<number>,
  initEffect: Effect<P, PaginationType<R>, E>
  loadNewItemsFx: Effect<PaginationRequestParams & P, PaginationType<R>, E>
  incrementItems: EventCallable<void>
  decrementItems: EventCallable<void>
}

export const createPagination = <P, R, E extends Error>(
  {
    domain,
    fetchEffect,
    limit = 10,
  }: PaginationOptions<P, R, E>): PaginationReturnType<P, R, E> => {
  const d = domain ?? root
  const $offset = d.store(0)
  const $total = d.store(0)
  const incrementItems = d.event()
  const decrementItems = d.event()

  const $hasMore = combine(
    $total,
    $offset,
    (t, f) => t > f,
  )

  // eslint-disable-next-line effector/enforce-effect-naming-convention
  const initEffect = attach({
    effect: fetchEffect,
    source: $offset,
    mapParams: (params: P) => ({
      ...params,
      offset: 0,
      limit,
    }),
  })

  // eslint-disable-next-line effector/enforce-effect-naming-convention
  const paginationEffect = attach({
    effect: fetchEffect,
    source: $offset,
    mapParams: (params: PaginationEffectPayload<P>, offset: number) => ({
      ...params,
      offset,
      limit: params.pageCount ? params.pageCount * limit : limit,
    }),
  })

  const loadNewItemsFx = attach({
    effect: fetchEffect,
  })

  $total
    .on(paginationEffect.doneData, (_, data) => data.total)
    .on(initEffect.doneData, (_, data) => data.total)
    .on(loadNewItemsFx.doneData, (_, data) => data.total)
    .on(incrementItems, (total) => total + 1)
    .on(decrementItems, (total) => (total ? total - 1 : 0))
    .reset(initEffect)

  $offset
    .on(paginationEffect.done, (currentOffset, { params: { pageCount = 1 } }) => (
      currentOffset + limit * pageCount
    ))
    .on(initEffect.done, (currentOffset) => currentOffset + limit)
    .on(loadNewItemsFx.done, (currentOffset, { params }) => currentOffset + (params.limit || 0))
    .on(incrementItems, (currentOffset) => currentOffset + 1)
    .on(decrementItems, (currentOffset) => (currentOffset ? currentOffset - 1 : 0))
    .reset(initEffect)

  return {
    $hasMore,
    paginationEffect,
    $offset,
    $total,
    initEffect,
    loadNewItemsFx,
    incrementItems,
    decrementItems,
  }
}

export type Pagination = ReturnType<typeof createPagination>
