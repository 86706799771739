import React from 'react'
import { useUnit } from 'effector-react'
import styled from 'styled-components'

import { NewButton, SimpleLoader, NewDivider } from '@/ui'

import { useShortItems } from '@/lib/hooks/useShortItems'
import { CommentItem } from './parts'
import { CommentsModel } from '../createCommentsModel'

type Params = {
  $comments: CommentsModel['$comments']
  getCommentsFx: CommentsModel['getCommentsFx']
}

export const createCommentsList = ({ $comments, getCommentsFx }: Params) => React.memo(() => {
  const comments = useUnit($comments)
  const isLoading = useUnit(getCommentsFx.pending)

  const {
    setIsExpand,
    visibleItems,
    isExpand,
  } = useShortItems({ source: comments, shortCount: 3 })

    return isLoading ? (
      <CommentsLoader>
        <SimpleLoader />
      </CommentsLoader>
    ) : (
      <CommentWrapper>
        {visibleItems?.map((item, index) => (
          <React.Fragment key={item.id}>
            <Item>
              <CommentItem
                body={item.text}
                date={item.written_at}
                author={item.author}
                isReply={false}
              />

              {item.replies.map((reply) => (
                <CommentItem
                  key={reply.id}
                  body={reply.text}
                  date={reply.written_at}
                  author={reply.author}
                  isReply
                />
              ))}
            </Item>

            {Boolean(index + 1 !== visibleItems.length) && <NewDivider />}
          </React.Fragment>
        ))}
        {(comments?.length || 0) > 3 && !isExpand && (
          <NewButton
            label="Показать все комментарии"
            isFill
            dataTestId="expand-comments"
            onClick={() => setIsExpand(true)}
            buttonType="grey"
          />
        )}
      </CommentWrapper>
    )
  })

const CommentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const CommentsLoader = styled.div`
  display: flex;
  justify-content: center;
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`
