import styled, { css } from 'styled-components'

import { TextM, TextMLight, palette } from '@/ui'

export const Col = styled.div`
  display: flex;
  flex-direction: column;
`

type GridWrapperProps = {
  $isTotal?: boolean
}

export const GridWrapper = styled.div<GridWrapperProps>`
  display: grid;
  grid-template-columns: 100px repeat(auto-fit, minmax(0, 1fr));
  gap: 0px 20px;
  
  ${({ $isTotal }) => $isTotal && css`
    background-color: ${palette.grey10};
  `}
`

export const Label = styled.span<{ $isBold?: boolean }>`
  ${({ $isBold }) => ($isBold ? TextM : TextMLight)};
  &:not(:first-child) {
    text-align: right;
  }
  color: ${palette.grey70};
`

type ValueProps = {
  $isBold?: boolean
  $isApproved?: boolean
  $isGrey?: boolean
}

export const Value = styled.span<ValueProps>`
  ${({ $isBold }) => ($isBold ? TextM : TextMLight)};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  ${({ $isApproved }) => $isApproved && css`
    color: ${palette.accent100};
  `}

  ${({ $isGrey }) => $isGrey && css`
    color: ${palette.grey60};
  `}
`
