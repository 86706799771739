import React, { useEffect } from 'react'

import { numberWithSpaces } from '@/lib/number-represent'

import { CellNumberInput } from './CellNumberInput'
import { CellDateInput } from './CellDateInput'
import { PaletteColor } from '../../palette'

type Props = {
  value: string
  defaultSubmitValue?: string
  suffix?: string
  cellType?: 'date' | 'currency'
  placeholder?: string
  color?: PaletteColor
  hasAbsoluteIcon?: boolean
  onSubmit: (val: string) => void
  onSanitizeChange?: (val: string) => string
}

export const EditableValue = ({
  value,
  defaultSubmitValue,
  suffix,
  cellType = 'currency',
  placeholder,
  color,
  hasAbsoluteIcon,
  onSubmit,
  onSanitizeChange,
}: Props) => {
  const [inputVal, setInputVal] = React.useState(value)

  const handleInputChange = (newVal: string) => {
    let value = newVal
    if (onSanitizeChange) {
      value = onSanitizeChange(newVal)
    }
    setInputVal(value)
    if (cellType === 'date') {
      onSubmit(newVal)
    }
  }

  const handleBlur = () => {
    if (inputVal === value) return
    if (!inputVal && defaultSubmitValue) return onSubmit(defaultSubmitValue)
    onSubmit(inputVal)
  }

  const handleFocus = () => {
    setInputVal(value)
  }

  useEffect(() => {
    setInputVal(value)
  }, [value])

  return (
    <>
      {cellType !== 'date' && (
        <CellNumberInput
          onFocus={handleFocus}
          onBlur={handleBlur}
          displayValue={numberWithSpaces(value)}
          onChange={handleInputChange}
          inputValue={inputVal}
          suffix={suffix}
          textAlign="right"
          placeholder={placeholder}
          decimalScale={2}
          decimalSeparator="."
          hasAbsoluteIcon={hasAbsoluteIcon}
          color={color}
          isCentered
        />
      )}
      {cellType === 'date' && (
        <CellDateInput
          dataTestId="summary-date-input"
          onChange={handleInputChange}
          inputValue={inputVal}
          textAlign="right"
        />
      )}
    </>
  )
}
