import styled from 'styled-components'

import { GA_MODAL_PADDING } from '@/features/act/view'

export const StickyRow = styled.div`
  position: sticky;
  left: 0;
  z-index: 2;
  display: flex;
  width: calc(100dvw - ${GA_MODAL_PADDING * 2}px);
`
