import React, { useMemo } from 'react'
import { EventCallable, Store } from 'effector'
import { useUnit } from 'effector-react'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

import { FileDTO } from '@/dal'
import {
  DropArea,
  HeadL,
  HeadXL,
  ModalLayoutV2,
  NewButton,
  NewFileItem,
  NotificationItem,
  NotificationItemProps,
  TextMLight,
  palette,
} from '@/ui'
import { useOnChangeLocation } from '@/lib/useOnChangeLocation'
import { noop } from '@/lib/noop'

type Params<T> = {
  close: EventCallable<void>
  $entityId: Store<T | null>
  $isSendAttachmentsPending: Store<boolean>,
  $files: Store<File[]>
  $visibleFiles: Store<FileDTO[] | null>
  $filesCount: Store<number>
  $isNotificationShown: Store<boolean>
  $canSendFiles: Store<boolean>
  closeNotification: EventCallable<void>
  onChangeFiles: EventCallable<File[]>
  markToDelete: EventCallable<FileDTO['id']>
  sendFiles: EventCallable<void>
  $filesToDelete: Store<FileDTO['id'][]>
}

export const createAttachmentsModalView = <T, >({
  $entityId,
  $isSendAttachmentsPending,
  $visibleFiles,
  $filesCount,
  $files,
  $filesToDelete,
  $isNotificationShown,
  $canSendFiles,
  markToDelete,
  onChangeFiles,
  close,
  closeNotification,
  sendFiles,
}: Params<T>) => {
  return React.memo(({ notificationProps }: { notificationProps?: NotificationItemProps }) => {
    const id = useUnit($entityId)
    const files = useUnit($files)
    const visibleFiles = useUnit($visibleFiles)
    const isPending = useUnit($isSendAttachmentsPending)
    const filesCount = useUnit($filesCount)
    const filesToDelete = useUnit($filesToDelete)
    const isNotificationShown = useUnit($isNotificationShown)
    const canSendFiles = useUnit($canSendFiles)

    useOnChangeLocation(close)

    const filesWithId = useMemo(() => (
      files.map((file) => ({ file, id: uuidv4() }))
    ), [files])

    if (id === null) return null

    const handleDeleteFile = (uuid: string) => {
      const files = filesWithId.filter(({ id }) => uuid !== id)
      onChangeFiles(files.map(({ file }) => file))
    }

    return (
      <ModalLayoutV2 closeModal={isPending ? noop : close}>
        <Wrapper>
          <Head>
            Мои вложения
            {' '}
            <AttachmentsCount>
              {filesCount}
            </AttachmentsCount>
          </Head>

          {notificationProps && isNotificationShown && canSendFiles && (
            <NotificationItem
              {...notificationProps}
              showCloseButton
              onClose={closeNotification}
            />
          )}

          <Body>
            {visibleFiles?.length || files.length ? (
              <FileList>
                {visibleFiles?.map(({
                  url,
                  extension,
                  id,
                  name,
                  can_delete,
                }) => (
                  <NewFileItem
                    key={id}
                    isDense
                    name={name}
                    url={url}
                    extension={extension}
                    onDelete={canSendFiles && can_delete ? () => markToDelete(id) : undefined}
                  />
                ))}
                {filesWithId.map(({ id, file }) => (
                  <NewFileItem
                    key={id}
                    isDense
                    name={file.name}
                    onDelete={() => handleDeleteFile(id)}
                  />
                ))}
              </FileList>
            ) : !canSendFiles && (
              <EmptyWrapper>
                У вас нет вложений
              </EmptyWrapper>
            )}

            {canSendFiles && (
              <DropArea
                value={files}
                isMultiple
                showInfo
                onChange={onChangeFiles}
              />
            )}
          </Body>

          {canSendFiles && (
            <Footer>
              <NewButton
                dataTestId="send-files"
                label="Сохранить"
                onClick={sendFiles}
                isPending={isPending}
                isDisabled={!files.length && !filesToDelete.length}
                isFill
              />
              <NewButton
                dataTestId="close-modal"
                label="Отменить"
                buttonType="grey"
                onClick={close}
                isDisabled={isPending}
                isFill
              />
            </Footer>
          )}
        </Wrapper>
      </ModalLayoutV2>
    )
  })
}

const Wrapper = styled.div`
  padding: 32px;
  width: 600px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const Head = styled.div`
  ${HeadXL}
`

const AttachmentsCount = styled.span`
  ${HeadL}
  color: ${palette.grey60};
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const FileList = styled.div`
  display: grid;
  gap: 2px 20px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  width: 100%;
`

const EmptyWrapper = styled.p`
  ${TextMLight}
  color: ${palette.grey70};
  text-align: center;
`

const Footer = styled.div`
  display: flex;
  gap: 16px;
`
