import React, { FormEvent, memo, useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import { useForm } from 'effector-forms'
import { useUnit } from 'effector-react'

import { NewDropDownField } from '@/ui'
import { useCheckFieldsDirty } from '@/lib/hooks/useCheckFieldsDirty'
import { getDocumentAliasByRouterPath } from '@/lib/documentResolvers'
import { RouterPaths } from '@/features/app/model'
import { ButtonsRow, ModalHeader } from '@/features/support/shared'
import { FILE_MAX_COUNT } from '@/features/support/model'

import {
  $documentOptions, $documentTypeOptions, createAppealModal, getJiraDocumentsFx,
} from '../../model/private'
import { documentProblemForm } from '../../model/forms'
import { BaseFormFieldsWithChildren } from '../parts'

type Path = RouterPaths extends `/${infer U}` ? U : never

export const DocumentProblemForm = memo(() => {
  const params = useRouteMatch<{ path: Path, id: string }>('/:path/:id')?.params

  const [documentTypeOptions, docOptions, isDocumentListPending] = useUnit([
    $documentTypeOptions,
    $documentOptions,
    getJiraDocumentsFx.pending,
  ])

  const { fields, submit, isValid } = useForm(documentProblemForm)

  const areFieldsDirty = useCheckFieldsDirty(fields, ['files'])

  useEffect(() => {
    if (!params) return

    const docId = parseInt(params.id ?? '', 10)
    const docAlias = getDocumentAliasByRouterPath(`/${params.path}` as RouterPaths)

    if (docId && docAlias === fields.documentType.value) {
      fields.document.onChange(docId)
    }
  }, [])

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    submit()
  }

  return (
    <Wrapper onSubmit={handleSubmit}>
      <ModalHeader title="Вопрос с документом" />

      <BaseFormFieldsWithChildren fields={fields}>
        <NewDropDownField
          label="Тип документа"
          placeholder="Выберите тип документа из списка"
          dataTestId="document-type-input"
          error={fields.documentType.firstError?.errorText}
          options={documentTypeOptions}
          size="L"
          selectedId={fields.documentType.value}
          required
          onOptionClick={fields.documentType.onChange}
        />
        <NewDropDownField
          label="Документ"
          placeholder="Выберите документ из списка"
          dataTestId="document-input"
          error={fields.document.firstError?.errorText}
          options={docOptions}
          selectedId={fields.document.value}
          size="L"
          isDisabled={!fields.documentType.value || isDocumentListPending}
          isLoading={isDocumentListPending}
          required
          hasSearch
          onOptionClick={fields.document.onChange}
        />
      </BaseFormFieldsWithChildren>

      <ButtonsRow
        isDisabled={!isValid || !areFieldsDirty || isDocumentListPending}
        canTakeScreenshot={fields.files.value.length < FILE_MAX_COUNT}
        hasScreenshotButton
        onClose={createAppealModal.close}
        onSubmit={submit}
      />
    </Wrapper>
  )
})

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 32px;

  width: 542px;
  padding: 32px;
`
