import React from 'react'
import styled, { css } from 'styled-components'

import {
  DefaultButton,
  Icon2,
  TextS,
  TextSLight,
  ToolTipV2,
  palette,
} from '@/ui'
import { useShortItems } from '@/lib/hooks/useShortItems'
import { getNoun } from '@/lib/text-noun'
import { useTooltip } from '@/lib/hooks'

type Props = {
  maxDataValue: number
  days: number
  contracts?: {
    name: string
    days_to_paying?: number
    days_to_approving?: number
  }[]
  isApproving?: boolean
}

export const Chart = ({
  maxDataValue,
  days,
  contracts,
  isApproving,
}: Props) => {
  const {
    setIsExpand,
    visibleItems,
    showButton,
  } = useShortItems({ source: contracts ?? null, shortCount: 4 })

  const {
    floatingStyles,
    getFloatingProps,
    getReferenceProps,
    isOpen,
    refs,
    arrowProps,
  } = useTooltip({ placement: 'left' })

  const maxValueLog = Math.log(maxDataValue + 1)
  const height = (Math.log(days + 1) / maxValueLog) * 100

  return (
    <Item
      height={height}
      isApproving={Boolean(isApproving)}
      ref={refs.setReference}
      {...getReferenceProps()}
    >
      {days}

      {visibleItems && (
        <ToolTipV2
          floatingStyles={floatingStyles}
          getFloatingProps={getFloatingProps}
          isOpen={isOpen}
          refs={refs}
          arrowProps={arrowProps}
        >
          <>
            <ContractList>
              {visibleItems?.map(({ name, days_to_paying, days_to_approving }, index) => {
                const days = isApproving ? days_to_approving : days_to_paying
                return (
                  <ContractItem key={index}>
                    <ContractName>
                      {name}
                    </ContractName>
                    <ContractDays>
                      {`${days} ${getNoun(days as number, 'день', 'дня', 'дней')}`}
                    </ContractDays>
                  </ContractItem>
                )
              })}
              {showButton && (
                <ExpandContacts onClick={() => setIsExpand(true)}>
                  Показать еще
                  <Icon2 size={12} icon="arrowDown" />
                </ExpandContacts>
              )}
            </ContractList>
          </>
        </ToolTipV2>
      )}
    </Item>
  )
}


const Item = styled.div<{height: number, isApproving?: boolean}>`
  border-radius: 4px 4px 0px 0px;
  width: 100%;
  height: ${({ height }) => `${height}%`};
  background-color: ${palette.violet100};
  display: flex;
  align-items: flex-start;
  padding: 10px 0;
  justify-content: center;
  transition: background-color 0.15s linear;
  ${TextS}
  color: ${palette.white};

  @media (hover: hover) {
    &:hover {
      background-color: ${palette.violet70};
    }
  }

  ${({ isApproving }) => isApproving && css`
    background-color: ${palette.accent60};

    @media (hover: hover) {
      &:hover {
        background-color: ${palette.accent90};
      }
    }
  `}
`

const ContractList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 4px;
  list-style: none;
  margin: 0;
  padding: 0;
`

const ContractItem = styled.li`
  display: flex;
  gap: 12px;
  align-items: center;
`

const ContractName = styled.p`
  ${TextSLight}
  color: ${palette.white};
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  flex-grow: 1;
`

const ContractDays = styled.p`
  ${TextS}
  color: ${palette.white};
  text-align: left;
`

const ExpandContacts = styled(DefaultButton)`
  display: flex;
  gap: 4px;
  width: max-content;
  align-items: center;
  ${TextS}
  color: ${palette.white};
`
