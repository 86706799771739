import { createForm } from 'effector-forms'

import { RowFilterEnum, getArticlesOutlayReqFx, getGaActingGroupOptionsReqFx } from '@/dal'
import { createFilters } from '@/features/shared/filterFactory/createFilters'
import { createSingleEffect } from '@/lib/createSingleEffect'
import { rules } from '@/lib/form-validators'
import { Option } from '@/ui'

import { d } from './domain'
import { Filters } from './types'
import { ActGate } from '../../model'

export const $worksTableSearch = d.store('')
export const changeSearchName = d.event<string>()
export const resetSearchName = d.event()

export const $articleItems = d.store<{ id: number, label: string }[]>([])

export const $hasChangedGaFilters = d.store(false)
export const acceptGaFilters = d.event()
export const resetGaFilters = d.event()

export const $actionGroups = d.store<Option[]>([])

// значения фильтров групп актирования перед применением
export const {
  $filters,
  removeFilter,
  setFilter,
  setFilters,
  clearFilter,
} = createFilters<Filters>({ rowFilters: [RowFilterEnum.HideDeleted] }, d)

export const filtersForm = createForm<Required<Filters>>({
  fields: {
    article: {
      init: [],
    },
    remained: {
      init: [],
    },
    reportPeriod: {
      init: [],
    },
    rowFilters: {
      init: [RowFilterEnum.HideDeleted],
    },
    acceptanceStatus: {
      init: null,
    },
    dateFrom: {
      init: '',
      rules: [rules.dateRange('endDate', true)],
    },
    dateTo: {
      init: '',
      rules: [rules.dateRange('startDate')],
    },
    actionGroups: {
      init: [],
    },
  },
})

export const acceptSearch = d.event<void>()
export const resetFilters = d.event<void>()

export const { requestFx: getActArticleFx } = createSingleEffect(getArticlesOutlayReqFx, {
  abortEvents: [ActGate.close],
})

export const { requestFx: getGaActingGroupOptionsFx } = createSingleEffect(
  getGaActingGroupOptionsReqFx,
  { abortEvents: [ActGate.close] },
)
