import { GetActTableParams } from './types'
import { ContractDirection } from '../administrative'

export const mapQueryFilters = ({
  actType,
  article,
  groupId,
  actionGroups,
  name,
  remained,
  reportPeriod,
  rowFilters,
  acceptanceStatus,
  dateFrom,
  dateTo,
}: Omit<GetActTableParams, 'actId'>) => {
  const getSpecificFilters = () => {
    switch (actType) {
      case ContractDirection.PDRD: {
        return {
          filter_by_status: acceptanceStatus,
          filter_by_date_from: dateFrom,
          filter_by_date_to: dateTo,
        }
      }
      case ContractDirection.SMR:
      default: {
        return {
          filter_by_completed_in_reporting_period: reportPeriod?.join(','),
          // TODO для ГА, непонятно, понадобится ли
          filter_by_action_group_id: actionGroups?.join(','),
        }
      }
    }
  }

  return {
    filter_by_name: name,
    filter_by_article_outlay: article?.join(','),
    filter_by_remainder_to_complete: remained?.join(','),
    filter_by_string_state: rowFilters?.join(','),
    filter_by_group_id: groupId,
    ...getSpecificFilters(),
  }
}
