import React, { memo } from 'react'
import styled from 'styled-components'

import { waitForScreenshotMode } from '@/features/screen-capture/model'
import { NewButton } from '@/ui'

type Props = {
  submitLabel?: string
  isDisabled: boolean
  isPending?: boolean
  hasScreenshotButton?: boolean
  canTakeScreenshot?: boolean
  onClose: () => void
  onSubmit: () => void
}

export const ButtonsRow = memo(({
  submitLabel = 'Далее',
  isDisabled,
  isPending,
  hasScreenshotButton,
  canTakeScreenshot,
  onClose,
  onSubmit,
}: Props) => {
  const isScreenshotBtnShown = 'getDisplayMedia' in navigator.mediaDevices
  const canCaptureScreen = 'ImageCapture' in window

  return (
    <Wrapper>
      <FirstButtonWrapper>
        {hasScreenshotButton && isScreenshotBtnShown && (
          <NewButton
            label="Сделать скриншот"
            prefixIcon="scissors"
            buttonType="line-grey"
            isDisabled={!canCaptureScreen || !canTakeScreenshot}
            tooltip={!canCaptureScreen ? 'Не поддерживается вашим браузером' : undefined}
            dataTestId="appeal-back-btn"
            onClick={waitForScreenshotMode}
          />
        )}
      </FirstButtonWrapper>
      <NewButton
        label="Отменить"
        buttonType="grey"
        isDisabled={isPending}
        dataTestId="form-cancel-btn"
        onClick={onClose}
      />
      <NewButton
        label={submitLabel}
        isDisabled={isDisabled}
        isPending={isPending}
        dataTestId="form-submit-btn"
        onClick={onSubmit}
      />
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  justify-content: end;
  gap: 12px;
`

const FirstButtonWrapper = styled.div`
  margin-right: auto;
`
