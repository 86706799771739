import { attach } from 'effector'

import {
  getActInfoReqFx,
  getActFeaturesReqFx,
  ActDTO,
  ActGaGroupType,
} from '@/dal'
import { createSingleEffect } from '@/lib/createSingleEffect'
import { createModal } from '@/features/factories'

import { d } from './domain'

export const {
  requestFx: getActInfoFx,
  abortFx: abortGetActInfoFx,
} = createSingleEffect(getActInfoReqFx)

export const refetchAct = d.event()

export const $hasChangedTables = d.store(false)
export const markTablesAsChanged = d.event()
export const resetHasChangedTables = d.event()

export const worksModal = createModal<ActGaGroupType>()

export const updateFeaturesFx = attach({ effect: getActFeaturesReqFx })

export const updateDocumentInfo = d.event<ActDTO>()
