import { sample } from 'effector'

import {
  $isAskingForScreenCapture,
  $isWaitingForScreenshotMode,
  askForScreenCapture,
  onScreenCaptureRequestCanceled,
  onScreenshotModeExited,
  resetIsWaitingForScreenshotMode,
  waitForScreenshotMode,
} from './public'
import {
  $isScreenshotModeEnabled,
  enableScreenshotMode,
  exitScreenshotMode,
  resetIsAskingForScreenCapture,
} from './private'

$isWaitingForScreenshotMode
  .on(waitForScreenshotMode, () => true)
  .reset(resetIsWaitingForScreenshotMode)

$isAskingForScreenCapture
  .on(askForScreenCapture, () => true)
  .reset(resetIsAskingForScreenCapture)

$isScreenshotModeEnabled
  .on(enableScreenshotMode, () => true)
  .reset(exitScreenshotMode)

sample({
  clock: askForScreenCapture,
  target: resetIsWaitingForScreenshotMode,
})

sample({
  clock: onScreenCaptureRequestCanceled,
  target: resetIsWaitingForScreenshotMode,
})

sample({
  clock: $isScreenshotModeEnabled.updates,
  filter: (val) => !val,
  target: onScreenshotModeExited,
})
