import React, { memo } from 'react'
import styled from 'styled-components'

import type { GaBuilding, GaEndNode } from '@/dal'
import { Cell } from '@/features/act/view'

import { FirstCellWrapper, StickyRow } from '../parts'
import { SectionWithFloors } from './SectionWithFloors'
import { EndNodeRows } from './EndNodeRows'

type Props = {
  item: GaBuilding
}

export const BuildingRow = memo(({ item }: Props) => {
  const { name, sections } = item
  const areSectionsEndNodes = !sections[0]?.floors.length

  return (
    <div>
      <StickyRow>
        <FirstCellWrapper>
          <Cell bgColor="grey20" borderPosition="bottom" isLeftAlign isBold>
            Корпус
          </Cell>
        </FirstCellWrapper>
        <Cell isBold>{name}</Cell>
      </StickyRow>

      {areSectionsEndNodes ? (
        <EndNodeRows items={sections as GaEndNode[]} typeName="Секция" />
      ) : (
        <Sections>
          {sections.map((item) => (
            <SectionWithFloors key={item.id} item={item} />
          ))}
        </Sections>
      )}
    </div>
  )
})

const Sections = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`
