import React, { memo, useMemo } from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'
import { useForm } from 'effector-forms'

import {
  DropAreaWithList,
  NewDateInput,
  NewDropDownField,
  NewTextButton,
  NotificationItem,
  NotificationType,
  NumberInputField,
} from '@/ui'
import { lotInfoForm } from '@/features/estimate-lot/model/forms'
import { $documentInfo } from '@/features/estimate-lot/model'
import { $advanceTypes, $typesOfSecurity } from '@/features/estimate-lot/model/private'
import { $isActionPending } from '@/features/estimate-lot/model/header.private'
import { getDayJsObject } from '@/lib/date'

import { Row } from '../parts'

const roundPercentInput = (value: string) => (parseFloat(value) > 100 ? '100' : value)

export const CommercialInfoForm = memo(() => {
  const [info, advanceTypes, typesOfSecurity, isPending] = useUnit([
    $documentInfo,
    $advanceTypes,
    $typesOfSecurity,
    $isActionPending,
  ])

  const { fields } = useForm(lotInfoForm)

  const constructionStartDateRange = useMemo(() => {
    const constructionEnd = fields.construction_end.value
    const to = constructionEnd
      ? getDayJsObject(constructionEnd ?? '').subtract(1, 'day').toDate()
      : undefined
    return { to }
  }, [fields.construction_end.value])

  const constructionEndDateRange = useMemo(() => {
    const constructionStart = fields.construction_start.value
    const from = constructionStart
      ? getDayJsObject(constructionStart ?? '').add(1, 'day').toDate()
      : undefined
    return { from }
  }, [fields.construction_start.value])

  if (!info) return null

  return (
    <>
      <Row>
        <NewDropDownField
          label="Вид аванса"
          placeholder="Вид аванса"
          options={advanceTypes}
          selectedId={fields.advance_type.value}
          size="L"
          isDisabled={isPending}
          dataTestId="lot-advance-type-select"
          required
          hasClear
          onOptionClick={fields.advance_type.onChange}
        />

        <NumberInputField
          label="Размер аванса в %"
          value={fields.advance_size.value}
          decimalScale={2}
          error={fields.advance_size.firstError?.errorText}
          size="L"
          disabled={isPending}
          suffix="%"
          dataTestId="lot-advance-size-input"
          onChange={(val) => fields.advance_size.onChange(roundPercentInput(val))}
          onClear={fields.advance_size.reset}
        />

        <NumberInputField
          label="Размер гарантийного удержания в %"
          value={fields.guarantee_retention_size.value}
          decimalScale={2}
          error={fields.guarantee_retention_size.firstError?.errorText}
          size="L"
          disabled={isPending}
          suffix="%"
          dataTestId="lot-guarantee-retention-size-input"
          onChange={(val) => fields.guarantee_retention_size.onChange(roundPercentInput(val))}
          onClear={fields.guarantee_retention_size.reset}
        />
      </Row>

      <Row>
        <NewDropDownField
          label="Вид обеспечения"
          placeholder="Вид обеспечения"
          options={typesOfSecurity}
          selectedId={fields.type_of_security.value}
          size="L"
          isDisabled={isPending}
          dataTestId="lot-type-of-security-select"
          required
          hasClear
          onOptionClick={fields.type_of_security.onChange}
        />
        <NewDateInput
          label="Сроки строительства"
          placeholder="Начало строительства"
          inputValue={fields.construction_start.value}
          initialOpenedDateValue={new Date().toDateString()}
          errorText={fields.construction_start.errorText()}
          activeDateRange={constructionStartDateRange}
          size="L"
          disabled={isPending}
          dataTestId="lot-start-date-input"
          onChange={fields.construction_start.onChange}
        />
        <ConstructionEndInputWrapper>
          <NewDateInput
            placeholder="Окончание строительства"
            inputValue={fields.construction_end.value}
            initialOpenedDateValue={new Date().toDateString()}
            errorText={fields.construction_end.errorText()}
            activeDateRange={constructionEndDateRange}
            size="L"
            disabled={isPending}
            dataTestId="lot-end-date-input"
            onChange={fields.construction_end.onChange}
          />
        </ConstructionEndInputWrapper>
      </Row>

      {!fields.is_typical_form_of_contract.value && (
        <>
          <NewTextButton
            url="mock"
            label="Скачать типовую форму"
            textStyle="LL"
            dataTestId="lot-download-typical-contract-form-btn"
            isDownload
            isInline
          />
          <NotificationItem
            body="Приложите свою форму договора или протокол разногласий"
            type={NotificationType.Info}
          />
          {/* TODO нет бэка */}
          <DropAreaWithList
            value={fields.files.value}
            dataTestId="lot-files-input"
            onChange={fields.files.onChange}
          />
        </>
      )}
    </>
  )
})

const ConstructionEndInputWrapper = styled.div`
  margin-top: auto;
`
