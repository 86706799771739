import React from 'react'
import styled from 'styled-components'

import { ActGaRow } from '@/dal'
import { Icon2, NewTextButton, palette, TextMLight } from '@/ui'
import { CELL_BORDER_COLOR } from '@/features/act/view'
import { numberWithSpaces } from '@/lib/number-represent'

import { GaGridWrapper } from './GaGridWrapper'

type Props = {
  item: ActGaRow
  onOpenTable: () => void
}

export const GaTableRow = ({ item, onOpenTable }: Props) => {
  const {
    id,
    ordering,
    unit,
    approved_cost: approvedCost,
    start_count: startCount,
    start_cost: startCost,
    period_count: periodCount,
    period_cost: periodCost,
    count_all: count,
    action_group_name: name,
    is_accepted: isAccepted,
  } = item

  return (
    <GaGridWrapper as="td">
      <Cell>{ordering}</Cell>
      <Cell>
        <Col>
          {name}
          <NewTextButton
            label="Заполнить выполненный объем"
            textStyle="ML"
            dataTestId={`open-acting-group-${id}-btn`}
            onClick={onOpenTable}
          />
        </Col>
        {isAccepted && <Icon2 icon="check" padding={10} color="accent100" />}
      </Cell>

      <Cell>{unit}</Cell>
      <Cell>{count}</Cell>
      <Cell>{numberWithSpaces(approvedCost)}</Cell>
      <Cell>{startCount}</Cell>
      <Cell>{numberWithSpaces(startCost)}</Cell>
      <Cell>{periodCount}</Cell>
      <Cell>{numberWithSpaces(periodCost)}</Cell>
    </GaGridWrapper>
  )
}

const Cell = styled.div`
  ${TextMLight}
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 72px;
  padding: 8px 12px 8px 20px;
  background-color: ${palette.white};
  text-align: center;
  word-break: break-word;

  &:not(:last-child) {
    border-right: 1px solid ${CELL_BORDER_COLOR};
  }
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
  text-align: left;
`
