import React from 'react'
import styled, { css } from 'styled-components'

import { mergeRefs } from '@/lib/mergeRefs'
import { numberWithSpaces } from '@/lib/number-represent'

import { usePopup } from '../../hooks'
import { TextMLight } from '../../typography'
import { PaletteColor, palette } from '../../palette/palette'
import { DialogSheetWrapper } from '../../components/DialogSheetWrapper'
import { NewIconButton } from '../../buttons/NewIconButton'
import { IconName2 } from '../../icons/Icon2'
import { DefaultButton } from '../../buttons'

export type CellInputWrapperProps = {
  value: string
  suffix?: string
  textAlign?: 'right' | 'center' | 'left'
  children: React.ReactElement
  isMaskInput?: boolean
  inputRef?: React.MutableRefObject<HTMLElement> | React.RefObject<HTMLElement>
  isInvalid?: boolean
  padding?: string,
  maxLines?: number
  popupWidth?: number,
  icon?: IconName2
  isDisable?: boolean
  iconTooltip?: string
  color?: PaletteColor
  hasAbsoluteIcon?: boolean
  isCentered?: boolean
  decimalScale?: number
  dot?: string
  submitKeys?: string[]
  hasSubmitOnEnter?: boolean
  hasToFocus?: boolean
  selectAfterFocus?: boolean
  popupZIndex?: number
  verticalAlign?: 'top' | 'center' | 'bottom'
  sameComponentHeight?: boolean
  onIconClick?: () => void
  onFocus?: () => void
  onBlur?: () => void
  onToggle?: (val: boolean) => void
  onEnter?: () => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

export const CellInputWrapper = ({
  suffix,
  value,
  textAlign,
  children,
  inputRef,
  isMaskInput,
  isInvalid,
  padding,
  maxLines,
  popupWidth,
  icon = 'edit',
  isDisable,
  iconTooltip,
  color,
  hasAbsoluteIcon = false,
  isCentered = false,
  decimalScale,
  dot = '.',
  submitKeys = [],
  hasToFocus,
  selectAfterFocus = false,
  popupZIndex,
  verticalAlign = 'top',
  sameComponentHeight,
  onIconClick,
  onBlur,
  onFocus,
  onToggle,
  onEnter,
  onKeyDown,
}: CellInputWrapperProps) => {
  const ref = React.useRef<HTMLInputElement>(null)
  const wrapperRef = React.useRef<HTMLDivElement>(null)

  const offsetNumber = React.useMemo(() => {
    const inputHeight = ref.current?.offsetHeight || 0
    const wrapperHeight = wrapperRef.current?.offsetHeight || 0
    if (isCentered) {
      return 0 - (wrapperHeight + inputHeight / 2)
    }
    return 0 - wrapperHeight
  }, [ref.current, wrapperRef.current, isCentered])

  const {
    isOpen,
    open,
    close,
    forceClose,
    floatingStyles,
    getFloatingProps,
    getReferenceProps,
    refs,
  } = usePopup({
    sameComponentWidth: !popupWidth,
    sameComponentHeight,
    placement: 'bottom',
    onOpen: onFocus,
    onClose: onBlur,
    onToggle,
    offsetNumber,
    isDisable,
    escapeKey: false,
  })
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (submitKeys.includes(e.key)) {
      e.preventDefault()
      close()
      onEnter?.()
    }
    if (e.key === 'Escape') {
      forceClose()
    }
    onKeyDown?.(e)
  }

  React.useEffect(() => {
    if (hasToFocus) {
      open()
    }
  }, [hasToFocus, open])

  React.useEffect(() => {
    if (isOpen) {
      ref.current?.focus()
      if (selectAfterFocus) ref.current?.select()
    }
  }, [isOpen, selectAfterFocus])

  const refPropName = isMaskInput ? 'getInputRef' : 'ref'
  const Input = React.cloneElement(
    children,
    {
      [refPropName]: inputRef ? mergeRefs([inputRef, ref]) : ref,
      onKeyDown: onBlur ? handleKeyDown : undefined,
      onFocus,
    },
  )
  const formattedValue = decimalScale ? numberWithSpaces(value, { decimalScale, dot }) : value

  return (
    <>
      <InputWrapper
        ref={wrapperRef}
        $padding={padding}
        $isInvalid={Boolean(isInvalid)}
        $isOpen={isOpen}
        $isDisabled={isDisable}
        $verticalAlign={verticalAlign}
      >
        <OverlayButton
          ref={refs.setReference}
          {...getReferenceProps()}
        />
        <Value
          $isInvalid={Boolean(isInvalid)}
          $textAlign={textAlign}
          $maxLines={maxLines}
          $color={color}
        >
          {`${formattedValue} ${suffix ? ` ${suffix}` : ''}`}
        </Value>

        {!isOpen && (
          <IconWrapper isAbsolute={hasAbsoluteIcon}>
            <NewIconButton
              dataTestId=""
              icon={icon}
              color={isInvalid ? 'red100' : isOpen ? 'grey40' : 'accent80'}
              hoverColor={!isInvalid ? 'accent100' : undefined}
              size={16}
              tooltipText={iconTooltip}
              disabled={isDisable}
              onClick={onIconClick ? (e) => {
                e.stopPropagation()
                onIconClick()
              } : undefined}
            />
          </IconWrapper>
        )}
      </InputWrapper>

      {isOpen && (
        <FieldWrapper
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
          popupWidth={popupWidth}
          zIndex={popupZIndex}
        >
          {Input}
        </FieldWrapper>
      ) }
    </>
  )
}

type ValueProps = {
  $textAlign?: 'right' | 'center' | 'left'
  $isInvalid: boolean
  $maxLines?: number
  $color?: PaletteColor
}

const Value = styled.span<ValueProps>`
  ${TextMLight};
  ${({ $textAlign }) => $textAlign && css`
    text-align: ${$textAlign};
  `}
  ${({ $isInvalid }) => $isInvalid && css`
    color: ${palette.red100};
  `}
  ${({ $isInvalid, $color }) => !$isInvalid && $color && css`
    color: ${palette[$color]};
  `}
  ${({ $maxLines }) => $maxLines && css`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: ${$maxLines};
    -webkit-box-orient: vertical;
  `}
  width: 100%;
`

const IconWrapper = styled.div<{ isAbsolute?: boolean }>`
  position: relative;
  z-index: 1;
  height: 16px;

  ${({ isAbsolute }) => isAbsolute && css`
    position: absolute;
    top: 0;
    left: calc(100% + 4px);
  `}
`

type InputWrapperProps = {
  $isOpen: boolean
  $isInvalid: boolean
  $padding?: string
  $isDisabled?: boolean
  $verticalAlign: CellInputWrapperProps['verticalAlign']
}

const verticalAlignments = {
  top: 'flex-start',
  center: 'center',
  bottom: 'flex-end',
}

const OverlayButton = styled(DefaultButton)`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
`

const InputWrapper = styled.div<InputWrapperProps>`
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 4px;
  cursor: ${({ $isDisabled }) => ($isDisabled ? 'not-allowed' : 'pointer')};
  transition: border-color 0.15s linear;
  height: 100%;
  border-radius: 4px;

  padding: ${({ $padding }) => $padding || '0px'};

  ${({ $isInvalid }) => $isInvalid && css`
    color: ${palette.red100};
  `}

  ${({ $verticalAlign }) => $verticalAlign && css`
    align-items: ${verticalAlignments[$verticalAlign]};
  `}
`

const FieldWrapper = styled(DialogSheetWrapper)<{ popupWidth?: number, zIndex?: number }>`
  display: flex;
  align-items: center;
  width: ${({ popupWidth }) => (popupWidth ? `${popupWidth}px` : 'auto')};
  min-height: 40px;
  padding: 12px;
  box-shadow: 0px 1px 6px 0px ${palette.shadow};
  border-radius: 4px;

  ${({ zIndex }) => zIndex && css`
    z-index: ${zIndex};
  `};
`
