import { attachWrapper } from '@42px/effector-extra'

import { Method, authRequestFx } from '../request'
import { AbortableRequest, DefaultResponseFail } from '../interfaces'
import { defaultErrorHandler } from '../error-handler'
import { DocumentFilesInfo, GetDocumentFilesPayload } from './types'

export const getDocumentFilesReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ signal, id, docType }: AbortableRequest<GetDocumentFilesPayload>) => ({
    method: Method.get,
    url: `/files/document_files/${docType}/${id}/`,
    signal,
  }),
  mapResult: ({ result }): DocumentFilesInfo => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})
