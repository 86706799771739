export enum DefaultErrorCode {
  TextError = 'ERROR',
  UserNotFound = 'NO_EMAIL_FOUND',
  ErrorAsIssue = 'ERROR_AS_ISSUE',
  RequestCancel = '__REQUEST_CANCEL',
}

export type CustomResponseErrorData<T> = {
  detail: T | null
  code: string | null
}

export type TextErrorData = {
  code: DefaultErrorCode.TextError
  detail: string
}

export type UserNotFoundErrorData = {
  code: DefaultErrorCode.UserNotFound
  detail: string
}

export type ErrorAsIssueErrorData = {
  code: DefaultErrorCode.ErrorAsIssue
  detail: string
  issue: {
    /** id сформированного обращения */
    id: number
    number: string
  }
}

export type RequestCancelErrorData = {
  code: DefaultErrorCode.RequestCancel
  detail: null
}

export type DefaultErrorResponseData =
  | TextErrorData
  | UserNotFoundErrorData
  | ErrorAsIssueErrorData
  | RequestCancelErrorData
  | undefined
