import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import {
  HeadM, NotificationItem, NotificationType, palette,
} from '@/ui'

import { $isClosing } from '@/features/administrative/close-contract/model'

import { ClosurePercentageStatus, DocumentAlias } from '@/dal'
import {
  CloseButton, CreateDocumentButton, UpdateClosurePercentage, WorkCompleted,
} from '@/features/administrative/share'
import { ProgressItem } from '../parts'
import {
  $contract,
  $documentFeatures,
  $isClosurePercentageUpdated,
  onCloseButton,
  onRequestAdvans,
  onUpdateClosurePercentage,
  updateClosurePercentageFx,
} from '../../../model/private'

const $contractData = $contract.map((contract) => (contract ? ({
  id: contract.id,
  closestInfo: contract.closure_percentage,
  isAdvancePossible: contract.features.is_advance_possible,
}) : null))

export const ClosingProgress = React.memo(() => {
  const contractData = useUnit($contractData)
  const isClosing = useUnit($isClosing)
  const features = useUnit($documentFeatures)
  const [isClosurePercentageUpdated, isClosureUpdating] = useUnit([
    $isClosurePercentageUpdated,
    updateClosurePercentageFx.pending,
  ])

  const paymentCreateButton = React.useMemo(() => features && contractData && (
    <CreateDocumentButton
      id={contractData.id}
      canCreateFeature={features.payment_document.create}
      docType={DocumentAlias.PAYMENT}
      label="Запросить аванс"
      onCreateDocument={() => onRequestAdvans()}
    />
  ), [contractData, features])

  if (
    !contractData
    || contractData.closestInfo.status === ClosurePercentageStatus.Hidden
    || !contractData.closestInfo.progress
  ) return null

  const {
    docs_link,
    has_closing_documents,
    progress,
    status,
    closure_data_update_at,
  } = contractData.closestInfo

  const {
    act,
    advance_credited,
    paid_advance,
    sum_guarantee,
  } = progress

  const showCloseButton = status === ClosurePercentageStatus.CanCloseContract
  const showWorkCompleted = status === ClosurePercentageStatus.WorkCompleted
    || status === ClosurePercentageStatus.CanGetGuaranteeRetention

  return (
    <Wrapper>
      <Header>
        <Title>
          Процент закрытия договора
        </Title>
        <UpdateClosurePercentage
          date={closure_data_update_at}
          isDisabled={isClosurePercentageUpdated}
          isLoading={isClosureUpdating}
          onUpdate={() => onUpdateClosurePercentage(contractData.id)}
        />
      </Header>
      <ProgressList>
        <ProgressItem
          filledPercent={parseInt(act.total_percent, 10)}
          title="Выполнения"
          stroke="accent70"
          labels={[
            {
              label: 'Выполнено, руб',
              percent: act.completed.percentage,
              squareColor: 'accent70',
              sum: act.completed.value,
            },
            {
              label: 'Осталось, руб',
              percent: act.remaining.percentage,
              squareColor: 'grey30',
              sum: act.remaining.value,
            },
          ]}
        />

        {showCloseButton && (
          <CloseButton
            type="line-primary"
            onButton={onCloseButton}
            isPending={isClosing}
            contractId={contractData.id}
          />
        )}

        {showWorkCompleted && (
          <WorkCompleted
            hasDocs={has_closing_documents}
            link={docs_link}
          />
        )}

        <ProgressItem
          filledPercent={parseInt(sum_guarantee.total_percent, 10)}
          stroke="yellow100"
          title="Гарантийные удержания"
          labels={[
            {
              label: 'Удержано, руб',
              percent: sum_guarantee.withholding.percentage,
              squareColor: 'yellow100',
              sum: sum_guarantee.withholding.value,
            },
            {
              label: 'Осталось к удержанию, руб',
              percent: sum_guarantee.remaining_withholding.percentage,
              squareColor: 'grey30',
              sum: sum_guarantee.remaining_withholding.value,
            },
            sum_guarantee.paid && {
              label: 'Выплачено, руб',
              percent: sum_guarantee.paid.percentage,
              squareColor: 'transparent',
              sum: sum_guarantee.paid.value,
            },
          ]}
        />

        {contractData.isAdvancePossible && (
          <Advances>
            {paid_advance && (
              <ProgressItem
                title="Авансы"
                filledPercent={parseInt(paid_advance.total_percent, 10)}
                stroke="pink100"
                buttonSlot={paymentCreateButton}
                labels={[
                  paid_advance.paid && {
                    label: 'Выплачено, руб',
                    percent: paid_advance.paid.percentage,
                    squareColor: 'pink100',
                    sum: paid_advance.paid.value,
                  },
                  paid_advance.remaining_paid && {
                    label: 'Осталось к выплате, руб',
                    percent: paid_advance.remaining_paid.percentage,
                    squareColor: 'grey30',
                    sum: paid_advance.remaining_paid.value,
                  },
                ]}
              />
            )}
            {advance_credited && (
              <ProgressItem
                title={!paid_advance ? 'Авансы' : undefined}
                filledPercent={parseInt(advance_credited.total_percent, 10)}
                stroke="violet"
                labels={[
                  {
                    label: 'Зачтено, руб',
                    percent: advance_credited.passed.percentage,
                    squareColor: 'violet',
                    sum: advance_credited.passed.value,
                  },
                  advance_credited.remaining_passed && {
                    label: 'Осталось к зачету, руб',
                    percent: advance_credited.remaining_passed.percentage,
                    squareColor: 'grey30',
                    sum: advance_credited.remaining_passed.value,
                  },
                ]}
              />
            )}
          </Advances>
        )}
      </ProgressList>

      {!contractData.isAdvancePossible && (
        <NotificationItem type={NotificationType.Info} body="Аванс не предусмотрен по договору" />
      )}
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 20px;
  border-radius: 20px;
  background-color: ${palette.white};
`

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.div`
  ${HeadM}
`

const ProgressList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`

const Advances = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`
