import React from 'react'
import styled, { css } from 'styled-components'

import { FileDTO } from '@/dal'

import { palette } from '../../palette'
import { Icon2 } from '../../icons'
import { TextMLight } from '../../typography'
import { NewIconButton } from '../../buttons'
import { useDefaultDropzone } from '../../hooks'

type Props = {
  onFile: (files: File) => void
  onClearField?: () => void
  onRemoveFile?: (id: FileDTO['id']) => void
  file: File | FileDTO | null
  isDisabled?: boolean
  isLoading?: boolean
  dataTestId?: string
}
export const DropSlot = ({
  isDisabled,
  isLoading,
  dataTestId,
  file,
  onFile,
  onRemoveFile,
  onClearField,
}: Props) => {
  const onDrop = React.useCallback(
    (files: File[]) => {
      onFile(files[0])
    },
    [onFile],
  )

  const handleRemoveFile = React.useCallback(() => {
    if (!file) return null
    if ('id' in file && onRemoveFile) {
      onRemoveFile(file.id)
    } else {
      onClearField?.()
    }
  }, [onRemoveFile, onClearField, file])

  const { getRootProps, getInputProps, isDragActive } = useDefaultDropzone({
    multiple: false,
    disabled: Boolean(isDisabled || isLoading || file),
    onDrop,
  })

  const showClearButton = Boolean(onClearField || onRemoveFile) && !isDisabled

  const Content = (
    <ContentWrapper isActive={isDragActive}>
      <Icon2 icon="addFile" size={14} color="grey50" />
      <LabelText>
        {!file && (
          <>
            <span>Выберите файл</span>
            {' '}
            или перетащите сюда
          </>
        )}
        {file && (
          <FileInfoWrapper>
            <span>
              {file.name}
            </span>
            {showClearButton && (
              <NewIconButton
                icon="cross"
                size={16}
                onClick={handleRemoveFile}
                dataTestId={`remove-file-${file.name}`}
              />
            )}
          </FileInfoWrapper>
        )}
      </LabelText>
    </ContentWrapper>
  )

  if (file) return Content

  return (
    <DropZone
      {...getRootProps()}
      isDisabled={isDisabled}
      data-testid={dataTestId}
    >
      <Input {...getInputProps()} data-testid="input-dragger" />

      {Content}

    </DropZone>
  )
}

const DropZone = styled.div<{ isDisabled?: boolean }>`
  cursor: pointer;
  width: 100%;
  ${({ isDisabled }) => isDisabled && css`
    cursor: not-allowed;
  `}
`

const ContentWrapper = styled.div<{ isActive: boolean }>`
  display: flex;
  width: 100%;
  gap: 8px;
  border-radius: 12px;
  padding: 8px 12px;
  transition: 0.15s linear;
  transition-property: border-color, background-color;
  border: 1px dashed ${palette.grey60};

  &:hover {
    border-color: ${palette.grey90};
  }

  ${({ isActive }) => isActive && css`
    background-color: ${palette.grey10};
  `}
`

const FileInfoWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const LabelText = styled.div`
  ${TextMLight}
  color: ${palette.grey60};
  width: 100%;
  span {
    color: ${palette.grey90}
  }
`

const Input = styled.input`
  display: none;
`
