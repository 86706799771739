import { useUnit } from 'effector-react'
import React from 'react'
import styled from 'styled-components'
import { Effect, EventCallable, Store } from 'effector'

import {
  Dragger,
  Button,
  UploadedFileItem,
} from '@/ui'

export type CreateUploadFiles<P, R, E> = {
  $uploadedFiles: Store<File[]>
  sendFilesFx: Effect<P, R, E>
  onRemove: EventCallable<string>
  onFiles: EventCallable<File[]>
  sendFiles: EventCallable<void>
}

export const createUploadFiles = <P, R, E>({
  $uploadedFiles,
  sendFilesFx,
  onRemove,
  onFiles,
  sendFiles,
}: CreateUploadFiles<P, R, E>) => React.memo(() => {
    const files = useUnit($uploadedFiles)
    const isLoading = useUnit(sendFilesFx.pending)

    return (
      <Wrapper>
        {files !== null && (
          <List>
            {files?.map(({ name }) => (
              <UploadedFileItem key={name} fileName={name} onRemove={onRemove} />
            ))}
          </List>
        )}
        <Row>
          <DropArea>
            <Dragger height="100%" onFiles={onFiles} />
          </DropArea>
          <ButtonWrapper>
            <Button
              size="M"
              styleButton="primary"
              dataTestId="act-upload-files"
              disabled={!files?.length}
              isLoading={isLoading}
              onClick={sendFiles}
            >
              Загрузить
            </Button>
          </ButtonWrapper>
        </Row>
      </Wrapper>
    )
  })

const Wrapper = styled.div`
  width: 100%;
`

const Row = styled.div`
  height: 45px;
  display: flex;
  width: 100%;
`

const DropArea = styled.div`
  flex: 1;
`

const ButtonWrapper = styled.div`
  flex: 0 0 150px;
`

const List = styled.div`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
`
