import { sample } from 'effector'
import { condition } from 'patronum'

import { EstimateDocId, EstimateTableRow, UpdateEstimateRowItemWithId } from '@/dal'
import { NotificationType } from '@/ui'
import { createToast, showToast } from '@/features/toast-service/model'

import {
  $changedRowIds,
  $currentFormId,
  $invalidRowIds,
  $isMultipleEditModeEnabled,
  $rowsFormDataMap,
  changeSpecificField,
  enableMultipleEditMode,
  exitMultipleEditMode,
  removeInvalidRowId,
  RowsFormDataMap,
  addChangedRowId,
  setCurrentFormId,
  setInvalidRowId,
  $lastChangedField,
  setLastChangedField,
  validationChecked,
  updateMultipleKorDocItems,
  updateMultipleKorDocItemsFx,
  updateRowsFormDataMap,
  requestEndedWithErrors,
  $itemToFocus,
  setItemToFocus,
  resetItemToFocus,
  onEnterSubmitItem,
  RowsFormData,
} from './edit-multiple-items.private'
import {
  $flatItems, $tableRoot, changeIsTree, scrollToItemById, setRootUpdate,
} from './private'
import { editItemForm, editRow, resetEditableState } from './edit-table-item.private'
import { $documentId, EstimateCorrectionGate, updateFeatures } from '../../shared-model'
import { getFormValuesFromRow } from './helpers/getFormValuesFromRow'
import { NonFormField, SpecificFieldPayload } from './types'
import { updateSummary } from '../../shared-model/private'
import { getItemToFocus } from './helpers'

$isMultipleEditModeEnabled
  .on(enableMultipleEditMode, () => true)
  .reset(exitMultipleEditMode)

$rowsFormDataMap
  .on(updateRowsFormDataMap, (map, data) => {
    const newMap: RowsFormDataMap = {}
    data.forEach((item) => {
      const values = getFormValuesFromRow(item)
      const entries = Object.entries(values).map(([name, value]) => {
        return [
          name,
          {
            name,
            value,
            isValid: true,
          } as Omit<NonFormField, 'onChange'>,
        ]
      })
      newMap[item.id] = Object.fromEntries(entries)
    })
    return {
      ...newMap,
      ...map,
    }
  })
  .on(changeSpecificField, (map, { id, value, name }) => {
    const item = map[id]
    if (!item) return
    const field = {
      ...item[name as keyof RowsFormData],
      value,
    }
    return {
      ...map,
      [id]: {
        ...item,
        [name]: field,
      },
    }
  })
  .reset(exitMultipleEditMode)

$currentFormId
  .on(setCurrentFormId, (_, id) => id)
  .reset(exitMultipleEditMode)

$lastChangedField
  .on(setLastChangedField, (_, id) => id)
  .reset(exitMultipleEditMode)

$changedRowIds
  .on(addChangedRowId, (ids, id) => (ids.includes(id) ? ids : [...ids, id]))
  .reset(exitMultipleEditMode)

$invalidRowIds
  .on(setInvalidRowId, (ids, id) => (ids.includes(id) ? ids : [...ids, id]))
  .on(removeInvalidRowId, (ids, id) => ids.filter((item) => item !== id))
  .reset(exitMultipleEditMode)

$itemToFocus
  .on(setItemToFocus, (_, data) => data)
  .reset(EstimateCorrectionGate.close, resetItemToFocus)

sample({
  clock: onEnterSubmitItem,
  source: $flatItems,
  filter: Boolean,
  fn: (...args) => getItemToFocus(...args),
  target: setItemToFocus,
})

sample({
  clock: setItemToFocus,
  filter: (data) => Boolean(data?.hasToScroll),
  fn: (data) => data?.id as string,
  target: scrollToItemById,
})

sample({
  clock: enableMultipleEditMode,
  fn: () => false,
  target: changeIsTree,
})

sample({
  clock: EstimateCorrectionGate.close,
  target: exitMultipleEditMode,
})

sample({
  clock: exitMultipleEditMode,
  target: resetEditableState,
})

sample({
  clock: [$isMultipleEditModeEnabled, $tableRoot],
  source: $tableRoot,
  filter: Boolean,
  target: updateRowsFormDataMap,
})

sample({
  clock: changeSpecificField,
  fn: ({ id }) => id,
  target: [editRow, addChangedRowId],
})

sample({
  clock: changeSpecificField,
  target: setLastChangedField,
})

type UpdatePayload = {
  changedField: SpecificFieldPayload
  currentFormId: EstimateTableRow['id']
}

condition({
  source: sample({
    clock: setLastChangedField,
    source: $currentFormId,
    filter: (_, changedField) => Boolean(changedField),
    fn: (currentFormId, changedField) => ({ changedField, currentFormId }),
  }),
  if: ({ changedField, currentFormId }) => changedField?.id === currentFormId,
  then: editItemForm.set.prepend(({ changedField }: UpdatePayload) => ({
    [changedField.name]: changedField.value,
  })),
  else: setCurrentFormId.prepend(({ changedField }: UpdatePayload) => changedField.id),
})

sample({
  clock: $currentFormId.updates,
  source: $rowsFormDataMap,
  filter: (_, id) => Boolean(id),
  fn: (map, id) => {
    return Object.values(map[id as string]).reduce((acc, curr) => {
      (acc as any)[curr.name] = curr.value
      return acc
    }, {})
  },
  target: editItemForm.setForm,
})

sample({
  clock: editItemForm.$values,
  filter: $isMultipleEditModeEnabled,
  target: editItemForm.validate,
})

sample({
  clock: editItemForm.validate,
  source: $currentFormId,
  filter: Boolean,
  target: validationChecked,
})

type ChangeInvalidRowPayload = {
  id: string
  isValid: boolean
}

condition({
  source: sample({
    clock: validationChecked,
    source: editItemForm.$isValid,
    fn: (isValid, id) => ({ isValid, id }),
  }),
  if: ({ isValid }) => isValid,
  then: removeInvalidRowId.prepend(({ id }: ChangeInvalidRowPayload) => id),
  else: setInvalidRowId.prepend(({ id }: ChangeInvalidRowPayload) => id),
})

sample({
  clock: updateMultipleKorDocItems,
  source: {
    documentId: $documentId,
    ids: $changedRowIds,
    rowsMap: $rowsFormDataMap,
  },
  filter: ({ documentId }) => Boolean(documentId),
  fn: ({ documentId, ids, rowsMap }) => {
    const items = ids.map((id) => Object.values(rowsMap[id]).reduce((acc, curr) => {
      (acc as any)[curr.name] = curr.value
      return acc
    }, { id } as UpdateEstimateRowItemWithId))
    return {
      documentId: documentId as EstimateDocId,
      items,
    }
  },
  target: updateMultipleKorDocItemsFx,
})

sample({
  clock: updateMultipleKorDocItemsFx.done,
  target: exitMultipleEditMode,
})

sample({
  clock: updateMultipleKorDocItemsFx.doneData,
  source: $tableRoot,
  filter: Boolean,
  fn: (table, { items }) => table.map((item) => (
    items.find(({ id }) => item.id === id) ?? item
  )),
  target: setRootUpdate,
})

sample({
  clock: updateMultipleKorDocItemsFx.doneData,
  fn: ({ features }) => features,
  target: updateFeatures,
})

sample({
  clock: updateMultipleKorDocItemsFx.doneData,
  fn: ({ summary }) => summary,
  target: updateSummary,
})

createToast({
  effect: updateMultipleKorDocItemsFx,
  doneText: 'Изменения успешно сохранены',
})

sample({
  clock: requestEndedWithErrors,
  fn: ({ failed, total }) => ({
    content: `Возникли ошибки при сохранении ${failed} из ${total} строк`,
    icon: NotificationType.Alert,
  }),
  target: showToast,
})
