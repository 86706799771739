import { css, RuleSet } from 'styled-components'
import { palette } from '../../palette'
import { ButtonType } from './types'

export const PrimaryButtonCSS = css`
  background-color: ${palette.accent100};
  color: ${palette.white};

  @media (hover: hover) {
    &:not(:disabled):hover {
      background-color: ${palette.accent90};
    }
  }

  &:not(:disabled):active {
    background-color: ${palette.accent80};
  }

  &:disabled:not([data-pending]) {
    background-color: ${palette.accent40};
  }

  &[data-pending] {
    color: ${palette.accent20};
  }
`

export const SecondaryButtonCSS = css`
  background-color: ${palette.accent10};
  color: ${palette.accent100};

  @media (hover: hover) {
    &:not(:disabled):hover {
      background-color: ${palette.accent20};
    }
  }

  &:not(:disabled):active {
    background-color: ${palette.accent30};
  }

  &:disabled:not([data-pending]) {
    background-color: ${palette.accent10};
    color: ${palette.accent50};
  }

  &[data-pending] {
    background-color: ${palette.accent10};
    color: ${palette.accent70};
  }
`

export const GreyButtonCSS = css`
  background-color: ${palette.grey30};
  color: ${palette.grey90};

  @media (hover: hover) {
    &:not(:disabled):hover {
      background-color: ${palette.grey40};
    }
  }

  &:not(:disabled):active {
    background-color: ${palette.grey50};
  }

  &:disabled:not([data-pending]) {
    background-color: ${palette.grey20};
    color: ${palette.grey60};
  }

  &[data-pending] {
    color: ${palette.grey70};
  }
`

export const LineGreyButtonCSS = css`
  background-color: ${palette.white};
  color: ${palette.grey90};
  border: 1px solid ${palette.grey40};

  @media (hover: hover) {
    &:not(:disabled):hover {
      background-color: ${palette.grey40};
      border: 1px solid ${palette.grey40};
    }
  }

  &:not(:disabled):active {
    background-color: ${palette.grey50};
    border: 1px solid ${palette.grey50};
  }

  &:disabled:not([data-pending]) {
    background-color: ${palette.white};
    border: 1px solid ${palette.grey40};
    color: ${palette.grey60};
  }

  &[data-pending] {
    border: 1px solid ${palette.grey40};
    color: ${palette.grey70};
  }
`

export const LinePrimaryButtonCSS = css`
  background-color: ${palette.white};
  color: ${palette.accent100};
  border: 1px solid ${palette.accent90};

  @media (hover: hover) {
    &:not(:disabled):hover {
      background-color: ${palette.accent90};
      color: ${palette.white};
      border: 1px solid ${palette.accent90};
    }
  }

  &:not(:disabled):active {
    background-color: ${palette.accent80};
    color: ${palette.white};
    border: 1px solid ${palette.accent80};
  }

  &:disabled:not([data-pending]) {
    background-color: ${palette.white};
    border: 1px solid ${palette.grey40};
    color: ${palette.accent50};
  }

  &[data-pending] {
    border: 1px solid ${palette.accent80};
    color: ${palette.accent70};
  }
`

export const styles = {
  primary: PrimaryButtonCSS,
  secondary: SecondaryButtonCSS,
  grey: GreyButtonCSS,
  'line-grey': LineGreyButtonCSS,
  'line-primary': LinePrimaryButtonCSS,
}

export const PrimaryLoader = css`
  background-color: ${palette.accent40};
  color: ${palette.white};
`
export const LineGreyLoader = css`
  background-color: ${palette.white};
  color: ${palette.grey100};
`
export const LinePrimaryLoader = css`
  background-color: ${palette.white};
  color: ${palette.accent100};
`
export const GreyLoader = css`
  background-color: ${palette.grey20};
  color: ${palette.grey100};
`
export const SecondaryLoader = css`
  background-color: ${palette.accent10};
  color: ${palette.accent100};
`

export const loaderStyles: { [key in ButtonType]: RuleSet } = {
  primary: PrimaryLoader,
  'line-grey': LineGreyLoader,
  'line-primary': LinePrimaryLoader,
  grey: GreyLoader,
  secondary: SecondaryLoader,
}
