import { createForm } from 'effector-forms'

import { rules } from '@/lib/form-validators'

import { FILE_MAX_COUNT } from '../../model'
import { CommentForm } from './types'

export const commentForm = createForm<CommentForm>({
  fields: {
    comment: {
      init: '',
    },
    files: {
      init: [],
      rules: [rules.maxFiles(FILE_MAX_COUNT)],
    },
  },
})
