import React, { memo } from 'react'
import styled, { css } from 'styled-components'
import { useStoreMap, useUnit } from 'effector-react'

import { ContractDirection, GaMorphologyItem } from '@/dal'
import {
  DefaultButton,
  Icon2,
  palette,
  TABLE_BORDER_STYLE,
  TextMLight,
} from '@/ui'

import {
  $isCellGroupHidden,
  $pendingFolderIds,
  $tableTree,
  closeTableItem,
  openTableItem,
} from '../../model/private'
import {
  FirstCellWrapper,
  GaCommonCells,
  RowLoader,
  TableRowWrapper,
} from '../parts'

// отступы пока такие
const DEFAULT_LEFT_PADDING = 22
const STEP = 8

type Props = {
  item: GaMorphologyItem
  isScrolled: boolean
}

export const GaMorphologyRow = memo(({ item, isScrolled }: Props) => {
  const isHiddenGroup = useUnit($isCellGroupHidden)

  const {
    id,
    name,
    depth,
    is_accepted: isAccepted,
    row_type: type,
    has_children: hasChildren,
  } = item

  const isLoading = useStoreMap({
    store: $pendingFolderIds,
    keys: [id],
    fn: (arr, [id]) => arr.includes(id),
  })

  const isExpanded = useStoreMap({
    store: $tableTree,
    keys: [id],
    fn: (map, [rowId]) => Boolean(map[rowId]),
  })

  const leftPadding = DEFAULT_LEFT_PADDING + depth * STEP

  const handleToggleExpansion = () => {
    if (isExpanded) {
      closeTableItem(id)
    } else {
      openTableItem({ id, type })
    }
  }

  return (
    <>
      <TableRowWrapper actType={ContractDirection.SMR} hiddenFirstGroup={isHiddenGroup} as="td">
        <FirstCellWrapper hasShadow={isScrolled}>
          <FirstCell
            $hasRightDivider={isHiddenGroup}
            $leftPadding={leftPadding}
            onClick={hasChildren ? handleToggleExpansion : undefined}
          >
            <IconWrapper $isHidden={!hasChildren}>
              <Icon2
                icon={isExpanded ? 'minusWithCircle' : 'plusWithCircle'}
                color={isExpanded ? 'grey80' : 'grey60'}
                size={12}
            />
            </IconWrapper>

            <FirstCellInfo>
              <p>
                {name}
              </p>
              <AcceptedBlock $isHidden={!isAccepted}>
                <Icon2 icon="success" size={12} />
                <p>Принято</p>
              </AcceptedBlock>
            </FirstCellInfo>
          </FirstCell>
        </FirstCellWrapper>

        <GaCommonCells isHiddenGroup={isHiddenGroup} periodCost={item.period_cost} />
      </TableRowWrapper>    

      {isLoading && (
        <TableRowWrapper
          actType={ContractDirection.SMR}
          hiddenFirstGroup={isHiddenGroup}
          as="td"
        >
          <RowLoader />
        </TableRowWrapper>
      )}
    </>
  )
})

type FirstCellProps = {
  $hasRightDivider: boolean
  $leftPadding: number
}

const FirstCell = styled(DefaultButton)<FirstCellProps>`
  ${TextMLight}
  display: flex;
  gap: 8px;

  width: 100%;
  height: 100%;
  padding: ${({ $leftPadding }) => `12px 20px 12px ${$leftPadding}px`};
  border-right: ${TABLE_BORDER_STYLE};
  text-align: left;

  ${({ $hasRightDivider }) => $hasRightDivider && css`
    border-width: 4px;
  `}

  ${({ onClick }) => !onClick && css`
    cursor: initial;
  `}
`

const FirstCellInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const AcceptedBlock = styled.div<{ $isHidden: boolean }>`
  display: flex;
  gap: 6px;
  align-items: center;
  color: ${palette.lightGreen100};

  ${({ $isHidden }) => $isHidden && css`
    visibility: hidden;
  `}
`

const IconWrapper = styled.div<{ $isHidden: boolean }>`
  padding-block: 3px;

  ${({ $isHidden }) => $isHidden && css`
    visibility: hidden;
  `}
`
