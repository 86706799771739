import { attachWrapper } from '@42px/effector-extra'
import { AxiosResponse } from 'axios'

import {
  AbortableRequest,
  CommentItem,
  CommentResponse,
  DefaultResponseFail,
  GetCommentsParams,
  PaginationRequestParams,
  PaginationType,
  SendCommentParams,
  SendReactionRequestParams,
} from '../interfaces'
import { authRequestFx, Method } from '../request'
import { ArticleItem, GetArticlesParams, GetNewArticlesResponse } from './type'
import { defaultErrorHandler } from '../error-handler'

export const getArticleCommentsReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, signal, ...query }: AbortableRequest<GetCommentsParams>) => ({
    method: Method.get,
    url: '/news/comments/',
    query: {
      target_id: id,
      ...query,
    },
    signal,
  }),
  mapResult: ({ result }: {result: AxiosResponse<CommentResponse>}) => result.data,
})

export const getArticlesReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({
    limit = 10,
    offset,
    signal,
    ...query
  }: AbortableRequest<PaginationRequestParams & GetArticlesParams>) => ({
    method: Method.get,
    url: '/news/publications/',
    query: {
      limit,
      offset,
      ...query,
    },
    signal,
  }),
  mapResult: ({ result }: {result: AxiosResponse<PaginationType<ArticleItem[]>>}) => result.data,
})

export const postArticleCommentReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, ...body }: SendCommentParams) => ({
    method: Method.post,
    url: 'news/comments/',
    body: {
      target: id,
      ...body,
    },
  }),
  mapResult: ({ result }: { result: AxiosResponse<CommentItem> }) => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const sendArticleReactionReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ targetId, reaction }: SendReactionRequestParams) => ({
    method: Method.post,
    url: `/news/publications/${targetId}/take_reaction/`,
    body: {
      reaction,
    },
  }),
  mapResult: ({ result }: {result: AxiosResponse<ArticleItem['reactions']>}) => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const getNewArticlesReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: () => ({
    method: Method.get,
    url: '/news/publications/get_updates/',
  }),
  mapResult: ({ result }: {result: AxiosResponse<GetNewArticlesResponse>}) => result.data,
})

export const readArticleReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (id: ArticleItem['id']) => ({
    method: Method.post,
    url: `/news/${id}/read_publication/`,
  }),
  mapResult: ({ result }: {result: AxiosResponse<ArticleItem>}) => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const sendArticleToEmailReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, email }) => ({
    method: Method.post,
    url: `/news/${id}/send_publication/`,
    body: { email },
  }),
  mapResult: ({ result }): void => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})
