import React from 'react'

import { EditableValue } from '@/ui'
import { formatDateString } from '@/lib/date'

import { Col, Value } from './sharedCSS'

type Props = {
  date: string
  isChangeble?: boolean
  isGrey?: boolean
  onSubmit?: (val: string) => void
}

export const DateCol = ({
  date,
  isChangeble,
  isGrey = false,
  onSubmit,
}: Props) => (
  <Col>
    {isChangeble && onSubmit ? (
      <EditableValue
        value={date}
        onSubmit={onSubmit}
        cellType="date"
      />
    ) : (
      <Value $isGrey={isGrey}>
        {formatDateString(date)}
      </Value>
    )}
  </Col>
)
