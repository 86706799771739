import { attachWrapper } from '@42px/effector-extra'
import { createForm } from 'effector-forms'

import { defaultErrorHandler, DefaultResponseFail, FileItem } from '@/dal'
import { authRequestFx, Method } from '@/dal/request'
import { rules } from '@/lib/form-validators'

import { d } from './domain'
import { FeedbackPayload } from './types'


export const $modalFeedbackOpened = d.store(false)
export const closeFeedbackModal = d.event()

export const $selectedFeedbackFiles = d.store<FileItem[]>([])
export const addSelectedFeedbackFiles = d.event<File[]>()
export const deleteFeedbackFile = d.event<number>()

export const initFeedbackImagesFx = d.effect<FileItem[], FileItem[], Error>()

export const $feedbackForm = createForm({
  domain: d,
  validateOn: ['submit'],
  fields: {
    theme: {
      init: '',
      rules: [rules.required()],
    },
    message: {
      init: '',
      rules: [rules.required()],
    },
  },
})


export const sendMailFeedbackFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (payload: FeedbackPayload) => {
    const formData = new FormData()
    formData.append('subject', payload.theme)
    formData.append('message', payload.message)
    for (const file of payload.files) {
      formData.append('files', file)
    }
    return {
      method: Method.post,
      url: '/main/technical-support-request/',
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  },
  mapResult: () => null,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})
