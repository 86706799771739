import React, { memo } from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { Icon2, palette, PaletteColor } from '@/ui'
import { useTooltip } from '@/lib/hooks'
import { $authorizedUserData } from '@/features/login/model'

import { UserProfilePopup } from '../parts'
import { onLogout } from '../../model/private'

export const UserProfileBlock = memo(() => {
  const authorizedUserData = useUnit($authorizedUserData)
  const { floatingStyles, getFloatingProps, getReferenceProps, isOpen, refs } = useTooltip({
    tooltipOffset: 4,
    placement: 'bottom-end',
  })

  const userName = authorizedUserData
    ? `${authorizedUserData?.first_name} ${authorizedUserData?.last_name}`
    : ''

  return (
    <Wrapper>
      <UserIcon ref={refs.setReference} {...getReferenceProps()}>
        <IconWrapper $color={isOpen ? 'accent90' : 'grey50'}>
          <Icon2 icon="user" color="white" size={32} padding={8} />
        </IconWrapper>
      </UserIcon>

      {isOpen && (
        <div ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
          <UserProfilePopup
            email={authorizedUserData?.email || ''}
            onLogout={onLogout}
            userName={userName}
          />
        </div>
      )}
    </Wrapper>
  )
})

const Wrapper = styled.div``

const IconWrapper = styled.div<{ $color: PaletteColor }>`
  display: flex;
  border: 2px solid ${palette.white};
  border-radius: 16px;
  background-color: ${({ $color }) => palette[$color]};
  transition: background-color 0.15s linear;
`

const UserIcon = styled.div`
  background: linear-gradient(90deg, ${palette.accent100}, rgba(255, 138, 30, 1));
  border-radius: 16px;
  padding: 2px;
`
