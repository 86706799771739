import React from 'react'
import styled, { css } from 'styled-components'

import { palette, PaletteColor, TextM, TextMLight } from '@/ui'

import { CELL_BORDER_COLOR } from './const'

type Props = {
  children: React.ReactNode
  borderColor?: PaletteColor
  bgColor?: PaletteColor
  isBold?: boolean
  isLeftAlign?: boolean
  borderPosition?: 'right' | 'bottom'
}

export const Cell = ({
  children,
  borderColor,
  bgColor = 'white',
  isBold = false,
  isLeftAlign = false,
  borderPosition = 'right',
}: Props) => {
  return (
    <Wrapper
      $borderColor={borderColor}
      $bgColor={bgColor}
      $borderPosition={borderPosition}
      $isBold={isBold}
      $isLeftAlign={isLeftAlign}
    >
      {children}
    </Wrapper>
  )
}

type WrapperProps = {
  $borderColor?: PaletteColor
  $bgColor: PaletteColor
  $borderPosition: Props['borderPosition']
  $isBold: boolean
  $isLeftAlign: boolean
}

export const Wrapper = styled.div.attrs({ className: 'cell' })<WrapperProps>`
  --background-color: ${({ $bgColor }) => palette[$bgColor]};

  ${({ $isBold }) => ($isBold ? TextM : TextMLight)}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 2px;

  min-height: 72px;
  padding: 14px 12px;
  background-color: var(--background-color);
  flex-grow: 1;
  border-right: 1px solid ${CELL_BORDER_COLOR};
  border-bottom: 1px solid ${CELL_BORDER_COLOR};

  transition: background-color 0.15s linear;

  ${({ $isLeftAlign }) =>
    !$isLeftAlign &&
    css`
      text-align: center;
      align-items: center;
    `}

  ${({ $borderColor }) =>
    $borderColor &&
    css`
      position: relative;
      z-index: 1;
      border-color: ${palette[$borderColor]};
      transition: border-color 0.15s linear;

      &::before {
        content: '';
        position: absolute;
        inset: 0;
        top: -1px;
        left: -1px;
        pointer-events: none;
        border-top: 1px solid ${palette[$borderColor]};
        border-left: 1px solid ${palette[$borderColor]};
      }
    `}
`
