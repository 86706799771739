import React from 'react'
import styled, { css } from 'styled-components'

import { showMaxFileCountError } from '@/features/shared/file-toasts/model'

import { palette } from '../../palette'
import { SimpleLoader } from '../../../Loader'
import { Icon2 } from '../../icons'
import { TextM } from '../../typography'
import { AttachmentsInfo } from './AttachmentsInfo'
import { useDefaultDropzone } from '../../hooks'

type Props = {
  value: File[]
  height?: number
  maxFiles?: number
  isDisabled?: boolean
  isMultiple?: boolean
  isLoading?: boolean
  dataTestId?: string
  showInfo?: boolean
  onChange: (files: File[]) => void
}

export const DropArea: React.FC<Props> = ({
  value,
  height = 92,
  maxFiles = Infinity,
  isDisabled,
  isMultiple,
  isLoading,
  dataTestId,
  showInfo,
  onChange,
}) => {
  const onDrop = React.useCallback(
    (files: File[]) => {
      const newValue = [...value, ...files]
      if (newValue.length > maxFiles) {
        showMaxFileCountError(maxFiles)
        onChange(newValue.slice(0, 5))
      } else {
        onChange(newValue)
      }
    },
    [value, maxFiles, onChange],
  )

  const isInputDisabled = isDisabled || isLoading || value.length >= maxFiles

  const { getRootProps, getInputProps, isDragActive } = useDefaultDropzone({
    disabled: isInputDisabled,
    multiple: isMultiple,
    onDrop,
  })

  return (
    <div>
      <Wrapper
        {...getRootProps()}
        $height={height}
        $isDisabled={isInputDisabled}
        $isActive={isDragActive}
        data-testid={dataTestId}
      >
        <Input {...getInputProps()} data-testid="input-dragger" />
        {isLoading ? (
          <SimpleLoader sizePx={24} />
        ) : (
          <>
            <Icon2 icon="addFile" size={25} color="grey50" />
            <LabelText>
              Нажмите или перетащите файл сюда
            </LabelText>
          </>
        )}
        <SvgBorder width="100%" height="100%" fill="none">
          <rect
            width="100%"
            height="100%"
            x={1}
            y={1}
            strokeDasharray="10, 6"
            ry="12"
            rx="12"
            strokeWidth={1}
            strokeLinecap="round"
            stroke="currentColor"
          />
        </SvgBorder>
      </Wrapper>
      {showInfo && (
        <AttachmentsInfoWrapper>
          <AttachmentsInfo />
        </AttachmentsInfoWrapper>
      )}
    </div>

  )
}

const SvgBorder = styled.svg`
  position: absolute;
  color: ${palette.grey50};
  transition: color 0.15s linear;

  rect {
    width: calc(100% - 2px);
    height: calc(100% - 2px);
  }
`

const Wrapper = styled.div<{ $height: number, $isDisabled: boolean, $isActive: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;

  width: 100%;
  height: ${({ $height }) => $height}px;
  padding: 22px 40px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.15s linear;

  ${({ $isDisabled }) => $isDisabled && css`
    cursor: not-allowed;
  `}

  ${({ $isDisabled }) => !$isDisabled && css`
    &:hover {
      ${SvgBorder} {
        color: ${palette.grey60};
      }
    }

    &:active {
      background-color: ${palette.grey10};

      ${SvgBorder} {
        color: ${palette.grey60};
      }
    }
  `}

  ${({ $isActive }) => $isActive && css`
    background-color: ${palette.grey10};

    ${SvgBorder} {
      color: ${palette.grey60};
    }
  `}
`

const LabelText = styled.div`
  ${TextM}
  color: ${palette.grey60};
`

const Input = styled.input`
  display: none;
`

const AttachmentsInfoWrapper = styled.div`
  margin-top: 16px;
`
