import { DefaultErrorResponseData } from '../error-handler'

export type RequestForAccessResponse = {
  inn: string
  name: string,
  projects: { id: number, name: string }[]
}

export type GetOrganizationsResponse = {
  id: number
  name: string
}[]

export enum RequestForAccessErrorType {
  CompanyNotFound = 'COMPANY_NOT_FOUND',
  AccreditationTimeOutError = 'ACCREDITATION_TIMED_OUT',
  AccreditationInProccess = 'ACCREDITATION_IN_PROCCESS',
  AccreditationFailed = 'ACCREDITATION_FAILED',
  AlreadyExistsInLKP = 'ALREADY_EXISTS_IN_LKP',
}

export type RequestForAccessError = {
  inn: string,
  detail: null,
  company_name?: string | null
} & ({
  code: RequestForAccessErrorType.AlreadyExistsInLKP,
  already_registered_users: string[]
} | {
  already_registered_users: undefined
  code: Exclude<RequestForAccessErrorType, RequestForAccessErrorType.AlreadyExistsInLKP>,
})

export type RequestForAccessResponseError =
  RequestForAccessError
  | DefaultErrorResponseData


export type SendCopmanyInfoParams = {
  inn: string,
  name: string,
  profession: string,
  company_fsk: number,
  project: number,
  number: string,
  phone: string,
  email: string,
}
