import { attach, Effect } from 'effector'

import {
  AppealFiltersResponse,
  AppealItem,
  AppealTabValue,
  getSupportAppealFiltersReqFx,
  getSupportAppealsReqFx,
  putMarkAppealAsReadReqFx,
  sendAppealChatMessageReqFx,
  getAppealMessagesReqFx,
  AppealCommentV2,
} from '@/dal'
import { createSingleEffect } from '@/lib/createSingleEffect'
import { createModal } from '@/features/factories'
import { readQueryFx } from '@/features/shared/query-string/model'

import { d } from './domain'
import { APPEAL_SECTIONS, SupportQueryParams } from '../../model'

export const $appeals = d.store<AppealItem[]>([])

export const $appealAvailableFilters = d.store<AppealFiltersResponse | null>(null)
export const $appealTabs = $appealAvailableFilters.map((data) => (
  !data ? [] : data.statuses.map(({ value: id, name: label, ...item }) => ({ ...item, id, label }))
))
export const $appealProjects = $appealAvailableFilters.map((data) => (
  !data ? [] : data.projects.map(({ id, name: label }) => ({ id, label }))
))
export const $appealTypeOptions = $appealAvailableFilters.map((data) => (
  !data ? [] : data.issue_types.map(({ alias: id }) => ({
    id,
    label: APPEAL_SECTIONS.find(({ alias }) => alias === id)?.name ?? '',
  }))
))

export const $selectedAppealTab = d.store<AppealTabValue>('all')
export const selectAppealTab = d.event<AppealTabValue>()

export const $appealMessages = d.store<AppealCommentV2[]>([])

// скрытие во время создания скриншота
export const $isAppealChatHidden = d.store(false)

export const markAppealAsRead = d.event<number>()

export const insertNewAppeal = d.event<AppealItem>()

export const openAppealChat = d.event<AppealItem>()

export const appealChatModal = createModal<AppealItem>()
export const $selectedAppeal = appealChatModal.$meta

export const $canSendMessage = d.store(false)
export const setCanSendMessage = d.event<boolean>()

export const {
  requestFx: putMarkAppealAsReadFx,
} = createSingleEffect(putMarkAppealAsReadReqFx)

export const {
  requestFx: getSupportAppealsFx,
} = createSingleEffect(getSupportAppealsReqFx)

export const {
  requestFx: getSupportAppealFiltersFx,
} = createSingleEffect(getSupportAppealFiltersReqFx)

export const {
  requestFx: getAppealMessagesFx,
} = createSingleEffect(getAppealMessagesReqFx)

export const sendMessageFx = attach({
  effect: sendAppealChatMessageReqFx,
})

export const readSupportQueryFx: Effect<void, SupportQueryParams> = attach({
  effect: readQueryFx,
})
