import React from 'react'
import styled from 'styled-components'

import {
  DefaultButton, Icon2, palette, TextL,
} from '@/ui'

type Props = {
  onClick: () => void
}

// TODO добавить анимацию при ховере либо забить
export const ScreenshotButton = ({ onClick }: Props) => {
  return (
    <Wrapper onClick={() => onClick()}>
      <Icon2 icon="scissors" size={20} />
      <Text>Сделать скриншот</Text>
    </Wrapper>
  )
}

const MIN_SIZE = '62px'

const Text = styled.header`
  ${TextL}
  display: none;
`

const Wrapper = styled(DefaultButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;

  min-width: ${MIN_SIZE};
  height: ${MIN_SIZE};
  padding: 16px;
  border: 1px solid ${palette.grey40};
  background-color: ${palette.white};
  border-radius: 12px;
  box-shadow: 0px 4px 20px 0px #1c1d1e26;

  @media (pointer: fine) {
    &:hover ${Text} {
      display: block;
    }
  }
`
