import { d } from './domain'

type Payload = { blob: Blob }

export const $isBottomBlockHidden = d.store(false)
export const setIsBottomBlockHidden = d.event<boolean>()

export const addScreenshotToResponsibleForm = d.event<Payload>()
export const addScreenshotToDocumentProblemForm = d.event<Payload>()
export const addScreenshotToLkpProblemForm = d.event<Payload>()
export const addScreenshotToAppealChat = d.event<Payload>()
