import React from 'react'
import styled from 'styled-components'

import { TextMLight } from '../../typography'
import { palette } from '../../palette'

export const AttachmentsInfo = () => (
  <Info>
    Для вложения доступны: png, pdf, jpeg, xls/xlsx, doc/docx; максимальный размер файла — 100 МБ.
    Все вложенные файлы будут сконвертированы в pdf.
  </Info>
)

const Info = styled.div`
  ${TextMLight}
  color: ${palette.grey70};
  width: 100%;
`
