import React, { memo } from 'react'

import type { GaSection } from '@/dal'
import { Cell } from '@/features/act/view'

import { FirstCellWrapper, StickyRow } from '../parts'
import { EndNodeRows } from './EndNodeRows'

type Props = {
  item: GaSection
}

export const SectionWithFloors = memo(({ item }: Props) => {
  if (!item.floors.length) return null

  const { name, floors } = item

  return (
    <div>
      <StickyRow>
        <FirstCellWrapper>
          <Cell bgColor="grey20" borderPosition="bottom" isLeftAlign isBold>
            Секция
          </Cell>
        </FirstCellWrapper>
        <Cell>{name}</Cell>
      </StickyRow>

      <EndNodeRows items={floors} typeName="Этаж" />
    </div>
  )
})
