import { attach } from 'effector'
import { attachWrapper } from '@42px/effector-extra'

import { prepareFormData } from '@/lib/form-data'

import {
  authRequestFx, downloadFilesFx, Method, mockRequestFx,
} from '../request'
import {
  EstimateLot,
  EstimateLotFiltersResponse,
  EstimateLotsFilterPayload,
  GetEstimateLotListResponse,
  GetEstimateLotMorphologyResponse,
  GetLotFlatTableResponse,
  GetLotFlatTableParams,
  GetLotTreeTableResponse,
  KvrElement,
  LotCatalogItem,
  PostEstimateLotAttachmentsParams,
  UpdateEstimateLotPayload,
  GetLotTreeTableParams,
  UnitItem,
  LotTableItem,
  GetLotTreeRowsParams,
  UpdateLotWorkCommentPayload,
} from './types'
import {
  AbortableRequest, CompanyItem, DefaultResponseFail, DocumentAlias, EstimateLotId, FileDTO,
} from '../interfaces'
import { defaultErrorHandler } from '../error-handler'

export const getEstimateLotReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ signal, id }: AbortableRequest<{ id: EstimateLotId }>) => ({
    method: Method.get,
    url: `/estimate_lots/lot/${id}/`,
    signal,
  }),
  mapResult: ({ result }): EstimateLot => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const getEstimateLotFlatTableReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ signal, id, ...query }: AbortableRequest<GetLotFlatTableParams>) => ({
    method: Method.get,
    url: `/estimate_lots/flat/${id}/`,
    signal,
    query,
  }),
  mapResult: ({ result }): GetLotFlatTableResponse => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const getEstimateLotTreeTableReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ signal, id, group_id }: AbortableRequest<GetLotTreeTableParams>) => ({
    signal,
    method: Method.get,
    url: `/estimate_lots/hierarchy/${id}/`,
    query: {
      group_id,
    },
  }),
  mapResult: ({ result }): GetLotTreeTableResponse => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const getEstimateLotTreeRowsReqFx = attachWrapper({
  effect: getEstimateLotTreeTableReqFx,
  mapParams: (params: AbortableRequest<GetLotTreeRowsParams>) => params,
  mapResult: ({ result }) => result.data,
})

export const getEstimateLotKvrAndKerReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({
    signal, id, parentId, search,
  }: AbortableRequest<{ id: EstimateLotId, parentId: number | null, search?: string }>) => ({
    method: Method.get,
    url: `/estimate_lots/kvr_ker/${id}/`,
    signal,
    query: {
      parent_id: parentId ?? undefined,
      filter_by_name: search || undefined,
    },
  }),
  mapResult: ({ result }): KvrElement[] => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const addEstimateLotKerReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, kerId, kvrId }: { id: EstimateLotId, kerId: number, kvrId: number }) => ({
    method: Method.post,
    url: `/estimate_lots/lot/${id}/add/kvr/${kvrId}/ker/${kerId}/`,
  }),
  // TODO поменять
  mapResult: ({ result }): void => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const getEstimateLotFolderNestedWorksReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (id: LotTableItem['id']) => ({
    method: Method.get,
    url: `/estimate_lots/kers_of_kvr/${id}/`,
  }),
  mapResult: ({ result }): string[] => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const deleteEstimateLotWorkReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (id: LotTableItem['id']) => ({
    method: Method.delete,
    url: `/estimate_lots/lot_work/${id}/`,
  }),
  mapResult: ({ result }): void => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const deleteEstimateLotFolderReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (id: LotTableItem['id']) => ({
    method: Method.delete,
    url: `/estimate_lots/delete_group/${id}/`,
  }),
  mapResult: ({ result }): void => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const changeEstimateLotWorkCommentReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, comment }: UpdateLotWorkCommentPayload) => ({
    method: Method.patch,
    url: `/estimate_lots/lot_work/${id}/set_comment/`,
    body: {
      comment,
    },
  }),
  mapResult: ({ result }): string => result.data.comment,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const addEstimateLotKvrReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, kvrId }: { id: EstimateLotId, kvrId: number }) => ({
    method: Method.post,
    url: `/estimate_lots/lot/${id}/add_all/kvr/${kvrId}/`,
  }),
  mapResult: ({ result }): { added_works: number[] } => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const postLotWorkAttachmentsReqFx = attachWrapper({
  effect: mockRequestFx,
  mapParams: ({ id, ...body }: any) => ({
    method: Method.post,
    url: `/mock/${id}/`,
    body: prepareFormData({ ...body, files: undefined }),
  }),
  mapResult: ({ result }): FileDTO[] => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const updateEstimateLotReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({
    signal,
    id,
    ...body
  }: AbortableRequest<{ id: EstimateLotId } & UpdateEstimateLotPayload>) => ({
    method: Method.patch,
    url: `/estimate_lots/lot/${id}/`,
    signal,
    body,
  }),
  mapResult: ({ result }): EstimateLot => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const createEstimateLotReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id }: { id: CompanyItem['id'] }) => ({
    method: Method.post,
    url: `/estimate_lots/lot/${id}/`,
  }),
  mapResult: ({ result }): EstimateLot => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const deleteEstimateLotReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (id: EstimateLotId) => ({
    method: Method.delete,
    url: `/estimate_lots/lot/${id}/`,
  }),
  mapResult: ({ result }): void => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const copyEstimateLotReqFx = attachWrapper({
  effect: mockRequestFx,
  mapParams: (id: EstimateLotId) => ({
    method: Method.post,
    url: `/mock/${id}`,
  }),
  mapResult: ({ result }): EstimateLot => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const sendEstimateLotToApproveReqFx = attachWrapper({
  effect: mockRequestFx,
  mapParams: (id: EstimateLotId) => ({
    method: Method.post,
    url: `/mock/${id}/`,
  }),
  mapResult: ({ result }): EstimateLot => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const cancelEstimateLotApprovingReqFx = attachWrapper({
  effect: mockRequestFx,
  mapParams: ({ id, comment }: { id: EstimateLotId, comment: string }) => ({
    method: Method.post,
    url: `/mock/${id}`,
    body: {
      message: comment,
    },
  }),
  mapResult: ({ result }): EstimateLot => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const getEstimateLotListReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ signal, ...query }: AbortableRequest<EstimateLotsFilterPayload>) => ({
    method: Method.get,
    url: '/estimate_lots/lots_list/',
    signal,
    query,
  }),
  mapResult: ({ result }): GetEstimateLotListResponse => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const getEstimateLotFiltersReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ signal, ...query }: AbortableRequest<EstimateLotsFilterPayload>) => ({
    method: Method.get,
    url: '/estimate_lots/lots_list/filters/',
    signal,
    query,
  }),
  mapResult: ({ result }): EstimateLotFiltersResponse => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const getEstimateLotDirectionsReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: () => ({
    method: Method.get,
    url: '/main/directions/contract_directions/',
  }),
  mapResult: ({ result }): LotCatalogItem[] => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const getUnitsReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: () => ({
    method: Method.get,
    url: '/main/units/',
  }),
  mapResult: ({ result }): UnitItem[] => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const getEstimateLotTypesOfSecurityReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: () => ({
    method: Method.get,
    url: '/estimate_lots/directions/type_of_security/',
  }),
  mapResult: ({ result }): LotCatalogItem[] => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const getEstimateLotAdvanceTypesReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: () => ({
    method: Method.get,
    url: '/estimate_lots/directions/advance_type/',
  }),
  mapResult: ({ result }): LotCatalogItem[] => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const getEstimateLotMorphologyReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: () => ({
    method: Method.get,
    url: '/estimate_lots/directions/morphology/',
  }),
  mapResult: ({ result }): GetEstimateLotMorphologyResponse => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const downloadLotFilesByUrlReqFx = attach({
  effect: downloadFilesFx,
  mapParams: ({ url }: { url: string, id: number }) => ({
    url,
  }),
})

export const postEstimateLotAttachmentsReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, ...body }: PostEstimateLotAttachmentsParams) => ({
    method: Method.post,
    url: `/files/by_document/${DocumentAlias.ESTIMATE_LOT}/${id}/`,
    body: prepareFormData({ ...body, files: undefined }),
  }),
  mapResult: ({ result }): FileDTO[] => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})
