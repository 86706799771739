import styled from 'styled-components'

import { palette } from '@/ui'

export const GaGridWrapper = styled.div`
  display: grid;
  grid-template-columns: 65px minmax(300px, 1fr) repeat(2, 129px) 158px repeat(3, 173px) 172px;
  padding: 0;
  border-bottom: 1px solid ${palette.grey30};
`
