import React, { memo } from 'react'
import styled from 'styled-components'
import { combine } from 'effector'
import { useUnit } from 'effector-react'

import { DocumentStatuses } from '@/dal'
import { LoaderOverlay, NewButton, palette, TextM } from '@/ui'
import { GaModalWrapper } from '@/features/act/view'
import { $documentStatus, $isDraft } from '@/features/act/model'

import {
  $openedActingGroup,
  actingGroupModal,
  resetActingGroup,
  resetActingGroupFx,
  toggleMorphologyWorksFx,
} from '../../model/private'
import { ColorsLegend } from '../parts'
import { BuildingRow } from '../containers'

const $isPending = combine(toggleMorphologyWorksFx.pending, resetActingGroupFx.pending, (...args) =>
  args.some((val) => val),
)

const Buttons = ({
  canReset,
  isDisabled,
  onReset,
}: {
  canReset: boolean
  isDisabled: boolean
  onReset: () => void
}) => {
  return (
    <>
      <NewButton
        label="Сбросить изменения"
        buttonType="grey"
        prefixIcon="refresh"
        isDisabled={!canReset || isDisabled}
        dataTestId="acting-group-reset-btn"
        onClick={onReset}
      />
      <NewButton
        label="Закрыть"
        width={200}
        isDisabled={isDisabled}
        dataTestId="acting-group-modal-close-btn"
        onClick={actingGroupModal.close}
      />
    </>
  )
}

export const ActingGroupModal = memo(() => {
  const [isDraft, actingGroup, status, isOpen, isPending] = useUnit([
    $isDraft,
    $openedActingGroup,
    $documentStatus,
    actingGroupModal.$isOpen,
    $isPending,
  ])

  if (!isOpen || !actingGroup) return null

  const { id, buildings, is_rejected: hasRejectedChildren } = actingGroup

  return (
    <GaModalWrapper
      buttonsSlot={
        <Buttons canReset={isDraft} isDisabled={isPending} onReset={() => resetActingGroup(id)} />
      }
      canClose={!isPending}
      onClose={actingGroupModal.close}
    >
      <UpperBlock>
        <ColorsLegend
          hasNotification={status === DocumentStatuses.Draft}
          hasRejectionLegend={hasRejectedChildren}
        />
        <GroupName>{actingGroup.action_group_name}</GroupName>
      </UpperBlock>
      <TableWrapper>
        <Buildings>
          {buildings.map((item) => (
            <BuildingRow key={item.id} item={item} />
          ))}
        </Buildings>
      </TableWrapper>
      <LoaderOverlay position="fixed" isShown={isPending} />
    </GaModalWrapper>
  )
})

const UpperBlock = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 3;

  border: 1px solid ${palette.grey50};
  border-bottom-width: 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: ${palette.white};
`

const GroupName = styled.div`
  ${TextM}
  padding: 18px 12px;
  background-color: ${palette.grey30};
  text-align: center;
`

const TableWrapper = styled.div`
  width: 100%;
  min-width: max-content;
`

const Buildings = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  background-color: ${palette.grey30};
`
