import React from 'react'
import styled from 'styled-components'
import { Placement, Strategy } from '@floating-ui/react'

import { useTooltip } from '@/lib/hooks'

import { PaletteColor, palette } from '../../palette'
import { TextMLight } from '../../typography'
import { TooltipArrow } from './TooltipArrow'

type Props = {
  strategy?: Strategy
  placement?: Placement
  onTooltipClose?: () => void
  onTooltipOpen?: () => void
  onOpenChange?: (val: boolean) => void
  referenceComponent: React.ReactElement
  children: React.ReactNode | React.ReactNode[]
  bgColor?: PaletteColor
  padding?: string
  tooltipOffset?: number
}

export const ToolTipWrapper = ({
  onOpenChange,
  onTooltipClose,
  onTooltipOpen,
  bgColor = 'grey100',
  padding,
  placement,
  strategy,
  children,
  referenceComponent,
  tooltipOffset,
}: Props) => {
  const {
    floatingStyles,
    getFloatingProps,
    getReferenceProps,
    isOpen,
    refs,
    arrowProps,
  } = useTooltip({
    onOpenChange,
    onTooltipClose,
    onTooltipOpen,
    placement,
    strategy,
    tooltipOffset,
  })

  return (
    <Wrapper ref={refs.setReference} {...getReferenceProps}>
      {referenceComponent}
      {isOpen && (
        <ToolTip
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
          $bgColor={bgColor}
          $padding={padding}
        >
          {children}
          {arrowProps && <TooltipArrow fill={bgColor} {...arrowProps} />}
        </ToolTip>
      )}
    </Wrapper>
  )
}

type ToolTipProps = {
  $bgColor: PaletteColor
  $padding?: string
}

const Wrapper = styled.div``

const ToolTip = styled.div<ToolTipProps>`
  background-color: ${({ $bgColor }) => palette[$bgColor]};
  padding: ${({ $padding }) => $padding || '12px 14px'};
  ${TextMLight};
  border-radius: 4px;
  color: ${palette.white};
  z-index: 900;
  box-shadow: 0px 3px 18px 0px #7A7A7A40;
`
