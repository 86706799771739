import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import {
  Icon2, LoaderOverlay, palette, TabSwiper, TextL, TextM,
} from '@/ui'
import { resumeAppealFx } from '@/features/support/support-appeals/model/private'

import {
  $appeals,
  appealChatModal,
  $appealTabs,
  getSupportAppealsFx,
  $selectedAppealTab,
  selectAppealTab,
  $selectedAppeal,
} from '../../model/private'
import { GridWrapper } from '../parts'
import { AppealListItem } from '../containers'

export const RequestHistory = React.memo(() => {
  const [appeals, appealTabs, activeTabId, selectedAppeal, isModalOpen, isLoading] = useUnit([
    $appeals,
    $appealTabs,
    $selectedAppealTab,
    $selectedAppeal,
    appealChatModal.$isOpen,
    getSupportAppealsFx.pending,
  ])
  const isResumePending = useUnit(resumeAppealFx.pending)

  return (
    <Wrapper>
      <Header>
        {Boolean(appealTabs.length) && (
          <TabSwiper
            items={appealTabs}
            activeId={activeTabId}
            size="M"
            onItemClick={(val) => selectAppealTab(val)}
          />
        )}

        <TableFields>
          <Name>Номер</Name>
          <Name>Тип обращения</Name>
          <Name>Тема</Name>
          <Name>Проект</Name>
          <Name>Дата</Name>
          <Name>Автор обращения</Name>
          <Name>Статус</Name>
        </TableFields>
      </Header>

      <AppealList>
        {isLoading ? (
          <LoaderWrapper>
            <LoaderOverlay isShown />
          </LoaderWrapper>
        ) : (
          <>
            {appeals.map((item) => (
              <AppealListItem
                key={`${item.issue_type}-${item.id}`}
                item={item}
                isSelected={
                  isModalOpen
                  && item.id === selectedAppeal?.id
                  && item.issue_type === selectedAppeal?.issue_type
                }
              />
            ))}

            {!appeals.length && (
              <EmptyList>
                <Icon2 icon="emptyTasks" size={170} />
                <EmptySubtitle>
                  Обращения не найдены
                </EmptySubtitle>
              </EmptyList>
            )}
          </>
        )}
      </AppealList>

      <LoaderOverlay position="fixed" isShown={isResumePending && !isLoading} />
    </Wrapper>
  )
})

const Wrapper = styled.div`
  padding: 20px;
  border-radius: 12px;
  background-color: ${palette.white};
`

const Header = styled.header`
  position: sticky;
  top: 0;
  z-index: 2;

  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 16px;
  background-color: ${palette.white};
`

const TableFields = styled(GridWrapper)`
  padding: 0 16px;
`

const Name = styled.div`
  ${TextM}
  color: ${palette.grey60};
`

const AppealList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const EmptyList = styled.div`
  padding: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
  align-items: center;
`

const EmptySubtitle = styled.p`
  ${TextL}
  color: ${palette.grey60};
`

const LoaderWrapper = styled.div`
  position: relative;
  height: 200px;
`
